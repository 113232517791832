import React from 'react';
import { mutate } from 'swr';
import { User } from '@customer-web-app/domains/users/models/user';
import { fronteggFetcher } from '@customer-web-app/domains/shared/api/services/fetchers-service';
import useToast from '@blastradius/ui/hooks/use-toast';
import { Avatar as avatar } from '@blastradius/ui';
import swrOptimisticUpdate from '@customer-web-app/domains/shared/services/swr-optimistic-update';
import { MetadataFlags } from '../models/metadata-flags';

type UpdateUserMetadataParams = {
  metadata: MetadataFlags;
  userID: string;
  userName?: string;
  withNotification?: boolean;
  notificationMessage?: string;
};

function useUserMetadata() {
  const { notification } = useToast();

  const [isRequesting, setIsRequesting] = React.useState(false);

  async function updateUserMetadata({
    metadata,
    userID,
    userName,
    withNotification = true,
    notificationMessage,
  }: UpdateUserMetadataParams) {
    const isEnablingNotification =
      metadata && Object.values(metadata).some((value) => value === true);

    setIsRequesting(true);

    try {
      const response = await fronteggFetcher({
        method: 'PUT',
        url: '/identity/resources/users/v2/me',
      });
      const responseData: any = response;
      const userMetadata = JSON.parse(responseData?.metadata);

      const newMetadata = { ...userMetadata, ...metadata };

      const { data } = await fronteggFetcher({
        method: 'PUT',
        url: '/identity/resources/users/v2/me',
        data: {
          metadata: JSON.stringify(newMetadata),
        },
      });

      swrOptimisticUpdate<{ items: User[] }>(
        `/identity/resources/users/v2`,
        (users) => {
          const cachedUsers = users?.items || [];

          return {
            ...users,
            items: cachedUsers.map((user) =>
              user.id === userID
                ? {
                    ...user,
                    metadata: JSON.stringify(metadata),
                  }
                : user,
            ),
          };
        },
        {
          mutate,
        },
      );

      if (withNotification) {
        notification({
          title: `Notifications ${
            isEnablingNotification ? 'Enabled' : 'Disabled'
          }`,
          message: `${notificationMessage} have been ${
            isEnablingNotification ? 'enabled' : 'disabled'
          }.`,
          avatar: avatar({ name: userName }),
        });
      }

      return data;
    } catch (e) {
      if (withNotification) {
        notification({
          title: 'Notifications Error',
          message: `${notificationMessage} could not be updated.`,
          state: 'error',
        });
      }

      return await Promise.reject(null);
    } finally {
      setIsRequesting(false);
    }
  }

  return {
    updateUserMetadata,
    isRequestingUserInvite: isRequesting,
  };
}

export default useUserMetadata;
