import React from 'react';
import { SWRConfig } from 'swr';
import {
  DrawerProvider,
  ThemeProvider,
  ToastProvider,
  DialogProvider,
} from '@blastradius/ui';
import api from '@customer-web-app/domains/shared/api';
import SkeletonLoaderProvider from '@customer-web-app/domains/shared/providers/skeleton-loader-provider';
import LogsProvider from '@customer-web-app/domains/shared/providers/logs-provider';
import RouterChangeProvider from '@customer-web-app/domains/shared/providers/router-change-provider';
import GlobalStoreProvider from '@customer-web-app/domains/shared/providers/global-store-provider';
import FeatureFlagsProvider from '@customer-web-app/domains/shared/providers/feature-flags-provider';
import GraphQLProvider from '@customer-web-app/domains/shared/providers/graphql-provider';
import AnalyticsProvider from '@customer-web-app/domains/shared/providers/analytics-provider';
import NewFeaturesProvider from '@customer-web-app/domains/shared/providers/new-features-provider';
import UserMetadataProvider from '@customer-web-app/domains/shared/providers/user-metadata-provider';
import ExpiredCredentialsWarningProvider from '@customer-web-app/domains/shared/providers/expired-credential-warning-provider';
import UsersnapProvider from '@customer-web-app/domains/shared/providers/usersnap-provider';

const CoreProvider: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
}) => {
  return (
    <GlobalStoreProvider>
      <ThemeProvider>
        <FeatureFlagsProvider>
          <AnalyticsProvider>
            <GraphQLProvider>
              <SWRConfig value={{ fetcher: api, errorRetryCount: 1 }}>
                <ToastProvider>
                  <SkeletonLoaderProvider>
                    <DialogProvider>
                      <DrawerProvider>
                        <NewFeaturesProvider>
                          <ExpiredCredentialsWarningProvider>
                            <UserMetadataProvider />
                            <RouterChangeProvider />
                            <UsersnapProvider />
                            <LogsProvider>{children}</LogsProvider>
                          </ExpiredCredentialsWarningProvider>
                        </NewFeaturesProvider>
                      </DrawerProvider>
                    </DialogProvider>
                  </SkeletonLoaderProvider>
                </ToastProvider>
              </SWRConfig>
            </GraphQLProvider>
          </AnalyticsProvider>
        </FeatureFlagsProvider>
      </ThemeProvider>
    </GlobalStoreProvider>
  );
};

export default CoreProvider;
