import { Button, Text } from '@blastradius/ui';
import useErrorNotifier from '@customer-web-app/domains/shared/hooks/use-error-notifier';
import { useRouter } from 'next/router';
import React from 'react';

const ErrorBoundary = ({ error, resetErrorBoundary }) => {
  const { notifyError } = useErrorNotifier();
  const router = useRouter();

  React.useEffect(() => {
    notifyError({ message: error.message });
  }, [error]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="flex flex-col items-start bg-gray-50 dark:bg-gray-925 rounded-lg shadow-lg p-6 max-w-md w-full -mt-28">
        <Text type="heading" size="small" className="mb-4">
          We encountered an unexpected error
        </Text>
        <Text type="body" size="regular" className="text-gray-500 mb-4">
          {error.message}
        </Text>
        <Button
          color="gray"
          size="small"
          onClick={() => {
            resetErrorBoundary();
            router.push('/');
          }}
        >
          Try Again
        </Button>
      </div>
    </div>
  );
};

export default ErrorBoundary;
