import React from 'react';
import classNames from 'classnames';
import Icon from '../icon';
import InputText, { InputTextProps } from '../input-text';

type Props = InputTextProps & {
  onChangeValue?: (value: number) => void;
};

const InputNumber = React.forwardRef<
  React.RefCallback<HTMLInputElement>,
  Props & React.InputHTMLAttributes<HTMLInputElement>
>(({ onChangeValue, ...props }, ref) => {
  const [value, setValue] = React.useState(props.value || '');
  const id = React.useMemo(() => Math.random().toString(36).slice(2), []);

  React.useEffect(() => {
    if (onChangeValue) {
      onChangeValue(value as number);
    }
  }, [value]);

  return (
    <div className="relative select-none">
      <InputText
        type="number"
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = +e.target.value;

          const isEmpty = e.target.value === '';

          const isGreaterThanMin = props.min
            ? newValue >= (props.min as number)
            : true;

          const isLessThanMax = props.max
            ? newValue <= (props.max as number)
            : true;

          if (isEmpty) {
            setValue(e.target.value);
          } else if (isLessThanMax && isGreaterThanMin && !isNaN(newValue)) {
            setValue(+e.target.value);
          }
        }}
        data-id={id}
        role="textbox"
        ref={ref}
        {...props}
      />

      <button
        type="button"
        className={classNames('rotate-180 absolute cursor-pointer right-3', {
          'top-[1.6rem]': props.label,
          'top-[0.125rem]': !props.label,
        })}
        onClick={() => {
          const currentValue = (
            document.querySelector(`[data-id="${id}"]`) as HTMLInputElement
          ).value;

          setValue(() => {
            const newValue = +currentValue + 1;

            return props.max != null && newValue > (props.max as number)
              ? +currentValue
              : newValue;
          });
        }}
        aria-label="Increase field value"
      >
        <Icon
          name="carrot-down"
          size={16}
          className="hover:fill-black dark:hover:fill-white transition-colors ease-in-out duration-200"
        />
      </button>

      <button
        type="button"
        className={classNames('absolute cursor-pointer right-3', {
          'top-[2.75rem]': props.label,
          'top-[1.45rem]': !props.label,
        })}
        onClick={() => {
          const currentValue = (
            document.querySelector(`[data-id="${id}"]`) as HTMLInputElement
          ).value;

          setValue(() => {
            const newValue = +currentValue - 1;

            return props.min != null && newValue < (props.min as number)
              ? +currentValue
              : newValue;
          });
        }}
        aria-label="Decrease field value"
      >
        <Icon
          name="carrot-down"
          size={16}
          className="hover:fill-black dark:hover:fill-white transition-colors ease-in-out duration-200"
        />
      </button>
    </div>
  );
});

export default InputNumber;
