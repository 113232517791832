import React from 'react';
import { useAuth } from '@frontegg/nextjs';
import { useRouter } from 'next/router';
import { setCookie } from 'nookies';
import { differenceInSeconds } from 'date-fns';
import useLocalStorage from '@customer-web-app/domains/shared/hooks/use-local-storage';
import { publicRoutes } from '@customer-web-app/domains/authentication/components/public-routes-wrapper';
import { ApplicationRoutes } from '@customer-web-app/domains/shared/enums/application-routes';
import { User } from '@customer-web-app/domains/users/models/user';
import useTenants from '@customer-web-app/domains/tenants/hooks/use-tenants';
import { bffFetcher } from '@customer-web-app/domains/shared/api/services/fetchers-service';
import useToast from '@blastradius/ui/hooks/use-toast/';
import { Tenant } from '@customer-web-app/domains/tenants/models/tenant';
import { delay } from '@customer-web-app/domains/shared/services/delay';

interface RedirectParams {
  pathname: string;
}

function useAuthentication() {
  const { replace, asPath, pathname, reload, ...router } = useRouter();
  const { user } = useAuth() as unknown as { user: User };
  const { tenants } = useTenants();
  const [isRequesting, setIsRequesting] = React.useState<
    Tenant['tenantId'] | null
  >(null);

  const { notification } = useToast();

  const [redirectParams, setRedirectParams] =
    useLocalStorage<RedirectParams | null>('redirectTo', null);

  const [isTenantSelected, setIsTenantSelected] = useLocalStorage<boolean>(
    'isTenantSelected',
    false,
  );

  React.useEffect(() => {
    if (user?.accessToken) {
      const accessTokenExpiresAtDate = new Date(user.exp * 1000);
      const currentDate = new Date();

      setCookie({}, 'token', user.accessToken, {
        maxAge: differenceInSeconds(accessTokenExpiresAtDate, currentDate),
        path: '/',
        sameSite: 'strict',
        secure: true,
      });
    }
  }, [user]);

  React.useEffect(() => {
    if (user?.id && asPath == redirectParams?.pathname) {
      setRedirectParams(null);
    }
  }, [user, asPath]);

  const memoUser = React.useMemo(
    () =>
      user && tenants?.length > 0
        ? {
            ...user,
            tenant: tenants.find((tenant) => tenant.tenantId === user.tenantId),
          }
        : undefined,
    [user, tenants],
  );

  const isSuperUser =
    memoUser?.superUser &&
    (memoUser?.email?.endsWith('blastradius.ai') ||
      memoUser?.email?.endsWith('radiantsecurity.ai'));

  const accessibleTenants = memoUser?.tenantIds;

  const canAccessMultipleTenants = isSuperUser || accessibleTenants?.length > 1;

  function redirectUserByAuthentication(to?: string, loading?: boolean) {
    const isSuperUser =
      user?.superUser &&
      (user?.email?.endsWith('blastradius.ai') ||
        user?.email?.endsWith('radiantsecurity.ai'));

    if (!user?.id && !publicRoutes.includes(pathname)) {
      setRedirectParams({ pathname: asPath });
      replace(ApplicationRoutes.Login);
    } else if (isSuperUser && !isTenantSelected) {
      return;
    } else if (redirectParams) {
      if (router?.query?.tenantID || router?.query?.params?.length >= 3) {
        setRedirectParams(null);
        return;
      }

      replace(redirectParams.pathname);
    } else if (to && !loading) {
      replace(to);
    }
  }

  async function updateCurrentTenant(newTenant: Tenant) {
    setIsRequesting(newTenant.tenantId);

    try {
      await bffFetcher({
        method: 'PUT',
        url: `/update-current-tenant`,
        data: { newTenantId: newTenant.tenantId },
        headers: {
          'frontegg-tenant-id': memoUser.tenant.tenantId,
          'frontegg-user-id': memoUser.id,
        },
      });

      notification({
        title: 'Tenant Updated',
        message: `"${newTenant.name}" is now your current tenant. The page will reload.`,
      });

      if (asPath.split('/')[2] === memoUser.tenant.tenantId) {
        replace(asPath.replace(memoUser.tenant.tenantId, newTenant.tenantId));
      }

      await delay(3000);

      if (pathname === '/' && !isTenantSelected) {
        setIsTenantSelected(true);
      }

      reload();
    } catch (e) {
      notification({
        title: 'Tenant Not Updated',
        message: 'The tenant cannot be changed. Please try again.',
        options: [{ type: 'dismiss' }],
      });
      throw e;
    } finally {
      setIsRequesting(null);
    }
  }

  const currentTenantName = memoUser?.tenant?.name;

  return {
    user: memoUser,
    currentTenantName,
    isSuperUser,
    accessibleTenants,
    canAccessMultipleTenants,
    isRequesting,
    isTenantSelected,
    id: memoUser?.id,
    redirectUserByAuthentication,
    updateCurrentTenant,
  };
}

export default useAuthentication;
