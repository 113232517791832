import { useRouter } from 'next/router';
import { ApplicationRoutes } from '@customer-web-app/domains/shared/enums/application-routes';

export const publicRoutes: string[] = [
  ApplicationRoutes.Login,
  ApplicationRoutes.SignUp,
  ApplicationRoutes.ForgetPassword,
  ApplicationRoutes.Activate,
  ApplicationRoutes.Frontegg,
];

function PublicRoutes({ children }) {
  const { pathname } = useRouter();

  return publicRoutes.includes(pathname) && children;
}

export default PublicRoutes;
