import classNames from 'classnames';
import { Text, Number } from '@blastradius/ui';
import Skeleton from 'react-loading-skeleton';

type Props = {
  label: string;
  value: number;
  loading?: boolean;
};

const BigNumber: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  label,
  value,
  loading = false,
  ...props
}) => {
  const defaultClassName = 'grid gap-4 text-center';
  const className = classNames(defaultClassName, props.className);

  return (
    <div {...props} className={className}>
      {!loading ? (
        <Text type="label" color="text-gray-500">
          {label}
        </Text>
      ) : (
        <Skeleton width={96} height={10} />
      )}

      {!loading ? (
        <Text type="blast" size="large">
          <Number value={value} />
        </Text>
      ) : (
        <Skeleton width={24} height={34} />
      )}
    </div>
  );
};

export default BigNumber;
