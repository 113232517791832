import React from 'react';
import { useRouter } from 'next/router';
import qsStringify from 'qs/lib/stringify';
import qsParse from 'qs/lib/parse';
import { Incident } from '@customer-web-app/domains/incidents/models/incident';
import { UseFormReturn } from 'react-hook-form';
import { gql, useLazyQuery } from '@apollo/client';
import { TimeFrameOptions } from '@customer-web-app/domains/search/models/time-frame-options';
import IncidentFilterService, {
  IncidentFilterParams,
} from '@customer-web-app/domains/incidents/services/incident-filter-service';
import useFeatureFlags from '@customer-web-app/domains/shared/hooks/use-feature-flags';
import { timeFrameOptions } from '@customer-web-app/domains/shared/constants/time-frame-options';
import getTimeFrame from '@customer-web-app/domains/shared/services/get-time-frame';

export type IncidentResultOffset = {
  from?: number;
  size?: number;
  total: number;
};

export type IncidentFilterTypes = 'all' | 'opened' | 'live' | 'closed';

export type UseIncidentsParams = {
  form?: UseFormReturn;
  formValues: IncidentFilterParams;
  setParamsOnUrl?: boolean;
  extraRequestLimit?: number;
  debounceKey?: string;
  debounceTimeout?: number;
  currentPage?: number;
};

export type FetchMoreType = {
  variables: FetchMoreArgs;
};

type FetchMoreArgs = {
  filters: IncidentFilterParams;
  pagination: {
    from: number;
    size: number;
  };
};

export interface IncidentsResponse {
  searchV2: {
    items: Incident[];
    offset: IncidentResultOffset;
  };
}

const incidentTypeSelectionMapper: {
  [key in IncidentFilterTypes]: {
    incidentStatus: string[];
    incidentPhase: string[];
  };
} = {
  all: {
    incidentStatus: ['pending', 'closed', 'falsePositive'],
    incidentPhase: ['live', 'contained', 'remediated'],
  },
  opened: {
    incidentStatus: [],
    incidentPhase: ['live', 'contained', 'remediated'],
  },
  live: { incidentStatus: [], incidentPhase: ['live'] },
  closed: { incidentStatus: ['closed', 'falsePositive'], incidentPhase: [] },
};

export const GET_INCIDENTS_QUERY = gql`
  query GetIncidents(
    $filters: SearchFilter
    $pagination: PaginationSearchOffset
  ) {
    searchV2(filters: $filters, pagination: $pagination) {
      items {
        ... on IncidentSearch {
          id
          alertsCount
          applicationsCount
          assignedTo
          closedAsFalsePositive
          closedBy
          closedOn
          createdOn
          dataObjectsCount
          devicesCount
          iamIdentitiesCount
          incidentName
          incidentNumber
          incidentStatus
          incidentType
          isLive
          alerts {
            alertConnectorVendorCode
          }
          investigationError {
            code
          }
          stepsCount {
            triage {
              done
              total
            }
            investigation {
              done
              total
            }
            containment {
              done
              total
            }
            remediation {
              done
              total
            }
          }
        }
      }
      offset {
        from
        size
        total
      }
    }
  }
`;

export const INCIDENTS_PAGE_LIMIT = 20;

const defaultParamValues: UseIncidentsParams = {
  form: null,
  formValues: null,
  setParamsOnUrl: false,
  extraRequestLimit: 5,
  debounceKey: undefined,
  debounceTimeout: undefined,
  currentPage: 0,
};

function useIncidents(params: UseIncidentsParams) {
  const {
    form,
    formValues,
    setParamsOnUrl,
    debounceKey,
    debounceTimeout,
    currentPage,
  } = {
    ...defaultParamValues,
    ...params,
  };

  const { alertsEnableNetworkAlerts, alertsEnableTriageRequiredAlertStatus } =
    useFeatureFlags();

  const router = useRouter();
  const [pathname, queryString] = router.asPath.split('?');

  const [incidentsResults, setIncidentsResults] = React.useState([]);
  const [incidentsOffsetResults, setIncidentsOffsetResults] =
    React.useState<IncidentResultOffset>({
      total: 0,
    });
  const [loading, setLoading] = React.useState(false);
  const [filters, setFilters] = React.useState(
    IncidentFilterService.buildIncidentFilter(formValues),
  );
  const [timeFrame, setTimeFrame] = React.useState<TimeFrameOptions>(
    (router.query?.timeFrame as TimeFrameOptions) || 'Last 30 Days',
  );

  const [fetch, { fetchMore }] = useLazyQuery<IncidentsResponse>(
    GET_INCIDENTS_QUERY,
    {
      fetchPolicy:
        process.env.NEXT_PUBLIC_ENVIRONMENT === 'test'
          ? 'no-cache'
          : /* istanbul ignore next */
            'cache-and-network',
      notifyOnNetworkStatusChange: true,
      context: {
        debounceKey,
        debounceTimeout,
      },
    },
  );

  async function requestQuery(filters) {
    setLoading(true);

    let results = [];

    const { data } = await fetch({
      variables: {
        filters,
        pagination: {
          from: 0,
          size: INCIDENTS_PAGE_LIMIT,
        },
      },
    });

    results = data?.searchV2?.items || [];

    setIncidentsResults(results);
    setIncidentsOffsetResults(data?.searchV2?.offset);
    setLoading(false);
  }

  async function getIncidentsNextPage(pageNumber: number) {
    setLoading(true);

    let results = [];

    const { data } = await fetchMore({
      variables: {
        filters,
        pagination: {
          from: pageNumber * INCIDENTS_PAGE_LIMIT,
          size: INCIDENTS_PAGE_LIMIT,
        },
      },
    });

    results = data?.searchV2?.items || [];

    setIncidentsResults(results);
    setIncidentsOffsetResults(data?.searchV2?.offset);
    setLoading(false);

    return Promise.resolve();
  }

  // Calls API again when change the filter form
  React.useEffect(() => {
    const newFilters = IncidentFilterService.buildIncidentFilter(formValues);

    setIncidentsResults([]);
    setFilters(newFilters);

    requestQuery(newFilters);

    if (setParamsOnUrl) {
      router.replace(
        `${pathname}${qsStringify(
          {
            ...qsParse(queryString),
            filter: JSON.stringify(newFilters),
            timeFrame,
          },
          {
            addQueryPrefix: true,
          },
        )}`,
      );
    }
  }, [formValues]);

  React.useEffect(() => {
    if (setParamsOnUrl) {
      router.replace(
        `${pathname}${qsStringify(
          {
            ...qsParse(queryString),
            page: currentPage,
          },
          {
            addQueryPrefix: true,
          },
        )}`,
      );
    }
  }, [currentPage]);

  function setTimeFrameOption(option: TimeFrameOptions) {
    setTimeFrame(option);
  }

  function resetTimeFrame() {
    setTimeFrameOption('Last 30 Days');

    form.setValue('globalFilter.dateRange.from', getTimeFrame('Last 30 Days'));
    form.setValue('globalFilter.dateRange.to', new Date());
  }

  function updateDatesAccordingTimeFrame(timeFrame: TimeFrameOptions) {
    if (timeFrameOptions.includes(timeFrame)) {
      form.setValue('globalFilter.dateRange.from', getTimeFrame(timeFrame));
      form.setValue('globalFilter.dateRange.to', new Date());
    }
  }

  function resetFilter(
    form: UseFormReturn,
    defaultIncidentsFilter: IncidentFilterParams,
  ) {
    setTimeFrameOption('Last 30 Days');

    form.reset({
      ...defaultIncidentsFilter,
      globalFilter: { ...form.getValues('globalFilter') },
    });
  }

  function selectIncidentsFilter(incidentType: IncidentFilterTypes) {
    const defaultFilterValues = IncidentFilterService.getDefaultIncidentFilter({
      alertsEnableNetworkAlerts,
      alertsEnableTriageRequiredAlertStatus,
    });

    form.reset({
      ...defaultFilterValues,
      globalFilter: form.getValues('globalFilter'),
      incidentFilter: {
        ...formValues.incidentFilter,
        ...incidentTypeSelectionMapper[incidentType],
      },
    });
  }

  async function fetchMoreIncidents(filter: FetchMoreType) {
    if (fetchMore) {
      const result = await fetchMore(filter);
      return { data: result.data };
    } else
      return {
        data: {
          searchV2: {
            items: [],
            offset: {
              total: 0,
            },
          },
        },
      };
  }

  return {
    incidentsResults,
    incidentsOffsetResults,
    incidentsResultsCount: incidentsResults.length,
    isLoadingIncidents: loading,
    timeFrame,
    fetchMoreIncidents,
    getIncidentsNextPage,
    resetFilter,
    setTimeFrameOption,
    resetTimeFrame,
    updateDatesAccordingTimeFrame,
    selectIncidentsFilter,
  };
}

export default useIncidents;
