import React from 'react';
import classNames from 'classnames';
import { ReactComponent as RSLogoName } from './images/rs-logo-name.svg';

const LogoName: React.FC<React.HTMLAttributes<HTMLImageElement>> = (props) => {
  const className = classNames('h-9', props.className);

  return <RSLogoName title="RadiantSecurity Logo" className={className} />;
};

export default LogoName;
