import classNames from 'classnames';
import React from 'react';
import Text from '../text';

type Sizes = 'regular' | 'large';

type Props = {
  value: number;
  size?: Sizes;
};

const textSizeMapper: {
  [key in Sizes]: {
    textSize: React.ComponentProps<typeof Text>['size'];
  };
} = {
  large: {
    textSize: 'xx-large',
  },
  regular: {
    textSize: 'x-large',
  },
};

function formatHoursToTimeString(value: number): {
  days: number;
  hours: number;
  minutes: number;
} {
  if (!value) {
    return { days: 0, hours: 0, minutes: 0 };
  }
  if (value <= 0.016) {
    return { days: 0, hours: 0, minutes: 1 };
  }

  const totalHours = Math.floor(value);
  const days = Math.floor(totalHours / 24);
  const remainingHours = totalHours % 24;
  const minutes = Math.round((value - totalHours) * 60);

  if (totalHours >= 24) {
    return { days, hours: remainingHours, minutes: 0 };
  }

  return { days: 0, hours: totalHours, minutes };
}

function Hours({
  value,
  size = 'large',
  ...props
}: Props & React.HTMLAttributes<HTMLDivElement>) {
  const defaultClassName = 'flex items-baseline gap-1.5';
  const className = classNames(defaultClassName);

  const { textSize } = textSizeMapper[size];

  const { days, hours, minutes } = formatHoursToTimeString(value);

  const daysLabel = days > 1 ? 'days' : 'day';
  const hoursLabel = hours > 1 ? 'hrs' : 'hr';
  const minutesLabel = minutes > 1 ? 'mins' : 'min';

  return (
    <div {...props} className={className} data-testid={`hours-${value}`}>
      {Boolean(days) && (
        <>
          <Text type="heading" size={textSize} className="!font-blast" as="p">
            {days}
          </Text>
          <Text
            type="heading"
            size="regular"
            className="!font-blast !font-light text-22px"
            as="p"
          >
            {daysLabel}
          </Text>
        </>
      )}
      {Boolean(hours) && (
        <>
          <Text type="heading" size={textSize} className="!font-blast" as="p">
            {hours}
          </Text>
          <Text
            type="heading"
            size="regular"
            className="!font-blast !font-light text-22px"
            as="p"
          >
            {hoursLabel}
          </Text>
        </>
      )}
      {Boolean(minutes) && (
        <>
          <Text type="heading" size={textSize} className="!font-blast" as="p">
            {minutes}
          </Text>
          <Text
            type="heading"
            size="regular"
            className="!font-blast !font-light text-22px"
            as="p"
          >
            {minutesLabel}
          </Text>
        </>
      )}
      {!value && (
        <Text
          type="heading"
          size={textSize}
          className="!font-blast"
          color="text-gray-500"
          as="p"
        >
          0
        </Text>
      )}
    </div>
  );
}

export default Hours;
