import { Icon, LoaderSpinner } from '@blastradius/ui';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import Card from '../../base-components/card';
import InputCheckbox from '../../base-components/input-checkbox';
import Text from '../../base-components/text';

export type TableCol = {
  width: string;
  id?: string;
  label?: string;
  withSort?: boolean;
};

type TableSelectionTypes = 'single' | 'multiple';

type TableToggleStates = 'checked' | 'unchecked';

type TableTriggerOptions = 're-render';

type TableSort = {
  field: string;
  direction: 'asc' | 'desc';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TableValue = any;

type TableRowElement = React.ReactElement<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  string | React.JSXElementConstructor<any>
>[];

type TableOnRowToggledCallback = ({
  state,
  values,
  value,
}: {
  state: TableToggleStates;
  values: TableValue[];
  value: TableValue;
}) => void;

type TableOnRowClickedCallback = (
  e: React.MouseEvent<HTMLElement>,
  {
    state,
    values,
    value,
  }: {
    state: TableToggleStates;
    values: TableValue[];
    value: TableValue;
  },
) => void;

type TableOnAllRowsToggledCallback = ({
  state,
  values,
  previousValues,
}: {
  state: TableToggleStates;
  values: TableValue[];
  previousValues: TableValue[];
}) => void;

type TableProviderProps = {
  cols: TableCol[];
  defaultValues: TableValue[];
  values: TableValue[];
  comparisonKey: string;
  rows: TableRowElement;
  totalRows: number;
  showActions: boolean;
  withAllRowsChecked: boolean;
  allowFullRowToggle: boolean;
  withCheckboxes: boolean;
  showAllCheckboxesWhenChecked: boolean;
  totalAvailable: number;
  maxSelectionCount: number;
  maxResultsPerPage: number;
  loading: boolean;
  reRenderRows: boolean;
  initialRender: boolean;
  sort: TableSort | undefined;
  setCols: React.Dispatch<React.SetStateAction<TableCol[]>>;
  resetToDefault: () => void;
  uncheckAllRows: () => void;
  toggleRow: (value: TableValue) => void;
  toggleAllRows: (
    filterKey?: string,
    comparisonValue?: string,
    allRowsToBeToggled?: TableRowElement,
  ) => void;
  checkRow: (value: TableValue) => void;
  uncheckRow: (value: TableValue) => void;
  trigger: (option: TableTriggerOptions) => void;
  setShowActions: React.Dispatch<React.SetStateAction<boolean>>;
  setComparisonKey: React.Dispatch<React.SetStateAction<string>>;
  setRows: React.Dispatch<React.SetStateAction<TableRowElement>>;
  setValues: React.Dispatch<React.SetStateAction<TableValue[]>>;
  setDefaultValues: React.Dispatch<React.SetStateAction<TableValue[]>>;
  setSelectionType: React.Dispatch<React.SetStateAction<TableSelectionTypes>>;
  setWithAllRowsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  setAllowFullRowToggle: React.Dispatch<React.SetStateAction<boolean>>;
  setWithCheckboxes: React.Dispatch<React.SetStateAction<boolean>>;
  setTotalAvailable: React.Dispatch<React.SetStateAction<number>>;
  setMaxSelectionCount: React.Dispatch<React.SetStateAction<number>>;
  setMaxResultsPerPage: React.Dispatch<React.SetStateAction<number>>;
  setShowAllCheckboxesWhenChecked: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setInitialRender: React.Dispatch<React.SetStateAction<boolean>>;
  setSort: React.Dispatch<React.SetStateAction<TableSort | undefined>>;
  setOnRowToggled: React.Dispatch<
    React.SetStateAction<TableOnRowToggledCallback | undefined>
  >;
  setOnAllRowsToggled: React.Dispatch<
    React.SetStateAction<TableOnAllRowsToggledCallback | undefined>
  >;
};

/* istanbul ignore next */
export const TableContext = React.createContext<TableProviderProps>({
  cols: [],
  defaultValues: [],
  values: [],
  comparisonKey: '',
  rows: [],
  totalRows: 0,
  showActions: false,
  withAllRowsChecked: false,
  allowFullRowToggle: false,
  withCheckboxes: false,
  showAllCheckboxesWhenChecked: false,
  maxSelectionCount: 0,
  maxResultsPerPage: 0,
  totalAvailable: 0,
  loading: false,
  reRenderRows: false,
  initialRender: false,
  sort: { field: '', direction: 'asc' },
  resetToDefault: () => void 0,
  uncheckAllRows: () => void 0,
  toggleRow: () => void 0,
  toggleAllRows: () => void 0,
  checkRow: () => void 0,
  uncheckRow: () => void 0,
  trigger: () => void 0,
  setShowActions: () => void 0,
  setComparisonKey: () => void 0,
  setRows: () => void 0,
  setCols: () => void 0,
  setDefaultValues: () => void 0,
  setSelectionType: () => void 0,
  setWithAllRowsChecked: () => void 0,
  setAllowFullRowToggle: () => void 0,
  setWithCheckboxes: () => void 0,
  setShowAllCheckboxesWhenChecked: () => void 0,
  setTotalAvailable: () => void 0,
  setValues: () => void 0,
  setLoading: () => void 0,
  setInitialRender: () => void 0,
  setMaxSelectionCount: () => void 0,
  setMaxResultsPerPage: () => void 0,
  setSort: () => void 0,
  setOnRowToggled: () => void 0,
  setOnAllRowsToggled: () => void 0,
});

function TableProvider({ children }: { children: React.ReactNode }) {
  // All States
  const [cols, setCols] = React.useState<TableCol[]>([]);
  const [defaultValues, setDefaultValues] = React.useState<TableValue[]>([]);
  const [values, setValues] = React.useState<TableValue[]>([]);
  const [showActions, setShowActions] = React.useState(false);
  const [rows, setRows] = React.useState<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    React.ReactElement<any, string | React.JSXElementConstructor<any>>[]
  >([]);
  const totalRows = React.useMemo(() => rows.length, [rows]);
  const [comparisonKey, setComparisonKey] = React.useState<string>('');
  const [selectionType, setSelectionType] =
    React.useState<TableSelectionTypes>('multiple');
  const [withAllRowsChecked, setWithAllRowsChecked] = React.useState(false);
  const [allowFullRowToggle, setAllowFullRowToggle] = React.useState(false);
  const [withCheckboxes, setWithCheckboxes] = React.useState(false);
  const [maxSelectionCount, setMaxSelectionCount] = React.useState(0);
  const [maxResultsPerPage, setMaxResultsPerPage] = React.useState(0);
  const [showAllCheckboxesWhenChecked, setShowAllCheckboxesWhenChecked] =
    React.useState(false);
  const [totalAvailable, setTotalAvailable] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [initialRender, setInitialRender] = React.useState(true);
  const [reRenderRows, setReRenderRows] = React.useState(false);
  const [sort, setSort] = React.useState<TableSort | undefined>({
    field: '',
    direction: 'asc',
  });

  // Callbacks
  const [onRowToggled, setOnRowToggled] = React.useState<
    TableOnRowToggledCallback | undefined
  >(undefined);
  const [onAllRowsToggled, setOnAllRowsToggled] = React.useState<
    TableOnAllRowsToggledCallback | undefined
  >(undefined);

  // Update values in case of removing rows
  // this happens after the trigger "re-render"
  React.useEffect(() => {
    if (reRenderRows) {
      const tableRowsComparisonKeys = rows.map(
        (row) => row?.props?.value?.[comparisonKey],
      );

      const checkedValuesAccordingCurrentRows = values.filter((value) =>
        tableRowsComparisonKeys.includes(value?.[comparisonKey]),
      );

      setValues(checkedValuesAccordingCurrentRows);
    }
  }, [reRenderRows, rows]);

  // Table Functions
  function toggleRow(value: TableValue) {
    const isIndexChecked = values.findIndex(
      (data) => data?.[comparisonKey] === value?.[comparisonKey],
    );
    const state: TableToggleStates =
      isIndexChecked === -1 ? 'checked' : 'unchecked';

    switch (selectionType) {
      case 'multiple':
        setValues((prevValue) => {
          const updatedRows =
            state === 'checked'
              ? [...prevValue, value]
              : prevValue.filter(
                  (currentRows) =>
                    currentRows?.[comparisonKey] !== value?.[comparisonKey],
                );

          onRowToggled?.({ state, value, values: updatedRows });

          return updatedRows;
        });
        break;
      case 'single':
        setValues(() => {
          const updatedRows = state === 'checked' ? [value] : [];

          onRowToggled?.({ state, value, values: updatedRows });

          return updatedRows;
        });
        break;
    }
  }

  function toggleAllRows(
    filterKey?: string,
    comparisonValue?: string,
    allRowsToBeToggled?: TableRowElement,
  ) {
    const previousValues = values;
    let newValues: TableValue[] = [];
    let state: TableToggleStates = 'unchecked';
    let filteredRows = allRowsToBeToggled?.length ? allRowsToBeToggled : rows;
    if (values.length <= 0) {
      if (filterKey && comparisonValue) {
        filteredRows = rows.filter(
          ({ props }) => props.value[filterKey] === comparisonValue,
        );
      }
      newValues = filteredRows.map(({ props }) => props.value) as TableValue[];
      state = 'checked';
    }
    setValues(newValues);
    onAllRowsToggled?.({ state, values: newValues, previousValues });
  }

  function checkRow(value: TableValue) {
    setValues((prevValue) => {
      const newValues = [...prevValue, value];

      onRowToggled?.({ state: 'checked', value, values: newValues });

      return newValues;
    });
  }

  function uncheckRow(value: TableValue) {
    setValues((prevValue) => {
      const newValues = prevValue.filter(
        (item) => item?.[comparisonKey] !== value?.[comparisonKey],
      );

      onRowToggled?.({ state: 'unchecked', value, values: newValues });

      return newValues;
    });
  }

  function uncheckAllRows() {
    setValues([]);
  }

  function resetToDefault() {
    setValues(defaultValues);
  }

  function trigger(option: TableTriggerOptions) {
    switch (option) {
      case 're-render':
        setReRenderRows(true);

        setTimeout(() => {
          setReRenderRows(false);
        }, 10);
        break;
    }
  }

  React.useLayoutEffect(() => {
    if (defaultValues.length > 0) {
      setValues(defaultValues);
    }
  }, [defaultValues]);

  React.useLayoutEffect(() => {
    if (withAllRowsChecked) {
      toggleAllRows();
    }
  }, [withAllRowsChecked]);

  return (
    <TableContext.Provider
      value={{
        cols,
        defaultValues,
        values,
        comparisonKey,
        rows,
        totalRows,
        showActions,
        withAllRowsChecked,
        allowFullRowToggle,
        withCheckboxes,
        showAllCheckboxesWhenChecked,
        totalAvailable,
        loading,
        reRenderRows,
        maxSelectionCount,
        maxResultsPerPage,
        initialRender,
        sort,
        uncheckAllRows,
        resetToDefault,
        toggleRow,
        toggleAllRows,
        checkRow,
        uncheckRow,
        trigger,
        setCols,
        setShowActions,
        setComparisonKey,
        setRows,
        setDefaultValues,
        setSelectionType,
        setWithAllRowsChecked,
        setAllowFullRowToggle,
        setWithCheckboxes,
        setShowAllCheckboxesWhenChecked,
        setTotalAvailable,
        setMaxSelectionCount,
        setMaxResultsPerPage,
        setValues,
        setLoading,
        setInitialRender,
        setSort,
        // Callbacks as last functions
        setOnRowToggled,
        setOnAllRowsToggled,
      }}
    >
      {children}
    </TableContext.Provider>
  );
}

type HeaderProps = {
  itemDescription?: string;
  itemDescriptionOnPlural?: string;
  loading?: boolean;
  withCounter?: boolean;
  withSelectAll?: boolean;
  columnClassName?: string;
  filterKey?: string;
  comparisonValue?: string;
  areThereMorePages?: boolean;
  maxSelectableCount?: number;
  fetchAllAvailableKeys?: () => Promise<string[]>;
  actions?: ({
    values,
    uncheckAllRows,
  }: {
    values: TableValue[];
    uncheckAllRows: () => void;
  }) => React.ReactNode;
  allRowsToBeToggled?: TableValue[];
};

function Header({
  itemDescription = 'item',
  itemDescriptionOnPlural = 'items',
  withCounter = true,
  withSelectAll = true,
  columnClassName = '',
  children,
  filterKey = '',
  comparisonValue = '',
  areThereMorePages = false,
  maxSelectableCount = 0,
  allRowsToBeToggled,
  actions,
  fetchAllAvailableKeys,
  ...props
}: HeaderProps & React.HTMLAttributes<HTMLDivElement>) {
  const {
    values,
    withCheckboxes,
    cols,
    loading,
    totalRows,
    showActions,
    sort,
    rows,
    comparisonKey,
    maxSelectionCount,
    maxResultsPerPage,
    setShowActions,
    setValues,
    toggleAllRows,
    uncheckAllRows,
    setSort,
  } = React.useContext(TableContext);
  const [isLoading, setIsLoading] = useState(false);
  const areAllRowsSelected = useMemo(() => {
    const valuesIds = values.map((o) => {
      return o[comparisonKey];
    });
    const rowIds = rows.map((row) => row?.props?.value?.[comparisonKey]);
    return (
      valuesIds.length > 0 && !rowIds.some((id) => !valuesIds.includes(id))
    );
  }, [rows, values]);
  const valuesCount = values.length;
  const atLeastOneSelected = valuesCount >= 1;

  React.useEffect(() => {
    setShowActions(valuesCount > 0);
  }, [valuesCount]);

  const checkboxSpacing = withCheckboxes ? '2.3rem' : '';
  const gridTemplateColumns = [
    checkboxSpacing,
    ...cols.map(({ width }) => width),
  ].join(' ');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleSelectAllClick(e: any) {
    toggleAllRows(filterKey, comparisonValue, allRowsToBeToggled);

    if (props?.onClick) {
      props.onClick(e);
    }
  }

  function renderCheckbox() {
    return (
      <InputCheckbox
        checked={atLeastOneSelected}
        size="large"
        background={classNames('bg-gray-200 dark:bg-white/20', {
          'opacity-0 invisible pointer-events-none': loading || !withSelectAll,
        })}
        partial={
          (atLeastOneSelected && valuesCount < totalRows) ||
          (atLeastOneSelected && !areAllRowsSelected)
        }
        onChange={handleSelectAllClick}
      />
    );
  }

  return !showActions || !withSelectAll || !withCounter ? (
    <div
      {...props}
      role="rowheader"
      className={classNames('grid', 'items-center py-4 px-5', props.className)}
      style={{ gridTemplateColumns }}
    >
      {withCheckboxes && renderCheckbox()}

      {cols.map(({ label, id, withSort }) => (
        <div key={label}>
          <Text
            type="body"
            size="small"
            color="text-gray-500"
            className={classNames(
              'inline-flex items-center select-none relative',
              columnClassName,
            )}
            as={withSort && rows.length > 0 ? 'button' : 'span'}
            aria-label={
              withSort && rows.length > 0
                ? `Sort by ${label} ${
                    sort?.field === id && sort?.direction === 'asc'
                      ? 'descending'
                      : 'ascending'
                  }`
                : ''
            }
            onClick={() => {
              /* istanbul ignore else */
              if (withSort) {
                setSort((prevState) => {
                  const oldSort = prevState as TableSort;
                  const newSort: TableSort = {
                    field: id as string,
                    direction: 'asc',
                  };

                  if (oldSort.field === newSort.field) {
                    newSort.direction =
                      oldSort.direction === 'asc' ? 'desc' : 'asc';
                  }

                  return newSort;
                });
              }
            }}
          >
            {label}
            {withSort && sort?.field === id && (
              <Icon
                name="arrow-down"
                size={16}
                className={classNames(
                  'fill-gray-950 dark:fill-white transition-all ease-in-out duration-150 absolute -right-5 -top-px',
                  {
                    'rotate-180 -mt-0.5': sort?.direction === 'asc',
                  },
                )}
              />
            )}
          </Text>
        </div>
      ))}
    </div>
  ) : (
    <div className="flex flex-col">
      <div
        {...props}
        role="rowheader"
        className={classNames(
          'flex',
          'items-center py-4 px-5 gap-1',
          props.className,
        )}
      >
        {withCheckboxes && renderCheckbox()}

        {valuesCount > 0 && (
          <div className="flex items-center gap-4">
            <Text type="body" size="small">
              {valuesCount}{' '}
              {valuesCount === 1 ? itemDescription : itemDescriptionOnPlural}{' '}
              selected
            </Text>
            {!!maxSelectionCount && valuesCount >= maxSelectionCount && (
              <Text
                type="body"
                size="x-small"
                color="text-gray-500 dark:text-gray-500"
                className="shrink-0"
              >
                ({maxSelectionCount} max.)
              </Text>
            )}

            {actions && (
              <>
                <div className="bg-gray-300 dark:bg-gray-800 h-[1rem] w-px"></div>
                {actions({ values, uncheckAllRows })}
              </>
            )}
          </div>
        )}
      </div>
      {valuesCount < maxSelectionCount &&
        areThereMorePages &&
        valuesCount === (maxSelectableCount || maxResultsPerPage) && (
          <Card role="row" className="mb-2">
            <div className="p-4 flex space-y-2 flex-col items-center justify-center">
              <Text type="heading">
                {isLoading && (
                  <LoaderSpinner
                    size="w-4 h-4"
                    circleBackground="after:bg-white after:dark:bg-gray-900"
                    className="inline-block mr-2"
                  />
                )}
                All {itemDescriptionOnPlural} on this page are selected.{' '}
                <button
                  className="underline"
                  disabled={isLoading}
                  onClick={async () => {
                    setIsLoading(true);
                    const availableKeys = await fetchAllAvailableKeys?.();
                    setIsLoading(false);
                    setValues(availableKeys as TableValue[]);
                  }}
                >
                  Select all {itemDescriptionOnPlural} in this filter{' '}
                </button>
              </Text>
            </div>
          </Card>
        )}
    </div>
  );
}

type BodyProps = {
  itemDescriptionOnPlural?: string;
  onSelectAllClick?: () => void;
};
function Body({
  children,
  ...props
}: BodyProps & React.HTMLAttributes<HTMLElement>) {
  return (
    <div
      {...props}
      role={'rowgroup'}
      className={classNames('grid', props.className)}
    >
      {children}
    </div>
  );
}

type TableProps = {
  cols: TableCol[];
  defaultValues?: TableValue[];
  withCheckboxes?: boolean;
  showAllCheckboxesWhenChecked?: boolean;
  totalAvailable?: number;
  maxSelectionCount?: number;
  maxResultsPerPage?: number;
  loading?: boolean;
  comparisonKey?: string;
  rowsClassName?: string;
  selectionType?: TableSelectionTypes;
  initialSort?: TableSort;
  withAllRowsChecked?: boolean;
  allowFullRowToggle?: boolean;
  onRowToggled?: TableOnRowToggledCallback;
  onAllRowsToggled?: TableOnAllRowsToggledCallback;
  isRowValuesLoading?: boolean;
};

function Table({
  cols,
  defaultValues = [],
  comparisonKey = 'id',
  selectionType = 'multiple',
  initialSort,
  withCheckboxes = false,
  showAllCheckboxesWhenChecked = false,
  totalAvailable = 0,
  loading = false,
  withAllRowsChecked = false,
  allowFullRowToggle = false,
  children,
  maxSelectionCount,
  maxResultsPerPage,
  onRowToggled,
  onAllRowsToggled,
  isRowValuesLoading,
  ...props
}: TableProps & React.HTMLAttributes<HTMLDivElement>) {
  const { initialRender, reRenderRows, ...tableContext } =
    React.useContext(TableContext);

  let tableContent = (
    React.Children.toArray(children) as React.ReactElement[]
  ).filter((child) => {
    const childType = child.type;
    return !(childType === Header);
  });

  const firstChildType = tableContent[0]?.type;
  if (firstChildType === Body) {
    tableContent = tableContent[0].props.children;
  }

  const rows = tableContent;

  // Initialization
  React.useEffect(() => {
    tableContext.setCols(cols);
    tableContext.setComparisonKey(comparisonKey);
    tableContext.setDefaultValues(defaultValues);
    tableContext.setSelectionType(selectionType);
    tableContext.setWithAllRowsChecked(withAllRowsChecked);
    tableContext.setAllowFullRowToggle(allowFullRowToggle);
    tableContext.setWithCheckboxes(withCheckboxes);
    tableContext.setShowAllCheckboxesWhenChecked(showAllCheckboxesWhenChecked);
    tableContext.setLoading(loading);
    tableContext.setSort(initialSort);
    tableContext.setOnRowToggled(() => onRowToggled);
    tableContext.setOnAllRowsToggled(() => onAllRowsToggled);
    tableContext.setMaxSelectionCount(maxSelectionCount || 0);
    tableContext.setMaxResultsPerPage(maxResultsPerPage || 0);
  }, []);

  React.useEffect(() => {
    tableContext.setRows(rows);
    tableContext.setInitialRender(false);
  }, [...rows.map((r) => r?.key), isRowValuesLoading]);
  React.useEffect(() => {
    tableContext.setTotalAvailable(totalAvailable);
  }, [totalAvailable]);
  React.useEffect(() => {
    if (!withCheckboxes) {
      tableContext.setValues([]);
    }
    tableContext.setWithCheckboxes(withCheckboxes);
  }, [withCheckboxes]);

  const defaultClassName = 'flex flex-col gap-1 w-full';

  const className = classNames(defaultClassName, props.className, {
    'pointer-events-none': loading,
  });

  return (
    <div {...props} className={className} role="table">
      {children}
    </div>
  );
}

type RowProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  withHover?: boolean;
  hoverButton?: React.ReactElement;
  disabled?: boolean;
  onClick?: TableOnRowClickedCallback;
  checkboxClassName?: string;
  showCheckbox?: boolean;
  openCheckedInNewTab?: boolean;
  withCheckboxHover?: boolean;
};

const Row = React.forwardRef<
  HTMLDivElement,
  RowProps & Omit<React.HTMLAttributes<HTMLElement>, 'onClick'>
>(
  (
    {
      value,
      children,
      withHover,
      hoverButton,
      disabled,
      onClick,
      checkboxClassName,
      showCheckbox = true,
      openCheckedInNewTab,
      withCheckboxHover = true,
      ...props
    },
    ref,
  ) => {
    const {
      values,
      toggleRow,
      comparisonKey,
      withCheckboxes,
      showAllCheckboxesWhenChecked,
      rows,
      cols,
      maxSelectionCount,
      allowFullRowToggle,
    } = React.useContext(TableContext);

    const isChecked = React.useMemo(
      () =>
        values.find(
          (storedValue) =>
            storedValue?.[comparisonKey as string] ===
            value?.[comparisonKey as string],
        ),
      [rows, values],
    );
    const isAnyChecked = React.useMemo(() => !!values.length, [values]);

    const checkboxRef = React.useRef<HTMLInputElement>(null);

    const checkboxSpacing = withCheckboxes ? '2.3rem' : '';
    const hoverButtonSpacing = hoverButton ? '1fr' : '';
    const gridTemplateColumns = [
      checkboxClassName ? '' : checkboxSpacing,
      ...cols.map(({ width }) => width),
      hoverButtonSpacing,
    ].join(' ');

    const defaultClassName = 'py-3 pl-5 pr-4 group';

    let childrenOptions = [] as React.ReactElement[];

    if (children) {
      childrenOptions = React.Children.map(
        children as React.ReactElement[],
        (child: React.ReactElement) => {
          if (child) {
            if (!openCheckedInNewTab) {
              return child;
            }
            return React.cloneElement(child, {
              target: openCheckedInNewTab && isAnyChecked ? '_blank' : '',
            });
          }
          return child;
        },
      );
    }

    const className = classNames(
      'grid items-center',
      props.className,
      defaultClassName,
      {
        'cursor-pointer transition-colors ease-in-out duration-200':
          onClick || withHover || hoverButton,
        'hover:bg-gray-75 dark:hover:bg-white/[.12]':
          (onClick || withHover || hoverButton) && !isChecked,
        group: hoverButton,
        'cursor-pointer': allowFullRowToggle,
        'pointer-events-none': disabled,
      },
    );

    function handleSelectRow() {
      toggleRow(value);
    }

    function handleClick(e: React.MouseEvent<HTMLDivElement>) {
      /* istanbul ignore else */
      if (
        !['input', 'button'].includes(
          (e.target as HTMLElement).tagName.toLowerCase(),
        )
      ) {
        onClick?.(e, {
          state: isChecked ? 'checked' : 'unchecked',
          values,
          value,
        });

        /* istanbul ignore else */
        if (allowFullRowToggle) {
          handleSelectRow();
        }
      }
    }

    return (
      <Card
        {...props}
        className={className}
        style={{ gridTemplateColumns }}
        background={
          !isChecked
            ? 'bg-white dark:bg-gray-900'
            : 'bg-gray-75 dark:bg-white/[.12]'
        }
        onClick={handleClick}
        ref={ref}
        role="row"
        aria-selected={isChecked}
      >
        {withCheckboxes && showCheckbox && (
          <div
            className={classNames(
              'transition-fade ease-in-out duration-200',
              {
                'opacity-100 visible':
                  isChecked || (showAllCheckboxesWhenChecked && isAnyChecked),
                'opacity-0 invisible group-hover:opacity-100 group-hover:visible':
                  !isChecked && !(showAllCheckboxesWhenChecked && isAnyChecked),
                '!opacity-100 !visible': !withCheckboxHover,
              },
              checkboxClassName,
            )}
          >
            <InputCheckbox
              checked={isChecked}
              size="large"
              onChange={handleSelectRow}
              background="bg-gray-200 dark:bg-white/20"
              data-testid="table-row-checkbox"
              disabled={
                !!maxSelectionCount &&
                values.length >= maxSelectionCount &&
                !isChecked
              }
              ref={checkboxRef}
            />
          </div>
        )}

        {childrenOptions}

        {hoverButton && (
          <div className="flex justify-end">
            <div className="inline-flex justify-end gap-2">{hoverButton}</div>
          </div>
        )}
      </Card>
    );
  },
);

const RowHoverButton = React.forwardRef<
  HTMLElement,
  React.HTMLAttributes<HTMLElement>
>(({ children, ...props }, ref) => {
  return (
    <span
      ref={ref}
      data-testid="table-row-hover-button"
      {...props}
      className={classNames(
        props.className,
        'ml-auto invisible opacity-0 group-hover:visible group-hover:opacity-100',
      )}
    >
      {children}
    </span>
  );
});

export const withTable = <T extends React.HTMLAttributes<HTMLElement>>(
  Component: React.ComponentType<T>,
) => {
  return (props: T) => {
    return (
      <TableProvider>
        <Component {...props} />
      </TableProvider>
    );
  };
};

Table.Provider = TableProvider;
Table.Header = Header;
Table.Body = Body;
Table.Row = Row;
Table.RowHoverButton = RowHoverButton;

export default Table;
