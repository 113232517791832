import { SortTypes } from '@customer-web-app/domains/search/enums/sort-types';
import { Alert } from '@customer-web-app/domains/alerts/models/alert';

const searchSortMapper: {
  [key in SortTypes]: {
    field: keyof Alert;
    order: 'asc' | 'desc';
  }[];
} = {
  [SortTypes.TimestampAsc]: [
    {
      field: 'createdOn',
      order: 'asc',
    },
  ],
  [SortTypes.TimestampDesc]: [
    {
      field: 'createdOn',
      order: 'desc',
    },
  ],
  [SortTypes.AlertPriority]: [
    {
      field: 'alertPriority',
      order: 'desc',
    },
    {
      field: 'createdOn',
      order: 'desc',
    },
  ],
};

export default searchSortMapper;
