import React from 'react';
import useSWR from 'swr';
import { fronteggFetcher } from '@customer-web-app/domains/shared/api/services/fetchers-service';
import { User } from '@customer-web-app/domains/users/models/user';
import Joi from 'joi';
import sortBy from 'lodash/sortBy';
import useAuthentication from '@customer-web-app/domains/authentication/hooks/use-authentication';

interface UsersResponse {
  items: User[];
}

function useUsers() {
  const { user } = useAuthentication();

  const { data, error } = useSWR<UsersResponse>(
    user?.id && '/identity/resources/users/v2',
    fronteggFetcher,
    {
      errorRetryCount: 5,
      errorRetryInterval: 20000,
    },
  );

  const users = React.useMemo(
    () =>
      (data?.items || []).map((user) => {
        // By default, when there is no name on the user"s invite, FrontEgg adds the email value for both fields.
        // This check is to prevent repeating the email on the User's Card.
        const nameHasEmailOnValue = !Joi.string()
          .email({ tlds: { allow: false } })
          .validate(user.name).error;

        return {
          ...user,
          name: nameHasEmailOnValue ? '' : user.name.trim(),
        };
      }),
    [data],
  );

  const activeUsers = React.useMemo(() => {
    const activeUsers = users?.filter(
      ({ lastLogin, name, email }) => lastLogin && (name || email),
    );

    const formatUsers = activeUsers?.map(({ name, email, ...user }) => ({
      ...user,
      name: name || email,
      email,
    }));

    return sortBy(formatUsers, ({ name, email }) =>
      name ? name.toLowerCase() : email.toLowerCase(),
    );
  }, [users]);

  return {
    users,
    activeUsers,
    isLoadingUsers: !error && !data,
    usersError: error,
  };
}

export default useUsers;
