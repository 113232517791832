import React from 'react';

const Main: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = (props) => {
  return (
    <main
      id="main"
      className="inline-block flex flex-col w-full overflow-y-auto lg:overflow-x-hidden lg:pt-0 pl-0 lg:pl-16 h-screen"
      {...props}
    />
  );
};

export default Main;
