import React from 'react';
import { ReactComponent as NavigatorEmptyState } from './assets/navigator-empty-state.svg';
import { Text } from '@blastradius/ui';
import classNames from 'classnames';

type Props = {
  height?: string;
};

const MainSideNavigationEmptyState: React.FC<
  Props & React.HTMLAttributes<HTMLElement>
> = ({ children, height = 'h-[80vh]', ...props }) => {
  const defaultClassName =
    'flex flex-col items-center justify-center px-4 gap-2';
  const className = classNames(defaultClassName, height);

  return (
    <div className={className} {...props}>
      <NavigatorEmptyState className="fill-gray-100 dark:fill-gray-900" />
      <div className="flex justify-center items-center w-[31rem] h-16">
        <Text type="heading" size="small">
          {children}
        </Text>
      </div>
    </div>
  );
};

export default MainSideNavigationEmptyState;
