import React from 'react';
import classNames from 'classnames';
import Text from '../../base-components/text';
import { Icon } from '@blastradius/ui';
import { Icons } from '@blastradius/ui/icons';

type NavigatorYStates = 'idle' | 'on' | 'off';

type NavigatorYProps = {
  composed?: boolean;
};

type NavigatorYItemGroupProps = {
  subGroup?: boolean;
};

type NavigatorYTitleProps = {
  state?: NavigatorYStates;
  icon: Icons;
};

type NavigatorYAnchorProps = {
  state?: NavigatorYStates;
};

type NavigatorYItemComposedProps = {
  state?: NavigatorYStates;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  icon?: Icons;
  iconAriaLabel?: string;
  childrenClassName?: string;
};

type NavigatorYItemGroupType = React.FC<
  NavigatorYItemGroupProps & React.HTMLAttributes<HTMLUListElement>
>;

type NavigatorYItemType = React.FC<React.HTMLAttributes<HTMLLIElement>>;

type NavigatorYTitleType = React.FC<
  NavigatorYTitleProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
>;

type NavigatorYAnchorType = React.FC<
  NavigatorYAnchorProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
>;

type NavigatorYItemComposedType = React.FC<
  NavigatorYItemComposedProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
>;

type SubComponents = {
  ItemGroup: NavigatorYItemGroupType;
  Item: NavigatorYItemType;
  Title: NavigatorYTitleType;
  Anchor: NavigatorYAnchorType;
  ItemComposed: NavigatorYItemComposedType;
};

const NavigatorY: React.FC<
  NavigatorYProps & React.HTMLAttributes<HTMLDivElement>
> &
  SubComponents = ({ composed, children, ...props }) => {
  const className = classNames('grid', {
    'gap-7': !composed,
    'gap-12': composed,
  });

  return (
    <nav className={className} {...props}>
      {children}
    </nav>
  );
};

const ItemGroup: NavigatorYItemGroupType = ({
  subGroup,
  children,
  ...props
}) => {
  const defaultClassName = classNames('flex flex-col', {
    'gap-4': subGroup,
    'gap-6': !subGroup,
  });
  return (
    <ul {...props} className={defaultClassName}>
      {children}
    </ul>
  );
};
NavigatorY.ItemGroup = ItemGroup;

const Item: NavigatorYItemType = ({ children, ...props }) => {
  return <li {...props}>{children}</li>;
};
NavigatorY.Item = Item;

const Title: NavigatorYTitleType = ({ state, icon, children, ...props }) => {
  const className = classNames(
    props.className,
    'flex items-center gap-2 w-full mb-4 cursor-default',
    {
      'opacity-60 pointer-events-none': state === 'off',
    },
  );
  const iconClassName = classNames({
    'fill-gray-500': state !== 'on',
    'fill-gray-950 dark:fill-white': state === 'on',
  });
  const textClassName = classNames({
    'text-gray-500': state !== 'on',
    'text-gray-950 dark:text-white': state === 'on',
  });

  return (
    <span className={className} {...props}>
      <Icon name={icon} size={20} className={iconClassName} />
      <Text type="label" color={textClassName}>
        {children}
      </Text>
    </span>
  );
};
NavigatorY.Title = Title;

const Anchor = React.forwardRef<
  HTMLAnchorElement,
  NavigatorYAnchorProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
>(({ state, children, ...props }, ref) => {
  const className = classNames(
    props.className,
    `ml-7 relative before:absolute before:bg-gray-200 dark:before:bg-gray-700
    before:w-0.5 before:-top-2 before:h-10 before:-left-5 before:rounded-xl
    before:transition-all before:ease-in-out before:duration-200`,
    {
      'before:bg-gray-900 dark:before:bg-white': state === 'on',
      'hover:before:bg-black hover:dark:before:bg-white hover:before:opacity-60':
        state === 'idle',
    },
  );
  const textClassName = classNames(
    'cursor-pointer transition duration-200ms ease-in-out',
    {
      'text-gray-500 hover:text-gray-950 dark:hover:text-white': state !== 'on',
      'text-gray-200 dark:text-gray-700 pointer-events-none': state === 'off',
      'text-gray-950 dark:text-white': state === 'on',
    },
  );

  return (
    <a className={className} {...props} ref={ref}>
      <Text type="body" color={textClassName}>
        {children}
      </Text>
    </a>
  );
});
NavigatorY.Anchor = Anchor;

const ItemComposed: NavigatorYItemComposedType = ({
  state,
  header,
  footer,
  children,
  icon,
  iconAriaLabel,
  childrenClassName = '',
  ...props
}) => {
  const className = classNames(
    'cursor-pointer',
    {
      'grid grid-cols-[2rem_1fr] gap-4': icon,
      'text-gray-600 hover:text-gray-500': state !== 'on',
    },
    props.className,
  );
  const textClassName = classNames(
    'w-44 whitespace-nowrap overflow-hidden text-ellipsis',
    {
      'text-gray-950 dark:text-gray-50': state === 'on',
    },
    childrenClassName,
  );
  const defaultClassName = 'transition-colors duration-200 ease-in-out';

  return (
    <a {...props} className={className}>
      {icon && (
        <span className="flex items-center justify-center h-8 rounded bg-white dark:bg-gray-900">
          <Icon
            aria-label={iconAriaLabel}
            name={icon}
            size={20}
            className={classNames({
              'fill-gray-300 dark:fill-gray-500': state !== 'on',
              'fill-black dark:fill-white': state === 'on',
            })}
          />
        </span>
      )}
      <span className="flex flex-col">
        {header && (
          <Text
            type="label-caps"
            gradient={state === 'on'}
            color={classNames(defaultClassName, {
              'from-red-500 to-pink-500': state === 'on',
            })}
            className="mb-2"
          >
            {header}
          </Text>
        )}
        <Text
          type="body"
          size="large"
          color={classNames(defaultClassName, textClassName)}
        >
          {children}
        </Text>
        {footer && (
          <Text
            type="body"
            size="small"
            color={classNames(defaultClassName, {
              'text-gray-500': state === 'on',
            })}
            className="mt-2"
          >
            {footer}
          </Text>
        )}
      </span>
    </a>
  );
};
NavigatorY.ItemComposed = ItemComposed;

export default NavigatorY;
