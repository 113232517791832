import React from 'react';
import classNames from 'classnames';
import Text from '../../base-components/text';
import styles from './navigator-x.module.scss';
import ScrollContainer from 'react-indiana-drag-scroll';

type NavigatorXStates = 'idle' | 'on' | 'off';

type NavigatorXItemProps = {
  state?: NavigatorXStates;
  stateOnTextColor?: string;
  as?: React.ElementType;
  size?: 'small' | 'regular';
  textType?: React.ComponentProps<typeof Text>['type'];
  disabled?: boolean;
};

type SubComponents = {
  Item: React.FC<
    NavigatorXItemProps &
      (
        | React.AnchorHTMLAttributes<HTMLAnchorElement>
        | React.ButtonHTMLAttributes<HTMLButtonElement>
      )
  >;
};

export interface INavigatorXContext {
  hoveredItemStyle: { width: number; left: number; opacity: number };
  setHoveredItemStyle: React.Dispatch<
    React.SetStateAction<INavigatorXContext['hoveredItemStyle']>
  >;
}

/* This context is used to share the references of current hovered NavigatorX.Item 
   getting the width and left values it's possible to make the hover bar dynamic */
/* istanbul ignore next */
const NavigatorXContext = React.createContext<Partial<INavigatorXContext>>({
  setHoveredItemStyle: () => void 0,
});

const NavigatorX: React.FC<React.HTMLAttributes<HTMLDivElement>> &
  SubComponents = ({ children, ...props }) => {
  const [hoveredItemStyle, setHoveredItemStyle] = React.useState<
    INavigatorXContext['hoveredItemStyle']
  >({ width: 0, left: 0, opacity: 0 });

  const className = classNames(
    props.className,
    'flex relative z-10',
    styles.navigatorXBefore,
  );

  return (
    <NavigatorXContext.Provider value={{ setHoveredItemStyle }}>
      <div className={className} {...props} aria-hidden>
        <ScrollContainer
          style={{ display: 'inherit', position: 'inherit' }}
          component="nav"
          className="flex"
        >
          {children}
          <span
            className="h-0.5 absolute bottom-0 z-10 bg-gray-500 transition-all duration-200 ease-in-out"
            style={hoveredItemStyle}
            data-testid="hover-pill"
          />
        </ScrollContainer>
      </div>
    </NavigatorXContext.Provider>
  );
};

const Item = React.forwardRef<
  | React.AnchorHTMLAttributes<HTMLAnchorElement>
  | React.ButtonHTMLAttributes<HTMLButtonElement>,
  NavigatorXItemProps &
    (
      | React.AnchorHTMLAttributes<HTMLAnchorElement>
      | React.ButtonHTMLAttributes<HTMLButtonElement>
    )
>(
  (
    {
      state = 'idle',
      stateOnTextColor = 'text-gray-950 dark:text-gray-50',
      as = 'a',
      size = 'regular',
      children,
      textType = 'label',
      disabled = false,
      ...props
    },
    // Ref not used, just to avoid nextjs errors
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _ref,
  ) => {
    const { setHoveredItemStyle } = React.useContext(NavigatorXContext);
    const anchorRef = React.useRef<HTMLAnchorElement>(null);
    const [debounce, setDebounce] = React.useState<NodeJS.Timeout>();

    const className = classNames(
      props.className,
      `flex items-center justify-center 
     relative z-20 py-5 
     text-center cursor-pointer 
     border-b-2 border-solid border-transparent min-w-max select-none`,
      {
        '!border-gray-950 dark:!border-white z-30': state === 'on',
        'pointer-events-none': state === 'off' || disabled,
        'px-6': size === 'regular',
        'px-5': size === 'small',
      },
    );
    const textClassName = classNames({
      [`text-gray-500 hover:text-gray-950 dark:hover:text-white 
     transition-all duration-200 ease-in-out`]: state === 'idle',
      'text-gray-200 dark:text-gray-700': state === 'off' || disabled,
      [stateOnTextColor]: state === 'on',
    });

    const handleEnterItem = () => {
      clearTimeout(debounce as NodeJS.Timeout);

      setDebounce(
        setTimeout(() => {
          const { offsetWidth, offsetLeft } = anchorRef.current || {};

          setHoveredItemStyle?.({
            width: offsetWidth as number,
            left: offsetLeft as number,
            opacity: 1,
          });
        }, 100),
      );
    };

    const handleLeaveItem = () => {
      clearTimeout(debounce as NodeJS.Timeout);

      setDebounce(
        setTimeout(() => {
          setHoveredItemStyle?.((prevValue) => ({
            ...prevValue,
            opacity: 0,
          }));
        }, 100),
      );
    };

    return React.createElement(
      as,
      {
        type: as === 'button' ? 'button' : undefined,
        ...props,
        className: classNames(className, textClassName),
        onMouseEnter: handleEnterItem,
        onMouseLeave: handleLeaveItem,
        ref: anchorRef,
        'data-state': state,
      },
      <Text type={textType} color="">
        {children}
      </Text>,
    );
  },
);
NavigatorX.Item = Item;

export default NavigatorX;
