import React from 'react';
import { ToastContext } from '@blastradius/ui';

function useToast(): React.ComponentProps<
  typeof ToastContext.Provider
>['value'] {
  const toastContext = React.useContext(ToastContext);

  return toastContext;
}

export default useToast;
