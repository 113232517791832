import classNames from 'classnames';
import React from 'react';
import Text from '../text';
import Number from '../number';

type Sizes = 'regular' | 'large';

type Props = {
  value: number;
  size?: Sizes;
};

const textSizeMapper: {
  [key in Sizes]: {
    textSize: React.ComponentProps<typeof Text>['size'];
  };
} = {
  large: {
    textSize: 'xx-large',
  },
  regular: {
    textSize: 'x-large',
  },
};

function Cost({
  value,
  size = 'large',
  ...props
}: Props & React.HTMLAttributes<HTMLDivElement>) {
  const defaultClassName = 'flex items-baseline gap-1.5';
  const className = classNames(defaultClassName);

  const { textSize } = textSizeMapper[size];

  const costValue = value || 0;

  const textColor = classNames({
    'text-gray-950 dark:text-gray-50': value,
    'text-gray-500': !value,
  });

  return (
    <div {...props} className={className} data-testid={`cost-${value}`}>
      <Text
        type="heading"
        size="regular"
        className="!font-blast !font-light text-22px"
        color={textColor}
        as="p"
      >
        $
      </Text>
      <Text
        type="heading"
        size={textSize}
        className="!font-blast"
        color={textColor}
        as="p"
      >
        <Number value={costValue} />
      </Text>
    </div>
  );
}

export default Cost;
