import React from 'react';
import classNames from 'classnames';
import BigNumber from '../big-number';

type Props = {
  label: string;
  value: number;
  active?: boolean;
  disabled?: boolean;
  as?: 'a' | 'button';
  loading?: boolean;
  color?: string;
  colorActive?: string;
};

const BigNumberButton = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  Props &
    React.AnchorHTMLAttributes<HTMLAnchorElement> &
    React.ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      label,
      value,
      active = false,
      disabled = false,
      as = 'a',
      loading = false,
      color = 'bg-white/50 dark:bg-gray-900/50 hover:bg-white hover:dark:bg-gray-900',
      colorActive = 'bg-white dark:bg-gray-900',
      ...props
    },
    ref,
  ) => {
    const defaultClassName = `flex justify-center items-center py-5 px-4 
      transition-color duration-100 ease-in-out cursor-pointer`;
    const className = classNames(props.className, defaultClassName, {
      [`${color} rounded border-b-solid border-b-2 border-b-transparent`]:
        !active,
      [`${colorActive} border-b-solid border-b-2 border-b-gray-200 dark:border-b-white rounded-t`]:
        active && !loading,
      'pointer-events-none': loading || disabled,
    });

    return React.createElement(
      as,
      {
        type: as === 'button' ? 'button' : undefined,
        ...props,
        className,
        ref,
      },
      <BigNumber
        label={label}
        value={value}
        loading={loading}
        className="gap-2"
      />,
    );
  },
);

export default BigNumberButton;
