import React from 'react';
import { GlobalStoreContext } from '@customer-web-app/domains/shared/providers/global-store-provider';
import { FeatureFlagsStructure } from '@customer-web-app/domains/shared/providers/feature-flags-provider';

function useFeatureFlags(): FeatureFlagsStructure {
  const { globalStoreState } = React.useContext(GlobalStoreContext);

  return globalStoreState.featureFlags;
}

export default useFeatureFlags;
