import { useTenantsState } from '@frontegg/react-hooks/auth';
import { Tenant } from '@customer-web-app/domains/tenants/models/tenant';
import { useForm } from 'react-hook-form';
import normalizeString from '@customer-web-app/domains/shared/services/normalize-string';

function useTenants() {
  const { tenants } = useTenantsState();

  const form = useForm({
    defaultValues: {
      search: '',
    },
  });

  const searchValue = form.watch('search');

  const filteredTenants = tenants?.filter(({ name }) =>
    normalizeString(name).includes(normalizeString(searchValue)),
  );

  return {
    tenants: filteredTenants as Tenant[],
    form,
  };
}

export default useTenants;
