import React from 'react';
import classNames from 'classnames';
import Text from '../text';
import Icon from '../icon';

type Props = {
  as?: React.ElementType;
  onRemove?: () => void;
  icon?: React.ComponentProps<typeof Icon>['name'];
};

const Tag: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  as = 'span',
  onRemove,
  icon,
  children,
  ...props
}) => {
  const defaultClassName = `inline-flex px-1.5 py-1 rounded-sm select-none cursor-default 
        border border-solid border-gray-400  dark:border-gray-600`;
  const className = classNames(defaultClassName, props.className);

  return React.createElement(
    as,
    { ...props, className },
    <Text
      type="body"
      size="x-small"
      color="text-gray-950 dark:text-white flex items-center"
    >
      {!!icon && <Icon name={icon} size={12} className="mr-1" />}

      {children}

      {onRemove && (
        <Icon
          name="close"
          size={12}
          onClick={onRemove}
          role="button"
          className="ml-1 -mt-px cursor-pointer"
          aria-label="Remove tag"
        />
      )}
    </Text>,
  );
};

export default Tag;
