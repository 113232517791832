import { IncidentTypes } from '@customer-web-app/domains/incidents/models/incident';

const incidentTypesCopies: { [key in IncidentTypes]: string } = {
  [IncidentTypes.Phishing]: 'Phishing',
  [IncidentTypes.Malware]: 'Malware',
  [IncidentTypes.BEC]: 'BEC',
  [IncidentTypes.Endpoint]: 'Endpoint',
  [IncidentTypes.Identity]: 'Identity',
  [IncidentTypes.Network]: 'Network',
  [IncidentTypes.Generic]: 'Vendor specific',
};

export default incidentTypesCopies;
