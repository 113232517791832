import classNames from 'classnames';
import React from 'react';
import Icon from '../../base-components/icon';
import useShortcut from '../../hooks/use-shortcut';
import { DialogContext } from './dialog-provider';
import styles from './dialog.module.scss';

type Colors = 'red' | 'gray' | 'white' | 'green';

export type DialogProps = {
  width?: string;
  height?: string;
  color?: Colors;
  withBackground?: boolean;
  withGradient?: boolean;
  gradientColor?: Colors;
  allowClose?: boolean;
  opened?: boolean;
  closeDialog?: () => void;
  backgroundClassName?: string;
};

type DialogActivateProps = {
  dialog: {
    component: React.ReactNode | React.FC;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props?: any;
  };
  disabled?: boolean;
  children: React.ReactElement;
};

type DialogDeactivateProps = {
  children?: React.ReactElement;
  closeDialog?: () => void;
};

type DialogType = React.FC<DialogProps & React.HTMLAttributes<HTMLElement>>;

type DialogActivateType = React.FC<
  DialogActivateProps & React.HTMLAttributes<HTMLElement>
>;

type DialogDeactivateType = React.FC<
  DialogDeactivateProps & React.HTMLAttributes<HTMLElement>
>;

type SubComponents = {
  Activate: DialogActivateType;
  Deactivate: DialogDeactivateType;
};

const colorsMapper: {
  [key in Colors]: {
    borderColor: string;
    backgroundColor: string;
  };
} = {
  gray: {
    borderColor: 'border-gray-500',
    backgroundColor: 'bg-gray-50 dark:bg-gray-925',
  },
  red: {
    borderColor: 'border-pink-500',
    backgroundColor: 'bg-gray-50 dark:bg-gray-950',
  },
  white: {
    borderColor: 'border-white dark:border-gray-900',
    backgroundColor: 'bg-white dark:bg-gray-900',
  },
  green: {
    borderColor: 'border-green-500',
    backgroundColor: 'bg-gray-50 dark:bg-gray-950',
  },
};

const Dialog: DialogType & SubComponents = ({
  width = 'w-[31.625rem]',
  height = 'h-[27.75rem]',
  color = 'red',
  withBackground = true,
  withGradient = true,
  gradientColor = color,
  children,
  allowClose = true,
  opened: openedProp = false,
  closeDialog: closeDialogProp,
  backgroundClassName = '',
  ...props
}) => {
  const { opened, closeDialog } = React.useContext(DialogContext);

  if (allowClose) {
    useShortcut([
      {
        keys: ['Escape'],
        callback: () => {
          closeDialog();
          if (closeDialogProp) closeDialogProp();
        },
      },
    ]);
  }

  const dialogBackgroundClassName = classNames(
    `fixed top-0 left-0 w-screen h-screen z-60 transition-opacity
    duration-200 ease-in-out`,
    backgroundClassName,
    {
      'bg-gray-950': withBackground,
      'bg-opacity-80 visible': opened || openedProp,
      'bg-opacity-0 invisible': !opened && !openedProp,
    },
  );

  const dialogClassName = classNames(
    `absolute top-1/4 my-0 mx-auto left-0 right-0 rounded transition-all ease-in-out
    duration-200 shadow-sm border-t-2 relative overflow-hidden`,
    width,
    height,
    colorsMapper[color].borderColor,
    colorsMapper[color].backgroundColor,
    props.className,
    {
      'animate-enter': opened || openedProp,
      'animate-leave': !opened && !openedProp,
    },
  );

  return (
    <div role="dialog" className={dialogBackgroundClassName}>
      <div {...props} className={dialogClassName}>
        {children}

        {withGradient && (
          <>
            {gradientColor === 'red' && (
              <>
                <div className={styles.dialogRedGradientRight} />
                <div className={styles.dialogRedGradientLeft} />
              </>
            )}

            {gradientColor === 'gray' && (
              <>
                <div className={styles.dialogGrayGradientRight} />
                <div className={styles.dialogGrayGradientLeft} />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const DialogActivate: DialogActivateType = ({
  dialog: { component, props: dialogProps },
  children,
  disabled = false,
  ...props
}) => {
  const { openDialog } = React.useContext(DialogContext);
  const className = classNames('cursor-pointer', children.props.className);

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    if (!disabled) {
      openDialog(component, dialogProps);
      props.onClick?.(e);
    }
  }

  return React.cloneElement(children, {
    onClick: handleClick,
    'aria-label': 'Open dialog',
    className,
    ...props,
  });
};
Dialog.Activate = DialogActivate;

const DialogDeactivate: DialogDeactivateType = ({
  children,
  closeDialog: closeDialogProp,
  ...props
}) => {
  const { closeDialog } = React.useContext(DialogContext);
  const cloneClassName = classNames('cursor-pointer z-70', props.className);
  const defaultClassName = classNames(
    'w-8 h-8 rounded bg-gray-950/[.06] dark:bg-gray-100/[.08] hover:bg-gray-800/[.04] dark:hover:bg-white/[.06] transition-colors duration-200 ease-in-out shrink-0',
    props.className,
  );

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    closeDialog();
    if (closeDialogProp) closeDialogProp();
    props.onClick?.(e);
  }

  return children ? (
    React.cloneElement(children, {
      onClick: handleClick,
      'aria-label': 'Close dialog',
      ...props,
      className: cloneClassName,
    })
  ) : (
    <button
      {...props}
      className={defaultClassName}
      onClick={handleClick}
      aria-label="Close dialog"
      type="button"
    >
      <Icon
        name="close"
        size={16}
        className="-mt-px fill-gray-950 dark:fill-white"
      />
    </button>
  );
};
Dialog.Deactivate = DialogDeactivate;

export default Dialog;
