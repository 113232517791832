import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { HttpStatusCode } from '../http-status-code';
import SessionService from '@customer-web-app/domains/authentication/services/session-service';
import api from '@customer-web-app/domains/shared/api';
import { delay } from '@customer-web-app/domains/shared/services/delay';

export function responseSuccessInterceptor({ data }: AxiosResponse) {
  return Promise.resolve(data);
}

export async function responseErrorInterceptor(error: AxiosError) {
  const { status } = error?.response || {};
  const config = error?.config as AxiosRequestConfig & { retry: number };

  switch (status) {
    case HttpStatusCode.Unauthorized:
      if (!config.retry) {
        return SessionService.logoutFromClient();
      }

      config.retry--;

      await delay(100);

      return api(config);

    case HttpStatusCode.Forbidden:
      return Promise.reject({
        status,
        message: 'You are not allowed to execute this action',
      });

    case HttpStatusCode.NotFound:
      return Promise.reject({
        status,
        message: 'The resource has not been found',
      });

    default:
      return Promise.reject({
        status,
        message: 'The application has encountered an error',
        data: error.response?.data,
      });
  }
}
