import React from 'react';
import classNames from 'classnames';
import Text from '../text';
import Icon from '../icon';

type Sizes = 'x-small' | 'small' | 'medium';

const sizeMapper: {
  [key in Sizes]: {
    imageSize: number;
    initialsSize?: string;
    iconSize: React.ComponentProps<typeof Icon>['size'];
  };
} = {
  'x-small': {
    imageSize: 6,
    iconSize: 14,
  },
  small: {
    imageSize: 10,
    iconSize: 16,
    initialsSize: '!text-12px',
  },
  medium: {
    imageSize: 18,
    initialsSize: '!text-18px tracking-x-widest',
    iconSize: 32,
  },
};

type Props = {
  name?: string;
  size?: Sizes;
  withHover?: boolean;
  grayscale?: boolean;
  colorWhenHover?: boolean;
  colorWhenHoverGroup?: string;
  textColor?: string;
};

const Avatar: React.FC<Props & React.ImgHTMLAttributes<HTMLImageElement>> = ({
  name,
  size = 'small',
  children,
  alt,
  src,
  withHover = false,
  grayscale = false,
  colorWhenHover = null,
  textColor = '',
  colorWhenHoverGroup = '',
  ...props
}) => {
  const { imageSize, initialsSize, iconSize } = sizeMapper[size];
  const defaultClassName = 'rounded-full';
  const className = classNames(
    `w-${imageSize} h-${imageSize}`,
    props.className,
    defaultClassName,
  );

  if (src && !src.includes('gravatar')) {
    if (!alt) {
      // eslint-disable-next-line no-console
      console.warn(
        `BlastRadius UI / Avatar: Property "alt" not found for the image "${src}", consider add an alternative text.`,
      );
    }

    return (
      <img
        {...props}
        src={src}
        alt={alt}
        className={classNames(
          className,
          'transition-all duration-200 ease-in-out shrink-0',
          {
            'hover:opacity-80 cursor-pointer': withHover,
            grayscale: grayscale || colorWhenHover,
            [`${colorWhenHoverGroup}hover:grayscale-0`]: colorWhenHover,
          },
        )}
        referrerPolicy="no-referrer"
      />
    );
  }

  const nameSplit = (name || '').trim().split(' ');

  const nameSplitInitials =
    nameSplit.length > 1
      ? `${nameSplit[0].charAt(0)}${nameSplit[nameSplit.length - 1].charAt(0)}`
      : nameSplit[0].substring(0, 2).toUpperCase();

  return (
    <div
      {...props}
      className={classNames(
        'bg-gray-200 dark:bg-gray-700 flex items-center justify-center transition duration-200 ease-in-out shrink-0',
        className,
        {
          'hover:bg-gray-300 dark:hover:bg-gray-600 cursor-pointer': withHover,
          'pointer-events-none': !withHover,
        },
      )}
    >
      {nameSplitInitials.length > 0 && (
        <Text
          type="label-caps"
          size="regular"
          color={textColor || 'text-gray-900 dark:text-gray-50'}
          className={classNames(initialsSize, 'uppercase select-none')}
          data-testid="avatar-initials"
        >
          {nameSplitInitials}
        </Text>
      )}
      {!name && (
        <Icon
          name="involved"
          size={iconSize}
          className="fill-gray-900 dark:fill-gray-50"
        />
      )}
    </div>
  );
};

export default Avatar;
