import classNames from 'classnames';
import { Text, Number } from '@blastradius/ui';
import Skeleton from 'react-loading-skeleton';

type Props = {
  label: string;
  labelColor?: string;
  value: number;
  valueColor?: string;
  textLeft?: boolean;
  loading?: boolean;
};

const LabelNumber: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  label,
  labelColor = 'text-gray-950 dark:text-gray-50',
  value,
  valueColor = 'text-gray-950 dark:text-gray-50',
  textLeft = false,
  loading = false,
  ...props
}) => {
  const defaultClassName = 'flex items-center gap-2 min-w-[9.5rem]';
  const className = classNames(defaultClassName, props.className, {
    'cursor-pointer': props.onClick,
    'flex-row-reverse justify-start': textLeft,
    'justify-center': !textLeft,
  });

  return (
    <div {...props} className={className}>
      {!loading ? (
        <Text type="blast" size="large" color={valueColor}>
          <Number value={value} />
        </Text>
      ) : (
        <Skeleton width={24} height={34} />
      )}

      {!loading ? (
        <Text
          type="heading"
          size="small"
          color={labelColor}
          className="whitespace-nowrap"
        >
          {label}
        </Text>
      ) : (
        <Skeleton width={96} height={10} />
      )}
    </div>
  );
};

export default LabelNumber;
