import React from 'react';
import { DialogContext } from '@blastradius/ui';

function useDialog(): React.ComponentProps<
  typeof DialogContext.Provider
>['value'] {
  const dialogContext = React.useContext(DialogContext);

  return dialogContext;
}

export default useDialog;
