import React from 'react';
import { Text, ToastContext, Icon, IconContained } from '@blastradius/ui';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import styles from './box-key-value.module.scss';
import { Icons } from '@blastradius/ui/icons';

type Props = {
  label: string;
  value:
    | React.ReactNode
    | ((
        textPartialProps: React.ComponentProps<typeof Text>,
      ) => React.ReactNode);
  copyable?: boolean;
  loading?: boolean;
  loadingContent?: boolean;
  copySingleLine?: boolean;
  tooltip?: React.ComponentProps<typeof Text>['tooltip'];
  copyToastProps?: React.ComponentProps<typeof Text>['copyToastProps'];
  copyableValue?: string;
  truncate?: number;
  leftIcon?: Icons;
  hidden?: boolean;
  wrapperClassName?: string;
  breakType?: 'all' | 'words';
};

const BoxKeyValue: React.FC<
  Props &
    (
      | React.HTMLAttributes<HTMLButtonElement>
      | React.HTMLAttributes<HTMLDivElement>
    )
> = ({
  label,
  value,
  copyable = false,
  loading = false,
  loadingContent = false,
  copyToastProps,
  tooltip,
  truncate = 1,
  leftIcon,
  hidden,
  wrapperClassName,
  breakType = 'words',
  ...props
}) => {
  const { notification } = React.useContext(ToastContext);

  const defaultClassName = classNames(
    {
      'flex items-center w-full': leftIcon,
      'group-deep text-left': copyable || props.onClick || hidden,
      [styles.boxKeyValue]: copyable || props.onClick || hidden,
    },
    wrapperClassName,
  );

  const valueClassName = classNames(
    {
      'cursor-pointer underline': props.onClick,
      'flex items-end gap-1': copyable || props.onClick || hidden,
      'justify-between': copyable || hidden,
      'max-w-[21rem]': leftIcon,
    },
    props.className,
  );

  const textPartialProps: Partial<React.ComponentProps<typeof Text>> = {
    type: 'body',
    size: 'small',
    as: 'div',
  };

  const [isContentCopied, setIsContentCopied] = React.useState(false);
  React.useEffect(() => {
    let checkIconDuration: ReturnType<typeof setTimeout>;

    if (isContentCopied) {
      checkIconDuration = setTimeout(() => {
        setIsContentCopied(false);
      }, 2000);
    }

    return () => {
      clearTimeout(checkIconDuration);
    };
  }, [isContentCopied]);

  function handleCopyContent() {
    if (!isContentCopied) {
      const valueToCopy = value as string;
      navigator.clipboard.writeText(valueToCopy);
      setIsContentCopied(true);
      notification({
        message: 'Text has been copied to clipboard',
        background: copyToastProps?.background,
        options: [],
      });
    }
  }

  const [isValueHide, setIsValueHide] = React.useState(hidden);
  function handleShowHideValue() {
    setIsValueHide((prevValue) => !prevValue);
  }

  return React.createElement(
    props.onClick ? 'button' : 'div',
    { ...props, className: defaultClassName },
    <>
      {leftIcon && (
        <IconContained
          icon={leftIcon}
          iconSize={20}
          className="flex-grow-0 flex-shrink-0 mr-4 self-start"
        />
      )}
      <div className="w-full">
        <Text
          type="label"
          color="text-gray-500"
          as="div"
          className="mb-1 select-none"
        >
          {!loading ? label : <Skeleton width={64} height={14} />}
        </Text>
        <Text
          {...(textPartialProps as React.ComponentProps<typeof Text>)}
          className={valueClassName}
          data-testid="element-value"
          tooltip={tooltip}
          /* if multiple items else unique item */
          render={({ tooltipId, tooltipStyle, ref }) =>
            !loading && !loadingContent ? (
              typeof value === 'function' ? (
                <span className="flex flex-col w-full">
                  {value(textPartialProps as React.ComponentProps<typeof Text>)}
                </span>
              ) : (
                <>
                  <div
                    className={classNames({
                      truncate: truncate === 1,
                      [`break-${breakType} line-clamp-${truncate}`]:
                        truncate > 1,
                    })}
                    data-tooltip-id={tooltipId}
                    data-tooltip-content={value}
                    ref={ref}
                    {...tooltipStyle}
                  >
                    {value ? (isValueHide ? '************' : value) : '-'}
                  </div>
                  {copyable && (
                    <button
                      aria-label="Copy content"
                      onClick={handleCopyContent}
                      className={classNames(
                        'basis-4 invisible group-deep-hover:visible -mt-3',
                      )}
                    >
                      <Icon
                        name={isContentCopied ? 'check' : 'copy'}
                        size={16}
                      />
                    </button>
                  )}
                  {hidden && (
                    <button
                      aria-label={`${isValueHide ? 'Show' : 'Hide'} content`}
                      onClick={handleShowHideValue}
                      className={classNames(
                        'basis-4 invisible group-deep-hover:visible',
                      )}
                    >
                      <Icon
                        name={isValueHide ? 'eye-open' : 'eye-close'}
                        size={16}
                      />
                    </button>
                  )}
                </>
              )
            ) : (
              <Skeleton width={68} height={14} />
            )
          }
        />
      </div>
    </>,
  );
};

export default BoxKeyValue;
