import classNames from 'classnames';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Icon from '../icon';
import IconBadge from '../icon-badge';

type Colors = 'gray' | 'green' | 'red';

type Props = {
  icon: React.ComponentProps<typeof Icon>['name'];
  iconSize?: React.ComponentProps<typeof Icon>['size'];
  size?: string;
  iconColor?: string;
  loading?: boolean;
  iconBadge?: React.ComponentProps<typeof Icon>['name'];
  color?: Colors;
};

const colorMapper: {
  [key in Colors]: { background: string; fill: string };
} = {
  gray: {
    background: 'bg-gray-50 dark:bg-gray-950',
    fill: 'fill-gray-950 dark:fill-white',
  },
  red: {
    background: 'bg-pink-500',
    fill: 'fill-white',
  },
  green: {
    background: 'bg-green-500',
    fill: 'fill-white',
  },
};

const IconContained: React.FC<Props & React.HTMLAttributes<HTMLElement>> = ({
  icon,
  iconSize = 32,
  size = 'w-9 h-9',
  iconColor,
  loading,
  iconBadge,
  color = 'gray',
  ...props
}) => {
  const defaultClassName = 'flex items-center justify-center rounded relative';
  const className = classNames(
    props.className,
    defaultClassName,
    size,
    colorMapper[color].background,
  );

  return (
    <div {...props} className={className}>
      {!loading ? (
        <>
          {iconBadge && (
            <IconBadge
              icon={iconBadge}
              color="red"
              className="absolute -top-1 -left-1 outline outline-2 outline-white dark:outline-gray-900"
            />
          )}
          <Icon
            name={icon}
            size={iconSize}
            className={iconColor || colorMapper[color].fill}
          />
        </>
      ) : (
        <Skeleton width={iconSize} height={iconSize} />
      )}
    </div>
  );
};

export default IconContained;
