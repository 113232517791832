import React from 'react';
import styles from './loader-spinner.module.scss';
import classNames from 'classnames';

export type CircleColors = 'gray' | 'red' | 'green' | 'white';

type Props = {
  size?: string;
  color?: CircleColors;
  circleBackground?: string;
};

const spinnerColorMapper: {
  [key in CircleColors]: string;
} = {
  gray: 'Gray',
  green: 'Green',
  red: 'Red',
  white: 'White',
};

const LoaderSpinner: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  size = 'w-5 h-5',
  color = 'gray',
  circleBackground = 'after:bg-gray-100 after:dark:bg-gray-900',
  ...props
}) => {
  const defaultClassName = classNames(
    'rounded-full relative animate-spin',
    [styles[`loaderSpinner${spinnerColorMapper[color]}`]],
    size,
    circleBackground,
    [spinnerColorMapper[color]],
    props.className,
  );

  return (
    <div
      {...props}
      className={defaultClassName}
      aria-label="Loader spinner running in circles"
    />
  );
};

export default LoaderSpinner;
