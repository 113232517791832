import classNames from 'classnames';
import React from 'react';
import Icon from '../icon/index';
import LoaderSpinner from '../loader-spinner';
import Text from '../text';

type Props = {
  as?: 'a' | 'button';
  disabled?: boolean;
  loading?: boolean;
  loadingCenter?: boolean;
  size?: 'x-small' | 'small' | 'regular';
  icon?: React.ComponentProps<typeof Icon>['name'];
  rightIcon?: React.ComponentProps<typeof Icon>['name'];
  iconSize?: React.ComponentProps<typeof Icon>['size'];
  rightIconSize?: React.ComponentProps<typeof Icon>['size'];
  transparent?: boolean;
  selected?: boolean;
  iconClassName?: string | undefined;
  childrenClassName?: string | undefined;
};

const ButtonGhost = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  Props &
    React.AnchorHTMLAttributes<HTMLAnchorElement> &
    React.ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      as = 'button',
      disabled,
      loading,
      loadingCenter,
      size = 'regular',
      icon,
      iconSize = 24,
      rightIconSize = 24,
      rightIcon,
      transparent = false,
      selected = false,
      children,
      ...props
    },
    ref,
  ) => {
    const defaultClassName = `inline-flex justify-center items-center px-6 rounded
      transition-color duration-200 ease-in-out cursor-pointer relative`;
    const className = classNames(defaultClassName, props.className, {
      'pointer-events-none opacity-50': disabled || loading || loadingCenter,
      'py-5': size === 'regular',
      'h-12': size === 'small',
      'h-9 px-3': size === 'x-small',
      [`border border-solid border-gray-200 dark:border-gray-700
      hover:border-gray-400 hover:dark:border-gray-500`]: !transparent,
      'px-0': transparent,
      '!border-gray-950 dark:!border-white': selected,
    });

    return React.createElement(
      as,
      {
        type: as === 'button' ? 'button' : undefined,
        ...props,
        className,
        ref,
      },
      <>
        {icon &&
          (!loading ? (
            <Icon
              name={icon}
              size={iconSize}
              className={classNames(
                props.iconClassName,
                'mr-2 fill-gray-950 dark:fill-white pointer-events-none shrink-0',
                {
                  'fill-gray-300 dark:fill-gray-700': disabled,
                },
              )}
            />
          ) : (
            <LoaderSpinner className="mr-2" size="w-4 h-4" />
          ))}

        {loading && !icon && (
          <div className="absolute -right-7">
            <LoaderSpinner size="w-4 h-4" />
          </div>
        )}

        <Text
          type="body"
          size="small"
          className={classNames(
            props.childrenClassName,
            'flex items-center pointer-events-none',
          )}
        >
          {loadingCenter ? <LoaderSpinner size="w-3.5 h-3.5" /> : children}
        </Text>
        {rightIcon ? (
          <Icon
            name={rightIcon}
            size={rightIconSize}
            className={classNames(
              'fill-gray-500 dark:fill-gray-500 pointer-events-none ml-2',
              {
                'fill-gray-300 dark:fill-gray-700': disabled,
              },
            )}
          />
        ) : null}
      </>,
    );
  },
);

export default ButtonGhost;
