import React from 'react';
import { Icon, Text } from '@blastradius/ui';
import Container from '@customer-web-app/domains/shared/components/container';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Skeleton from 'react-loading-skeleton';

type Screen = {
  title: string;
  pathname: string;
  asPath: string;
  isLoading?: boolean;
};

type PushScreenOptionals = {
  resetHistory?: boolean;
};

interface IBreadcrumbsContext {
  pushScreen: (
    screen: Partial<Screen>,
    optionals?: PushScreenOptionals,
  ) => void;
}

export const BreadcrumbsContext = React.createContext<IBreadcrumbsContext>({
  pushScreen: () => void 0,
});

/*
  This array resets the breadcrumb, it means the pages here
  should not have a breadcrumb rendered in the screen.
  --
  Make sure you use the same structure of the folder creation
  i.e.: don't use the final paths but the dynamic ones
*/
export const pagesToReset = ['/incidents/[status]'];

const BreadcrumbsProvider: React.FC<
  React.HTMLAttributes<HTMLHeadingElement>
> = ({ children, ...props }) => {
  const { asPath, pathname } = useRouter();
  const [history, setHistory] = React.useState<Screen[]>([]);
  const [render, setRender] = React.useState(false);

  React.useEffect(() => {
    if (pagesToReset.includes(pathname)) {
      setHistory([]);
      setRender(false);
    }
  }, [pathname]);

  function pushScreen(
    screen: Partial<Screen>,
    { resetHistory }: PushScreenOptionals = {},
  ) {
    setRender(true);

    if (resetHistory) {
      const nextScreen = {
        pathname,
        asPath,
        ...screen,
      } as Screen;
      setHistory([nextScreen]);
      return;
    }

    const lastScreen = history[history.length - 1];

    if (!lastScreen?.isLoading && pathname === lastScreen?.pathname) {
      return;
    }

    const existsScreenIndex = history.findIndex(
      (x) => !x.isLoading && x.pathname === pathname,
    );
    if (existsScreenIndex > -1) {
      setHistory(history.slice(0, existsScreenIndex + 1));
      return;
    }

    const previousScreens = lastScreen?.isLoading
      ? history.filter((x) => !x.isLoading)
      : history;

    const nextScreen = {
      pathname,
      asPath,
      ...screen,
    } as Screen;

    setHistory([...previousScreens, nextScreen]);
  }

  return (
    <BreadcrumbsContext.Provider
      value={{
        pushScreen,
      }}
    >
      {render && (
        <div className="w-full h-12 sticky top-0 bg-gray-50 dark:bg-gray-950 z-20 lg:block inline-table">
          <Container role="navigation" as="nav" {...props}>
            <div className="flex h-12 gap-4 items-center ml-1 2.5xl:ml-20 4xl:mx-auto 4xl:grow 4xl:max-w-[141rem]">
              <Link href="/">
                <Icon
                  name="home"
                  className="fill-gray-400 hover:fill-gray-600 dark:fill-gray-500 hover:dark:fill-gray-400 transition-all duration-200 ease-in-out cursor-pointer"
                  size={16}
                />
              </Link>
              <Icon name="carrot-right" size={8} />
              {history.map(({ title, asPath, isLoading }, index) =>
                index < history.length - 1 ? (
                  <React.Fragment key={`${index}_${asPath}`}>
                    <Link href={asPath} passHref legacyBehavior>
                      <Text
                        type="body"
                        size="x-small"
                        as="a"
                        color="text-gray-600 hover:text-gray-800 dark:text-gray-500 hover:dark:text-gray-300 transition-all duration-200 ease-in-out cursor-pointer"
                        onClick={() => pushScreen({ asPath })}
                      >
                        {title}
                      </Text>
                    </Link>
                    <Icon name="carrot-right" size={8} />
                  </React.Fragment>
                ) : (
                  <Text
                    key={asPath}
                    type="body"
                    size="x-small"
                    as="span"
                    color="text-gray-600 dark:text-gray-500"
                  >
                    {!isLoading ? (
                      title
                    ) : (
                      <Skeleton width={40} height={12} className="mt-1.5" />
                    )}
                  </Text>
                ),
              )}
            </div>
          </Container>
        </div>
      )}
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export default BreadcrumbsProvider;
