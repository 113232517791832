import React from 'react';
import classNames from 'classnames';
import Icon from '../../base-components/icon';
import MiddleTruncation from '../../base-components/middle-truncation';
import Text from '../../base-components/text';
import { UploadedFile } from '../upload-files';

type Props = {
  file: UploadedFile;
  showHash?: boolean;
  removeFile: (fileIndex: number) => void;
  fileIndex: number;
};

function UploadFilesListItem({
  file,
  showHash,
  removeFile,
  fileIndex,
  ...props
}: Props & React.HTMLAttributes<HTMLDivElement>) {
  const fileCardRef = React.useRef(null);

  const defaultClassName = `flex items-center justify-between shrink-0 pl-6 pr-5 py-4
    rounded bg-gray-50 dark:bg-white/[.06] relative group`;

  const className = classNames(defaultClassName, props.className, {
    'border-l border-l-2 border-pink-600 dark:border-pink-500 bg-pink-500/[0.04] dark:bg-pink-900/[0.4]':
      file.error,
  });

  return (
    <div
      {...props}
      className={className}
      ref={fileCardRef}
      data-testid="file-upload-card"
    >
      <div className="flex flex-col gap-1 w-10/12 2xl:w-11/12">
        <div className="flex items-center gap-2">
          <Text type="body" size="regular" className="2xl:hidden">
            <MiddleTruncation maxSize={15} value={file?.fileInstance?.name} />
          </Text>
          <Text type="body" size="regular" className="hidden 2xl:block">
            <MiddleTruncation maxSize={35} value={file?.fileInstance?.name} />
          </Text>
        </div>

        {file.error && (
          <Text type="body" size="small" className="opacity-50">
            {file.error}
          </Text>
        )}
      </div>

      {!file.error ? (
        <>
          <Icon
            name="circle-check"
            size={24}
            className={`absolute right-[1.1rem] group-hover:opacity-0
            group-hover:invisible transition-fade ease-in-out duration-75`}
          />
          <button
            className={`absolute right-[1.1rem] invisible opacity-0
          group-hover:opacity-100 group-hover:visible
          transition-fade ease-in-out duration-75`}
            type="button"
            onClick={() => removeFile(fileIndex)}
            aria-label="Click to remove file from the list"
          >
            <Icon
              name="circle-close"
              size={24}
              className={`hover:fill-black dark:hover:fill-white
              transition-colors ease-in-out duration-200`}
            />
          </button>
        </>
      ) : (
        <button
          onClick={() => removeFile(fileIndex)}
          aria-label="Click to remove file from the list"
        >
          <Icon
            name="circle-close"
            size={24}
            className="fill-pink-600 dark:fill-pink-500"
          />
        </button>
      )}
    </div>
  );
}

export default UploadFilesListItem;
