import React from 'react';
import classNames from 'classnames';
import { marked } from 'marked';
import mermaid from 'mermaid';
import { svgCopy, svgCheck } from './icons';
import { ToastContext } from '@blastradius/ui';
import { useRouter } from 'next/router';
import { format } from 'date-fns';
import unescape from 'lodash/unescape';
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import powershell from 'highlight.js/lib/languages/powershell';
import xml from 'highlight.js/lib/languages/xml';
import vbScript from 'highlight.js/lib/languages/vbscript';
import bash from 'highlight.js/lib/languages/bash';
import shell from 'highlight.js/lib/languages/shell';
import python from 'highlight.js/lib/languages/python';
import plaintext from 'highlight.js/lib/languages/plaintext';
import csharp from 'highlight.js/lib/languages/csharp';
import php from 'highlight.js/lib/languages/php';
import java from 'highlight.js/lib/languages/java';
import md from 'highlight.js/lib/languages/markdown';
import c from 'highlight.js/lib/languages/c';
import css from 'highlight.js/lib/languages/css';
import json from 'highlight.js/lib/languages/json';
import go from 'highlight.js/lib/languages/go';
import ruby from 'highlight.js/lib/languages/ruby';
import rust from 'highlight.js/lib/languages/rust';
import scss from 'highlight.js/lib/languages/scss';
import sql from 'highlight.js/lib/languages/sql';
import swift from 'highlight.js/lib/languages/swift';
import yaml from 'highlight.js/lib/languages/yaml';
import typescript from 'highlight.js/lib/languages/typescript';

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('powershell', powershell);
hljs.registerLanguage('html', xml);
hljs.registerLanguage('xml', xml);
hljs.registerLanguage('vbScript', vbScript);
hljs.registerLanguage('bash', bash);
hljs.registerLanguage('shell', shell);
hljs.registerLanguage('python', python);
hljs.registerLanguage('plaintext', plaintext);
hljs.registerLanguage('csharp', csharp);
hljs.registerLanguage('php', php);
hljs.registerLanguage('java', java);
hljs.registerLanguage('md', md);
hljs.registerLanguage('c', c);
hljs.registerLanguage('css', css);
hljs.registerLanguage('json', json);
hljs.registerLanguage('go', go);
hljs.registerLanguage('ruby', ruby);
hljs.registerLanguage('rust', rust);
hljs.registerLanguage('scss', scss);
hljs.registerLanguage('sql', sql);
hljs.registerLanguage('swift', swift);
hljs.registerLanguage('yaml', yaml);
hljs.registerLanguage('typescript', typescript);

type Props = {
  value: string;
  router?: ReturnType<typeof useRouter>;
  removeLinks?: boolean;
};

const Markdown: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  value,
  router,
  removeLinks,
  ...props
}) => {
  const [markdown, setMarkdown] = React.useState<string>(value);
  const className = classNames(props.className, 'markdown');
  const { notification } = React.useContext(ToastContext);
  const [readyToRender, setReadyToRender] = React.useState(false);

  React.useEffect(() => {
    if (removeLinks) {
      marked.use({
        renderer: {
          link(_href, _title, text) {
            return `${text}`;
          },
        },
      });
    }

    if (!value) setMarkdown('-');
    else {
      const result = marked.parse(value);
      if (result instanceof Promise) result.then(setMarkdown);
      else setMarkdown(result);
    }
    setReadyToRender(true);
  }, [value, removeLinks]);

  React.useEffect(() => {
    mermaid.initialize({
      startOnLoad: false,
    });
  }, []);

  const copyButtonElements: {
    element: HTMLElement;
    handlerFunction: () => void;
  }[] = [];

  const hrefButtonElements: {
    element: Element;
    handlerFunction: () => void;
  }[] = [];

  React.useEffect(() => {
    const [matcherOpen, matcherClose] = ['<span data-br-date="">', '</span>'];

    document
      .querySelectorAll(
        'pre code:not(.language-mermaid,#error-stack,.language-undefined)',
      )
      .forEach(async (el) => {
        hljs.highlightElement(el as HTMLElement);
      });

    document
      .querySelectorAll('pre code.language-plaintext, pre code .hljs-string')
      .forEach((el) => {
        // Normalize the tags
        el.innerHTML = unescape((el as HTMLElement).innerHTML);

        let content = unescape((el as HTMLElement).innerHTML);
        let dateFormatTriggerIndex = content.indexOf(matcherOpen);

        // Replaces the matcher by the final content until not have more
        while (dateFormatTriggerIndex > 0) {
          const startIndex = dateFormatTriggerIndex;
          const endIndex = content.indexOf(matcherClose);
          const value = content.substring(
            startIndex + matcherOpen.length,
            endIndex,
          );
          try {
            const dateFormatted = format(new Date(value), 'MM/dd/yyyy');

            el.innerHTML = content.replace(
              `${matcherOpen}${value}${matcherClose}`,
              dateFormatted,
            );
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error('BlastRadius UI: Invalid date on code block', e);
            break;
          }

          // Get next element if exists
          content = unescape((el as HTMLElement).innerHTML);
          dateFormatTriggerIndex = content.indexOf(matcherOpen);
        }
      });

    document
      .querySelectorAll('pre code:not(.language-mermaid)')
      .forEach((el) => {
        // Creating copy button for each code block
        const copyButton = document.createElement('button');
        copyButton.className = 'copy';
        copyButton.ariaLabel = 'Copy code block content';
        copyButton.type = 'button';
        copyButton.innerHTML = svgCopy;

        async function onCopyButtonClick() {
          await navigator.clipboard.writeText(el.textContent as string);
          copyButton.blur();
          copyButton.innerHTML = svgCheck;
          setTimeout(() => (copyButton.innerHTML = svgCopy), 3000);
          notification({
            message: 'Text has been copied to clipboard',
            options: [],
          });
        }

        copyButton.addEventListener('click', onCopyButtonClick);
        el.appendChild(copyButton);

        copyButtonElements.push({
          element: copyButton,
          handlerFunction: onCopyButtonClick,
        });
      });

    document.querySelectorAll('a[data-br-href]').forEach((el) => {
      async function onClick() {
        router?.push(el.getAttribute('data-br-href') as string);
      }

      el.addEventListener('click', onClick);

      hrefButtonElements.push({
        element: el,
        handlerFunction: onClick,
      });
    });

    document.querySelectorAll('[data-br-date]').forEach((el) => {
      if (el.getAttribute('data-br-date-formatted') === null) {
        const dateToFormat = new Date(el.innerHTML);
        if (isNaN(dateToFormat.getTime())) {
          return;
        }
        el.innerHTML = format(dateToFormat, 'MM/dd/yyyy h:mmaaa');
        el.setAttribute('data-br-date-formatted', '');
      }
    });

    mermaid.init({}, '.language-mermaid');

    return () => {
      [...copyButtonElements, ...hrefButtonElements].forEach(
        ({ element, handlerFunction }) => {
          element.removeEventListener('click', handlerFunction);
        },
      );
    };
  }, [markdown]);

  return (
    <>
      {readyToRender && (
        <div
          {...props}
          className={className}
          dangerouslySetInnerHTML={{ __html: markdown }}
        />
      )}
    </>
  );
};

export default Markdown;
