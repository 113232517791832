import React from 'react';
import classNames from 'classnames';
import Text from '../text';
import InputLabel from '../input-label';
import { FieldValues, UseFormReturn } from 'react-hook-form';

type InputRadioProps = {
  label?: string;
  onChangeValue?: (selected: string) => void;
  loading?: boolean;
  locked?: boolean;
  disabled?: boolean;
  value?: string | readonly string[] | number | undefined;
  name?: string;
  form?: UseFormReturn<any, FieldValues>;
};

type InputRadioOptionProps = {
  label: string;
  disabled?: boolean;
  active?: boolean;
  setSelected?: (value: string) => void;
  loading?: boolean;
  inputRadioID?: string;
};

type InputRadioOptionType = React.FC<
  InputRadioOptionProps & React.InputHTMLAttributes<HTMLInputElement>
>;

type SubComponents = {
  Option: InputRadioOptionType;
};

const InputRadio: React.FC<
  InputRadioProps & React.HTMLAttributes<HTMLDivElement>
> &
  React.PropsWithChildren &
  SubComponents = ({
  id,
  className,
  onChangeValue,
  children,
  label,
  loading = false,
  locked,
  disabled,
  value,
  ...props
}) => {
  const wrapperClassName = classNames(
    'flex overflow-hidden gap-2 group',
    {
      'pointer-events-none': locked,
      'pointer-events-none opacity-50': disabled,
    },
    className,
  );

  const radioOptions = React.Children.map(
    children as React.ReactElement[],
    (child: React.ReactElement) => {
      return React.cloneElement(child, {
        ...child.props,
        active: child.props.value === value,
        setSelected: onChangeValue,
        loading,
        'aria-disabled': disabled || locked,
        inputRadioID: id,
      });
    },
  );

  return (
    <div className={wrapperClassName}>
      {label && (
        <InputLabel
          labelColor="text-gray-500 group-hover:text-gray-950 dark:group-hover:text-white transition-all ease-in-out duration-200 cursor-default"
          htmlFor={id}
        >
          {label}
        </InputLabel>
      )}
      <div {...props} className="flex overflow-hidden" role="radiogroup">
        {radioOptions}
      </div>
    </div>
  );
};

const Option = React.forwardRef<
  HTMLInputElement,
  InputRadioOptionProps & React.InputHTMLAttributes<HTMLInputElement>
>(
  (
    {
      label,
      setSelected,
      active = false,
      disabled,
      loading = false,
      inputRadioID,
      ...props
    },
    ref,
  ) => {
    const defaultClassName = `flex items-center justify-center py-2 w-18 border border-solid relative first:rounded-l last:rounded-r
  group-deep transition-bg ease-in-out duration-200`;

    const className = classNames(defaultClassName, {
      'bg-white dark:bg-gray-900 border-gray-950/20 dark:border-gray-700 hover:bg-gray-950/[.04] hover:dark:bg-white/[.12]':
        !active,
      'border-gray-900 dark:border-gray-100 bg-gray-900 dark:bg-gray-100':
        active && !disabled && !loading,
      'pointer-events-none opacity-60 dark:opacity-25': disabled,
      'pointer-events-none': loading,
    });

    return (
      <div
        className={className}
        onClick={() => {
          if (setSelected) {
            setSelected(props.value as string);
          }
        }}
        data-testid={`${props.value}_input_radio_option`}
      >
        <input
          {...props}
          value={props.value}
          checked={active}
          type="radio"
          ref={ref}
          data-testid={`input_radio_option_${props.value}`}
          aria-checked={active}
          id={inputRadioID}
          readOnly
          className="opacity-0 top-0 left-0 absolute w-full h-full cursor-pointer"
        />

        <div className="flex flex-col gap-0.5">
          <Text
            type="body"
            size="small"
            className="transition-colors ease-in-out duration-200"
            color={classNames({
              'text-gray-950 dark:text-white': !active,
              'text-white dark:text-gray-900': active,
            })}
          >
            {label}
          </Text>
        </div>
      </div>
    );
  },
);
InputRadio.Option = Option;

export default InputRadio;
