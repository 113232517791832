import React from 'react';
import { FieldValues, RefCallBack, UseFormReturn } from 'react-hook-form';
import { ReactDatePickerProps } from 'react-datepicker';
import {
  subDays,
  subYears,
  format,
  startOfDay,
  addDays,
  subMinutes,
  subHours,
  isSameMinute,
} from 'date-fns';
import classNames from 'classnames';
import {
  DatePicker,
  InputCheckbox,
  Text,
  InputText,
  ButtonTransparent,
  InputTextProps,
  InputTime,
} from '@blastradius/ui';

type TimeFrameOptions =
  | 'Last 15 minutes'
  | 'Last 30 minutes'
  | 'Last 4 hours'
  | 'Today'
  | 'Last 7 Days'
  | 'Last 30 Days'
  | 'Last 90 Days'
  | 'Last Year';

const timeFrameMapper: Record<TimeFrameOptions, (date: Date) => Date> = {
  'Last 15 minutes': (currentDate: Date) => subMinutes(currentDate, 15),
  'Last 30 minutes': (currentDate: Date) => subMinutes(currentDate, 30),
  'Last 4 hours': (currentDate: Date) => subHours(currentDate, 4),
  Today: (currentDate: Date) => currentDate,
  'Last 7 Days': (currentDate: Date) => subDays(currentDate, 7),
  'Last 30 Days': (currentDate: Date) => subDays(currentDate, 30),
  'Last 90 Days': (currentDate: Date) => subDays(currentDate, 90),
  'Last Year': (currentDate: Date) => subYears(currentDate, 1),
};

enum PeriodOptions {
  AM,
  PM,
}

const getUserUTC = () => {
  const utc = new Date().getTimezoneOffset() / 60;
  return utc > 0 ? `UTC -${utc}` : `UTC +${utc}`;
};

function combineDateAndTime(
  date: Date,
  time: { hour: string; period: PeriodOptions },
) {
  const [hours, minutes] = time.hour.split(':');
  const adjustedHours =
    time.period === PeriodOptions.PM && hours !== '12' ? +hours + 12 : +hours;
  const finalDate = new Date(date);

  finalDate.setHours(adjustedHours, +minutes);
  return finalDate;
}

function getTimeFrameRange(dateRange: Date[]) {
  if (dateRange?.some((date) => !date)) {
    return null;
  }

  const [startDate, endDate] = dateRange;
  const currentDate = new Date();

  for (const frame of Object.keys(timeFrameMapper) as TimeFrameOptions[]) {
    const frameStartDate = timeFrameMapper[frame](currentDate);

    if (
      isSameMinute(frameStartDate, startDate) &&
      isSameMinute(currentDate, endDate)
    ) {
      return frame;
    }
  }

  return null;
}

type Props = {
  startDateName: string;
  endDateName: string;
  initialStartDate?: Date | string;
  initialEndDate?: Date | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, FieldValues>;
  initialTimeFrame?: TimeFrameOptions;
  inputClassName?: string;
  withError?: boolean;
  leftOffset?: string;
  inputTextClassName?: string;
};

type DatePickerHoursMinutesProps = Partial<ReactDatePickerProps> & Props;

const DatePickerHoursMinutesInput = React.forwardRef<
  RefCallBack,
  InputTextProps & {
    setIsOpen: (state: boolean) => void;
    dateRange: Date[];
    isOpen: boolean;
    inputClassName?: string;
    inputTextClassName?: string;
  } & React.InputHTMLAttributes<HTMLInputElement>
>(
  (
    {
      value,
      onClick,
      setIsOpen,
      dateRange,
      isOpen,
      inputClassName,
      inputTextClassName,
      ...props
    },
    ref,
  ) => {
    return (
      <InputText
        {...props}
        value={getTimeFrameRange(dateRange) || value}
        iconRight="carrot-down"
        onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
          setIsOpen(true);
          onClick?.(e);
        }}
        iconClassName={classNames({
          'rotate-180': isOpen,
        })}
        ref={ref}
        className={inputClassName}
        inputClassName={inputTextClassName}
      />
    );
  },
);

DatePickerHoursMinutesInput.displayName = 'DatePickerHoursMinutesInput';

interface PeriodToggleProps {
  period: PeriodOptions;
  selectedPeriod: PeriodOptions;
  setSelectedPeriod: (period: PeriodOptions) => void;
  label: string;
  roundedSide?: 'left' | 'right';
}

const PeriodToggle: React.FC<PeriodToggleProps> = ({
  period,
  selectedPeriod,
  setSelectedPeriod,
  label,
  roundedSide,
}) => {
  const isActive = period === selectedPeriod;
  const roundedClass = roundedSide === 'left' ? 'rounded-l' : 'rounded-r';

  return (
    <div
      className={classNames(
        'w-10 h-8 flex items-center justify-center cursor-pointer border-[1px] border-black',
        roundedClass,
        isActive ? 'bg-black text-white' : 'bg-white text-black',
      )}
      onClick={() => setSelectedPeriod(period)}
    >
      <Text type="body" size="small" color="inherit">
        {label}
      </Text>
    </div>
  );
};

const DatePickerHoursMinutes = ({
  startDateName,
  endDateName,
  initialStartDate,
  initialEndDate,
  form,
  initialTimeFrame,
  leftOffset = '-64px',
  inputClassName,
  inputTextClassName,
  withError,
  ...props
}: DatePickerHoursMinutesProps) => {
  const { setValue } = form;

  const [currentDate, setCurrentDate] = React.useState(new Date());
  const [timeFrame, setTimeFrame] = React.useState<
    TimeFrameOptions | undefined
  >();
  const [dateRange, setDateRange] = React.useState<Date[]>([]);
  const [startDate, endDate] = dateRange;
  const [startHour, setStartHour] = React.useState({
    hour: '00:00',
    period: PeriodOptions.AM,
  });
  const [endHour, setEndHour] = React.useState({
    hour: '00:00',
    period: PeriodOptions.AM,
  });
  const [focusedInputId, setFocusedInputId] = React.useState<string>('');

  const [isAllDay, setIsAllDay] = React.useState(true);

  const [isOpen, setIsOpen] = React.useState(false);

  function handleClickStartInput() {
    setIsOpen(true);
  }

  const getTimeAsDate = ({
    hour,
    period,
  }: {
    hour: string;
    period: PeriodOptions;
  }) => {
    const [hours, minutes] = hour.split(':').map(Number);
    const date = new Date();
    date.setHours(
      period === PeriodOptions.PM && hours !== 12 ? hours + 12 : hours,
      minutes,
      0,
      0,
    );
    return date;
  };

  React.useEffect(() => {
    const startTime = getTimeAsDate(startHour);
    const endTime = getTimeAsDate(endHour);

    if (endTime < startTime) {
      setEndHour(startHour);
    }
  }, [startHour, endHour]);

  const timeFrameOptions: TimeFrameOptions[] = [
    'Last 15 minutes',
    'Last 30 minutes',
    'Last 4 hours',
    'Today',
    'Last 7 Days',
    'Last 30 Days',
    'Last 90 Days',
    'Last Year',
  ];

  function closeDatePicker(e: MouseEvent) {
    const hasDatePickerInComposition = e
      .composedPath()
      .some((path) =>
        (path as HTMLElement)
          .getAttribute?.('class')
          ?.startsWith('react-datepicker'),
      );

    if (!hasDatePickerInComposition) {
      setIsOpen(false);
    }
  }

  React.useEffect(() => {
    document.addEventListener('click', closeDatePicker);

    return () => {
      document.removeEventListener('click', closeDatePicker);
    };
  }, []);

  React.useEffect(() => {
    if (dateRange.length === 0) {
      return;
    }
    if (dateRange?.every((date) => date)) {
      if (isAllDay) {
        if (
          ['Last 15 minutes', 'Last 30 minutes', 'Last 4 hours'].includes(
            timeFrame as TimeFrameOptions,
          )
        ) {
          setValue(startDateName, format(dateRange[0], 'yyyy-MM-dd HH:mm'));
          setValue(endDateName, format(dateRange[1], 'yyyy-MM-dd HH:mm'));
          return;
        }
        setValue(
          startDateName,
          format(startOfDay(dateRange[0]), 'yyyy-MM-dd HH:mm'),
        );
        if (dateRange[1] < currentDate) {
          const addedDay = addDays(dateRange[1], 1);
          setValue(
            endDateName,
            format(startOfDay(addedDay), 'yyyy-MM-dd HH:mm'),
          );
        } else {
          setValue(endDateName, format(dateRange[1], 'yyyy-MM-dd HH:mm'));
        }
      } else {
        const startDateWithTime = combineDateAndTime(dateRange[0], startHour);
        const endDateWithTime = combineDateAndTime(dateRange[1], endHour);

        setValue(startDateName, format(startDateWithTime, 'yyyy-MM-dd HH:mm'));
        setValue(endDateName, format(endDateWithTime, 'yyyy-MM-dd HH:mm'));
      }
    }
  }, [dateRange, startHour, endHour, isAllDay]);

  React.useEffect(() => {
    if (timeFrameOptions.includes(timeFrame as TimeFrameOptions)) {
      setDateRange([
        timeFrameMapper[timeFrame as TimeFrameOptions](currentDate),
        new Date(),
      ]);
    }
  }, [timeFrame]);

  React.useEffect(() => {
    if (timeFrameOptions.includes(initialTimeFrame as TimeFrameOptions)) {
      setDateRange([
        timeFrameMapper[initialTimeFrame as TimeFrameOptions](currentDate),
        new Date(),
      ]);
    }
  }, []);

  //Update current date every 1 minute
  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <DatePicker
      {...props}
      selectsRange
      open={isOpen}
      dateFormat={'MM/dd/yyyy'}
      startDate={startDate}
      endDate={endDate}
      onChange={(dates) => {
        setDateRange(dates as unknown as Date[]);
      }}
      customInput={
        <DatePickerHoursMinutesInput
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onClick={handleClickStartInput}
          dateRange={dateRange}
          inputClassName={inputClassName}
          inputTextClassName={inputTextClassName}
        />
      }
      shouldCloseOnSelect
      maxDate={currentDate}
      openToDate={endDate || new Date()}
      monthsShown={2}
      className="absolute"
      calendarContainer={({ children }) => {
        return (
          <div
            className="react-datepicker__calendar-container"
            style={{
              position: 'absolute',
              top: '100%',
              left: leftOffset,
            }}
          >
            {children as React.ReactNode}
          </div>
        );
      }}
    >
      <div className="flex flex-col gap-2 !h-[420px] bg-white dark:bg-gray-900">
        {timeFrameOptions.map((timeFrameOption) => (
          <ButtonTransparent
            key={`${timeFrameOption}-date-picker`}
            className={classNames('pl-2 h-10', {
              'bg-gray-800/[.06] dark:bg-white/[.06]':
                getTimeFrameRange(dateRange) === timeFrameOption,
            })}
            onClick={() => {
              setTimeFrame(timeFrameOption);
              setIsOpen(false);
            }}
          >
            {timeFrameOption}
          </ButtonTransparent>
        ))}
      </div>
      <div className="bg-white dark:bg-gray-900 mt-10 w-[555px] absolute left-28 rounded-sm -bottom-[8.5rem]">
        <div className="grid grid-cols-1 pl-2">
          <div className="space-y-4 border-t border-t-gray-200 dark:border-t-gray-800 border-l border-l-gray-200 dark:border-l-gray-800">
            <div className="flex items-center justify-between w-full h-18 py-4 px-6 border-b border-b-gray-200 dark:border-b-gray-700">
              <InputCheckbox
                label="All day"
                withHover={false}
                onChange={() => {
                  setIsAllDay(!isAllDay);
                  setStartHour({
                    hour: '00:00',
                    period: PeriodOptions.AM,
                  });
                  setEndHour({
                    hour: '11:59',
                    period: PeriodOptions.PM,
                  });
                }}
                checked={isAllDay}
                role="tree"
                id="all-day"
              />
              <Text
                as="label"
                type="body"
                size="small"
                htmlFor="all-day"
                data-testid="input-checkbox-label"
                color="text-gray-950 dark:text-white"
                className="w-16"
              >
                {getUserUTC()}
              </Text>
            </div>

            <div
              className={classNames(
                'w-full px-6 pb-4 flex items-center justify-between',
                {
                  'disabled opacity-50': isAllDay,
                },
              )}
            >
              <div className="flex items-center justify-between">
                <div>
                  <Text
                    type="body"
                    as="label"
                    className="mb-1 mr-6"
                    size="small"
                    color="text-gray-500"
                  >
                    From
                  </Text>
                </div>
                <div className="flex items-center space-x-2">
                  <InputTime
                    inputClassName="w-[3.375rem] h-8 !pl-2"
                    withButtons={false}
                    initTime={startHour.hour}
                    id="start-hour"
                    focusedInputId={focusedInputId}
                    onFocus={() => setFocusedInputId('start-hour')}
                    onBlur={() => setFocusedInputId('')}
                    onTimeChange={(hour) => {
                      if (hour !== startHour.hour) {
                        setStartHour((prev) => ({
                          ...prev,
                          hour,
                        }));
                      }
                    }}
                  />
                  <div className="flex items-center">
                    <PeriodToggle
                      period={PeriodOptions.AM}
                      selectedPeriod={startHour.period}
                      setSelectedPeriod={(period) =>
                        setStartHour({ ...startHour, period })
                      }
                      label="AM"
                      roundedSide="left"
                    />
                    <PeriodToggle
                      period={PeriodOptions.PM}
                      selectedPeriod={startHour.period}
                      setSelectedPeriod={(period) =>
                        setStartHour({ ...startHour, period })
                      }
                      label="PM"
                      roundedSide="right"
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div>
                  <Text
                    type="body"
                    as="label"
                    className="mb-1 mr-6"
                    size="small"
                    color="text-gray-500"
                  >
                    To
                  </Text>
                </div>
                <div className="flex items-center space-x-2">
                  <InputTime
                    inputClassName="w-[3.375rem] h-8 !pl-2"
                    withButtons={false}
                    initTime={endHour.hour}
                    id="end-hour"
                    focusedInputId={focusedInputId}
                    onFocus={() => setFocusedInputId('end-hour')}
                    onBlur={() => setFocusedInputId('')}
                    onTimeChange={(hour) => {
                      if (hour !== endHour.hour) {
                        setEndHour((prev) => ({
                          ...prev,
                          hour,
                        }));
                      }
                    }}
                  />

                  <div className="flex items-center">
                    <PeriodToggle
                      period={PeriodOptions.AM}
                      selectedPeriod={endHour.period}
                      setSelectedPeriod={(period) =>
                        setEndHour({ ...endHour, period })
                      }
                      label="AM"
                      roundedSide="left"
                    />
                    <PeriodToggle
                      period={PeriodOptions.PM}
                      selectedPeriod={endHour.period}
                      setSelectedPeriod={(period) =>
                        setEndHour({ ...endHour, period })
                      }
                      label="PM"
                      roundedSide="right"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DatePicker>
  );
};

export default DatePickerHoursMinutes;
