import React from 'react';
import { loadSpace } from '@usersnap/browser';
import useFeatureFlags from '@customer-web-app/domains/shared/hooks/use-feature-flags';
import useAuthentication from '@customer-web-app/domains/authentication/hooks/use-authentication';

const UsersnapProvider = () => {
  const { generalEnableUsersnap } = useFeatureFlags();

  const { user } = useAuthentication();

  React.useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' &&
      generalEnableUsersnap
    ) {
      loadSpace(process.env.NEXT_PUBLIC_USERSNAP_API_KEY).then((api) => {
        api.init({
          user: {
            userId: user?.id,
            email: user?.email,
          },
          custom: {
            tenantId: user?.tenantId,
            tenantName: user?.tenant?.name,
          },
        });
      });
    }
  }, []);

  return null;
};

export default UsersnapProvider;
