import classNames from 'classnames';
import React from 'react';

function StickyHeaderWrapper({
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  const defaultClassName = 'sticky top-12 z-20 lg:block inline-block';
  const className = classNames(defaultClassName, props.className);

  return (
    <div {...props} className={className}>
      {children}
    </div>
  );
}

export default StickyHeaderWrapper;
