import { Icons } from '@blastradius/ui/icons';

export const alertResolutionMapper: {
  [key in string]: {
    alertResolutionIcon: Icons;
    alertResolutionCopy: string;
    alertHasGradient: boolean;
    alertResolutionColor: string;
    alertBorderColor: 'red' | 'green' | 'gray';
  };
} = {
  ['true']: {
    alertResolutionIcon: 'caution',
    alertResolutionCopy: 'Escalated',
    alertHasGradient: true,
    alertResolutionColor: 'from-red-500 to-pink-500',
    alertBorderColor: 'red',
  },
  ['false']: {
    alertResolutionIcon: 'caution-green',
    alertResolutionCopy: 'Benign',
    alertHasGradient: false,
    alertResolutionColor: 'text-green-500',
    alertBorderColor: 'green',
  },
  ['undefined']: {
    alertResolutionIcon: 'alert',
    alertResolutionCopy: '',
    alertHasGradient: false,
    alertResolutionColor: 'text-gray-500',
    alertBorderColor: 'gray',
  },
};
