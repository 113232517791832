import React from 'react';
import { Text } from '@blastradius/ui';
import classNames from 'classnames';

type Sizes = 'small' | 'medium';

type Props = {
  title: React.ReactNode;
  subTitle: React.ReactNode;
  action?: React.ReactNode;
  size?: Sizes;
};

const EmptyState: React.FC<Props & React.HTMLAttributes<HTMLElement>> = ({
  title,
  subTitle,
  size = 'small',
  action,
  ...props
}) => {
  const defaultClassName =
    'flex flex-col items-center justify-center gap-2 p-9 bg-gray-75 dark:bg-gray-925';
  const className = classNames(defaultClassName, props.className, {
    'p-9': size === 'small',
    'pt-20 pb-16 px-9': size === 'medium',
  });

  return (
    <div {...props} className={className}>
      <Text type="heading" size="small">
        {title}
      </Text>
      <Text
        type="body"
        size="small"
        color="text-gray-500"
        className={classNames({ 'mb-4': action })}
      >
        {subTitle}
      </Text>
      {action}
    </div>
  );
};

export default EmptyState;
