/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import isEqual from 'lodash/isEqual';
import useShortcut from '@blastradius/ui/hooks/use-shortcut';

type DrawerProps = {
  drawerId?: string;
  component: React.ReactNode | React.FC<any> | React.ComponentType<any>;
  props?: any;
};

type SubDrawerProps = {
  mainDrawerId?: string;
} & DrawerProps;

export type DrawerNavigateToParams = {
  drawer?: DrawerProps;
  subDrawer?: SubDrawerProps;
};

export type DrawerContextProps = {
  opened: boolean;
  subOpened: boolean;
  openDrawer: ({ drawer, subDrawer }: DrawerNavigateToParams) => void;
  closeDrawer: () => void;
  navigateTo: ({ drawer, subDrawer }: DrawerNavigateToParams) => void;
  navigateBack: () => void;
  closeSubDrawer: () => void;
};

/* istanbul ignore next */
export const DrawerContext = React.createContext<DrawerContextProps>({
  opened: false,
  subOpened: false,
  openDrawer: () => void 0,
  closeDrawer: () => void 0,
  navigateTo: () => void 0,
  navigateBack: () => void 0,
  closeSubDrawer: () => void 0,
});

const DrawerProvider: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
}) => {
  // Main Drawers States
  const [drawerOpened, setDrawerOpened] = React.useState(false);
  const [drawers, setDrawers] = React.useState<DrawerProps[]>([]);

  // Sub Drawers States
  // Note: sub drawers must be linked to a main drawer
  const [subDrawerOpened, setSubDrawerOpened] = React.useState(false);
  const [subDrawers, setSubDrawers] = React.useState<SubDrawerProps[]>([]);

  function handleNavigateTo({ drawer, subDrawer }: DrawerNavigateToParams) {
    const drawerId = Math.random().toString(36).slice(2);

    /* istanbul ignore else */
    if (drawer) {
      const { component, props } = drawer;

      setDrawers((prevState) => {
        const prevDrawer = prevState[prevState.length - 1];
        if (
          prevDrawer?.component === component &&
          isEqual(prevDrawer?.props, props)
        ) {
          return prevState;
        }

        return [...prevState, { drawerId, component, props }];
      });
    }

    if (subDrawer) {
      // If open one each time, use the state to get the drawer id
      // If open both together use the drawerId created on condition above
      const mainDrawerId = drawer
        ? drawerId
        : (drawers[drawers.length - 1]?.drawerId as string);
      const { component, props } = subDrawer;

      setSubDrawers((prevState) => {
        const prevSubDrawer = prevState[prevState.length - 1];

        if (
          prevSubDrawer?.component === component &&
          isEqual(prevSubDrawer?.props, props)
        ) {
          return prevState;
        }

        return [...prevState, { mainDrawerId, component, props }];
      });
    }
  }

  function handleOpen({ drawer, subDrawer }: DrawerNavigateToParams) {
    handleNavigateTo({ drawer, subDrawer });

    setTimeout(() => {
      if (drawer) {
        setDrawerOpened(true);
      }

      if (subDrawer) {
        setSubDrawerOpened(true);
      }
    });
  }

  function handleClose() {
    setDrawerOpened(false);
    setSubDrawerOpened(false);

    setTimeout(() => {
      setDrawers([]);
      setSubDrawers([]);
    }, 250);
  }

  function handleCloseSubDrawer() {
    if (subDrawers.length === 1) {
      setSubDrawerOpened(false);

      setTimeout(() => {
        setSubDrawers((prevValue) => prevValue.slice(0, -1));
      }, 250);
    } else {
      setSubDrawers((prevValue) => prevValue.slice(0, -1));
    }
  }

  function handleNavigateBack() {
    setDrawers((prevValue) => prevValue.slice(0, -1));

    if (
      subDrawers.length > 0 &&
      drawers[drawers.length - 1].drawerId ===
        subDrawers[subDrawers.length - 1].mainDrawerId
    ) {
      handleCloseSubDrawer();
    }
  }

  useShortcut([
    {
      keys: ['Escape'],
      callback: () => handleClose(),
    },
  ]);

  return (
    <DrawerContext.Provider
      value={{
        opened: drawerOpened,
        subOpened: subDrawerOpened,
        openDrawer: handleOpen,
        closeDrawer: handleClose,
        navigateTo: handleNavigateTo,
        navigateBack: handleNavigateBack,
        closeSubDrawer: handleCloseSubDrawer,
      }}
    >
      {children}
      {drawers.length > 0 &&
        drawers.map((drawer, index) =>
          (drawer.component as any)({
            ...drawer.props,
            key: `drawer_${index}`,
            isLastDrawer: index === drawers.length - 1,
          }),
        )}
      {subDrawers.length > 0 &&
        subDrawers.map((drawer, index) =>
          (drawer.component as any)({
            ...drawer.props,
            key: `drawer_sub_${index}`,
          }),
        )}
    </DrawerContext.Provider>
  );
};

export default DrawerProvider;
