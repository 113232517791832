import React from 'react';
import millify from 'millify';

type Props = {
  value: number;
};

const Number: React.FC<Props> = ({ value }) => {
  const formattedNumber = React.useMemo(
    () =>
      value != null && !isNaN(value)
        ? millify(value, { lowercase: true })
        : '-',
    [value],
  );

  return <>{formattedNumber}</>;
};

export default Number;
