import { Text, Icon, DateFormat } from '@blastradius/ui';
import { alertResolutionMapper } from '@customer-web-app/domains/alerts/mappers/alert-resolution-mapper';
import {
  Alert,
  AlertTypes,
} from '@customer-web-app/domains/alerts/models/alert';
import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import SearchFilterService from '@customer-web-app/domains/search/services/search-filter-service';

type Props = {
  alert: Alert;
  loading?: boolean;
  selected?: boolean;
  borderBottom?: boolean;
  keyword?: string;
  iconClassName?: string;
};

type AlertDetailRowProps = {
  value: React.ReactNode;
  label: string;
  loading: boolean;
};

const AlertDetailRow: React.FC<
  AlertDetailRowProps & React.HTMLAttributes<HTMLDivElement>
> = ({ value, label, loading, ...props }) => {
  return (
    <div {...props} className="flex items-center">
      <Text
        type="body"
        size="x-small"
        color="text-gray-500"
        className="truncate max-w-[8rem]"
      >
        {!loading ? (
          <>
            {label}:{' '}
            <Text type="body" size="x-small" color="text-gray-500">
              {value}
            </Text>
          </>
        ) : (
          <Skeleton width={100} height={9} />
        )}
      </Text>
    </div>
  );
};

const MainSearchBarAlertCard = React.forwardRef<
  HTMLAnchorElement,
  Props & React.HTMLAttributes<HTMLAnchorElement>
>(
  (
    {
      alert,
      loading,
      selected,
      borderBottom,
      keyword,
      iconClassName,
      ...props
    },
    ref,
  ) => {
    const defaultClassName = `flex items-center gap-4 p-4 -mt-px group rounded hover:rounded
  transition-all ease-in-out duration-200`;

    const className = classNames(props.className, defaultClassName, {
      'hover:bg-white dark:hover:bg-white/[.06]': !selected,
      'bg-white dark:bg-white/[.06]': selected,
      'pointer-events-none': loading,
    });

    const href = {
      pathname: `/alerts/${alert?.id}/details`,
      query: {
        ...(alert?.incidentID ? { incidentId: alert.incidentID } : {}),
        resetHistory: true,
      },
    };

    return (
      <>
        <Link href={href} {...props} className={className} ref={ref}>
          <>
            <div
              className={classNames(
                `flex justify-center items-center w-8 h-8 rounded transition-all ease-in-out duration-200 shrink-0`,
                {
                  'bg-white group-hover:bg-gray-100 dark:bg-gray-925 dark:group-hover:bg-gray-925':
                    !selected,
                  'bg-gray-100 dark:bg-gray-925': selected,
                },
                iconClassName,
              )}
            >
              {!loading && alert && 'isEscalated' in alert && (
                <Icon
                  name={
                    alertResolutionMapper[alert?.isEscalated?.toString()]
                      .alertResolutionIcon
                  }
                  size={16}
                />
              )}
            </div>
            <div className="flex flex-col justify-center gap-1.5">
              <div className="flex items-center gap-2">
                <Text
                  type="label-caps"
                  gradient={
                    alert &&
                    'isEscalated' in alert &&
                    alertResolutionMapper[alert?.isEscalated?.toString()]
                      ?.alertHasGradient
                  }
                  color={
                    alert &&
                    'isEscalated' in alert &&
                    alertResolutionMapper[alert?.isEscalated?.toString()]
                      ?.alertResolutionColor
                  }
                  as="div"
                >
                  {!loading ? (
                    alert &&
                    'isEscalated' in alert &&
                    alertResolutionMapper[alert?.isEscalated?.toString()]
                      .alertResolutionCopy
                  ) : (
                    <Skeleton width={57} height={12} />
                  )}
                </Text>

                {!loading ? (
                  <Text
                    type="body"
                    size="large"
                    as="h3"
                    className="truncate max-w-[33rem]"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: SearchFilterService.highlightKeyword(
                          alert?.alertName,
                          keyword,
                        ),
                      }}
                    />
                  </Text>
                ) : (
                  <Skeleton width={350} height={15} />
                )}
              </div>

              <div className="flex items-center gap-5">
                <AlertDetailRow
                  label="Date"
                  value={<DateFormat value={alert?.createdOn} pattern="long" />}
                  loading={loading}
                  data-testid="alert-date"
                />
                <AlertDetailRow
                  label="Alert ID"
                  value={alert?.alertNumber}
                  loading={loading}
                  data-testid="alert-id"
                />
                <AlertDetailRow
                  label="Vendor"
                  value={alert?.connectorVendorName}
                  loading={loading}
                  data-testid="alert-vendor"
                />
                <AlertDetailRow
                  label="Identity"
                  value={
                    alert?.alertDetail?.identity?.displayName ||
                    alert?.alertDetail?.actor?.userDisplayName
                  }
                  loading={loading}
                  data-testid="alert-identity"
                />
                {alert?.alertType === AlertTypes.Endpoint && (
                  <AlertDetailRow
                    label="Device"
                    value={alert?.alertDetail?.host}
                    loading={loading}
                    data-testid="alert-device"
                  />
                )}
              </div>
            </div>
          </>
        </Link>
        {borderBottom && (
          <div className="w-[calc(100%-2rem)] mx-auto h-px bg-gray-100 dark:bg-white/[.08] -mt-px" />
        )}
      </>
    );
  },
);
MainSearchBarAlertCard.displayName = 'MainSearchBarAlertCard';

export default MainSearchBarAlertCard;
