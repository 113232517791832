import React from 'react';
import { useRouter } from 'next/router';
import { DrawerContext } from '@blastradius/ui';

const RouterChangeProvider = () => {
  const { closeDrawer } = React.useContext(DrawerContext);

  const router = useRouter();

  React.useEffect(() => {
    const handleRouteChange = () => {
      closeDrawer();
    };

    router?.events.on('routeChangeStart', handleRouteChange);
  }, []);

  React.useEffect(() => {
    const main = document.getElementById('main');
    main?.scrollTo(0, 0);
  }, [router.pathname]);

  return null;
};

export default RouterChangeProvider;
