import { TimeFrameOptions } from '@customer-web-app/domains/search/models/time-frame-options';
import { subDays, subYears } from 'date-fns';

function getTimeFrame(timeFrame: TimeFrameOptions) {
  const currentDate = new Date();

  const timeFrameMapper: {
    [key in TimeFrameOptions]?: Date;
  } = {
    'Last 7 Days': subDays(currentDate, 7),
    'Last 30 Days': subDays(currentDate, 30),
    'Last 90 Days': subDays(currentDate, 90),
    'Last Year': subYears(currentDate, 1),
  };

  return timeFrameMapper?.[timeFrame];
}

export default getTimeFrame;
