import React from 'react';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';

type Colors = 'red' | 'green' | 'neutral' | 'gray';

const colorsMapper: {
  [key in Colors]: { background: string };
} = {
  red: {
    background: 'bg-gradient-to-b from-red-500 to-pink-500',
  },
  green: {
    background: 'bg-green-500',
  },
  neutral: {
    background: 'bg-gray-950 dark:bg-white',
  },
  gray: {
    background: 'bg-gray-300',
  },
};

type Props = {
  progress: number;
  color: Colors;
  height?: string;
  backgroundColor?: string;
  loading?: boolean;
};

const ProgressBar: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  progress,
  color,
  height = 'h-0.5',
  backgroundColor = '',
  loading,
  ...props
}) => {
  const percentageWidth = progress * 100;
  const { background } = colorsMapper[color];
  const defaultClassName = 'inline-flex rounded-sm w-full';

  return !loading ? (
    <div
      {...props}
      className={classNames(
        props.className,
        height,
        defaultClassName,
        'overflow-hidden relative',
      )}
      aria-label={`A ${color} ${Math.round(
        percentageWidth,
      )}% completed progress bar`}
    >
      <div
        data-testid="progress-wrapper"
        className={classNames(
          defaultClassName,
          height,
          backgroundColor || background,
          'opacity-30 w-full absolute top-0 left-0 z-10',
        )}
      />
      <div
        data-testid="progress-value"
        className={classNames(
          defaultClassName,
          height,
          backgroundColor || background,
          'absolute top-0 left-0 z-20',
        )}
        style={{ width: `${percentageWidth}%` }}
      />
    </div>
  ) : (
    <div className={height}>
      <Skeleton height={2} />
    </div>
  );
};

export default ProgressBar;
