import { Themes } from '@blastradius/ui/themes';
import React from 'react';
import TooltipStyles from '../tooltip/tooltip-styles';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import useTheme from '../../hooks/use-theme';

const tooltipThemeMapper: {
  [key in Themes]: React.ComponentProps<typeof ReactTooltip>['variant'];
} = {
  light: 'dark',
  dark: 'light',
};

type Props = {
  maxSize?: number;
  separator?: string;
  value: React.ReactNode;
  tooltip?: React.ComponentProps<typeof ReactTooltip>;
  withTooltip?: boolean;
};

function setMiddleTruncation(
  value: string,
  maxSize: number,
  separator: string,
) {
  if (!value) {
    return '-';
  }

  if (value.length <= maxSize) {
    return value;
  }

  const sepLen = separator.length;
  const charsToShow = maxSize - sepLen;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return `${value.substring(0, frontChars)}${separator}${value.substring(
    value.length - backChars,
  )}`;
}

const MiddleTruncation: React.FC<Props> = ({
  value,
  separator = '...',
  maxSize = 48,
  tooltip = undefined,
  withTooltip = true,
}) => {
  const tooltipId = Math.random().toString(36).slice(2);
  const { theme } = useTheme();
  const tooltipStyle = TooltipStyles(theme);
  const [hasTooltip] = React.useState(
    (value?.toString() as string)?.length > maxSize,
  );

  return (
    <span data-tooltip-id={tooltipId} data-tooltip-content={value}>
      {setMiddleTruncation(value as string, maxSize, separator)}
      {withTooltip && hasTooltip && (
        <ReactTooltip
          {...tooltip}
          id={tooltipId}
          place={tooltip?.place || 'bottom'}
          variant={tooltipThemeMapper[theme]}
          style={{
            backgroundColor: tooltipStyle['data-background-color'],
            zIndex: 50,
          }}
          arrowColor={tooltipStyle['data-background-color']}
        />
      )}
    </span>
  );
};

export default MiddleTruncation;
