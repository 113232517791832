import React from 'react';

export type DialogComponent = React.ReactNode | React.FC;

export type DialogContextProps = {
  opened: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openDialog: (component: DialogComponent, props?: any) => void;
  closeDialog: () => void;
};

/* istanbul ignore next */
export const DialogContext = React.createContext<DialogContextProps>({
  opened: false,
  openDialog: () => void 0,
  closeDialog: () => void 0,
});

const DialogProvider: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
}) => {
  const [opened, setOpened] = React.useState(false);
  const [dialog, setDialog] = React.useState<{
    component: DialogComponent;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props: any;
  }>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleOpen(component: DialogComponent, props?: any) {
    setDialog({ component, props });

    setTimeout(() => {
      setOpened(true);
    });
  }

  function handleClose() {
    setOpened(false);

    setTimeout(() => {
      setDialog(undefined);
    }, 250);
  }

  return (
    <DialogContext.Provider
      value={{ opened, openDialog: handleOpen, closeDialog: handleClose }}
    >
      {children}
      {dialog &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (dialog.component as any)({
          ...dialog.props,
        })}
    </DialogContext.Provider>
  );
};

export default DialogProvider;
