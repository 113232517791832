import React from 'react';
import classNames from 'classnames';
import { ButtonGhost, Icon, NavigatorX, Tabs, Text } from '@blastradius/ui';
import Link from 'next/link';
import MainSearchBarIncidentCard from '@customer-web-app/domains/search/components/main-search-bar-incident-card';
import useIncidents from '@customer-web-app/domains/incidents/hooks/use-incidents';
import IncidentFilterService, {
  IncidentFilterParams,
} from '@customer-web-app/domains/incidents/services/incident-filter-service';
import MainSideNavigationEmptyState from '@customer-web-app/domains/shared/components/main-side-navigation-empty-state';
import { sub } from 'date-fns';
import useFeatureFlags from '@customer-web-app/domains/shared/hooks/use-feature-flags';
import getIncidentsMainScreen from '@customer-web-app/domains/incidents/services/get-incidents-main-screen';
import useIncidentsCounters from '@customer-web-app/domains/incidents/hooks/use-incidents-counters';

type Props = {
  disableHover?: boolean;
};

const MainSideNavigationIncidentsLoading: React.FC<
  { count: number } & React.HTMLAttributes<HTMLElement>
> = ({ count }) => (
  <>
    {Array.from({ length: count }).map((_, index) => (
      <MainSearchBarIncidentCard
        key={index}
        incident={null}
        iconClassName="dark:group-hover:bg-white/[.06]"
        borderBottom={index < count - 1}
        loading
      />
    ))}
  </>
);

const MainSideNavigationIncidents: React.FC<
  Props & React.HTMLAttributes<HTMLElement>
> = ({ disableHover, ...props }) => {
  const currentDate = new Date();

  const flags = useFeatureFlags();

  const defaultFilters = IncidentFilterService.getDefaultIncidentFilter({
    alertsEnableNetworkAlerts: flags.alertsEnableNetworkAlerts,
    alertsEnableTriageRequiredAlertStatus:
      flags.alertsEnableTriageRequiredAlertStatus,
  });

  const { liveIncidentsCount } = useIncidentsCounters();

  const incidentsFilter: IncidentFilterParams = React.useMemo(
    () => ({
      globalFilter: {
        ...defaultFilters.globalFilter,
        entities: ['Incident'],
        dateRange: {
          from: sub(currentDate, {
            years: 1,
          }),

          to: currentDate,
        },
      },
      incidentFilter: {
        ...defaultFilters.incidentFilter,
        incidentStatus: ['opened', 'pending'],
      },
    }),
    [],
  );

  const closedIncidentsFilter: IncidentFilterParams = React.useMemo(
    () => ({
      globalFilter: {
        ...defaultFilters.globalFilter,
        entities: ['Incident'],
        dateRange: {
          from: sub(currentDate, {
            years: 1,
          }),

          to: currentDate,
        },
      },
      incidentFilter: {
        ...defaultFilters.incidentFilter,
        incidentStatus: ['closed', 'falsePositive'],
      },
    }),
    [],
  );

  const { incidentsResults, isLoadingIncidents } = useIncidents({
    formValues: incidentsFilter,
  });

  const {
    incidentsResults: closedIncidents,
    isLoadingIncidents: isLoadingClosedIncidents,
  } = useIncidents({
    formValues: closedIncidentsFilter,
  });

  const latestIncidents = incidentsResults?.slice(0, 10) || [];

  const recentlyClosedIncidents = closedIncidents?.slice(0, 10) || [];

  const defaultClassName = `bg-white dark:bg-gray-900 w-16
  fixed top-0 left-[15.25rem] h-full
  border-l border-l-gray-200 dark:border-l-gray-800
  border-r border-r-gray-200 dark:border-r-gray-800
  w-[49.938rem] z-40
  transition-fade duration-200 ease-in-out delay-200
  -translate-x-4 opacity-0 invisible 
  group-deeper-hover:translate-x-0 group-deeper-hover:opacity-100 group-deeper-hover:visible
  cursor-default pointer-events-none`;

  const className = classNames(defaultClassName, props.className, {
    'group-hover:pointer-events-auto': !disableHover,
  });

  return (
    <div className={className}>
      <header className="flex justify-between pt-4 pl-8 pr-10">
        <Text type="heading" size="small">
          Incidents
        </Text>
        <Link
          href={getIncidentsMainScreen({
            hasLiveIncidents: liveIncidentsCount > 0,
            enableEndpointAlerts: flags.alertsEnableEndpointAlerts as boolean,
            enableIdentityAlerts: flags.alertsEnableIdentityAlerts as boolean,
            enableNetworkAlerts: flags.alertsEnableNetworkAlerts as boolean,
            enableGenericAlerts:
              flags.alertsEnableTriageRequiredAlertStatus as boolean,
          })}
          passHref
          legacyBehavior
        >
          <ButtonGhost
            as="a"
            size="x-small"
            className="flex items-center"
            aria-label="View all incidents"
          >
            View All Incidents{' '}
            <Icon
              name="carrot-right"
              size={16}
              className="fill-gray-950 dark:fill-white ml-1"
            />
          </ButtonGhost>
        </Link>
      </header>

      <section>
        <Tabs className="mt-2 pl-8 pr-10">
          <Tabs.Navigator>
            <NavigatorX>
              <NavigatorX.Item
                as="button"
                size="small"
                aria-label="View Latest Incidents"
                role={'tab'}
                className="pb-3"
              >
                Latest
              </NavigatorX.Item>
              <NavigatorX.Item
                as="button"
                size="small"
                aria-label="View Recently Closed Incidents"
                role={'tab'}
                className="pb-3"
              >
                Recently Closed
              </NavigatorX.Item>
            </NavigatorX>
          </Tabs.Navigator>
          <Tabs.Sections className="mt-6">
            <Tabs.Section aria-label="Latest Incidents" role={'tabpanel'}>
              {!isLoadingIncidents ? (
                latestIncidents?.length > 0 ? (
                  latestIncidents?.map((incident, index) => (
                    <MainSearchBarIncidentCard
                      key={incident.id}
                      incident={incident}
                      iconClassName="dark:group-hover:bg-white/[.06]"
                      className="hover:bg-gray-75 dark:hover:bg-white/[.06]"
                      borderBottom={index < latestIncidents.length - 1}
                    />
                  ))
                ) : (
                  <MainSideNavigationEmptyState>
                    No recent incidents in the last year
                  </MainSideNavigationEmptyState>
                )
              ) : (
                <MainSideNavigationIncidentsLoading count={10} />
              )}
            </Tabs.Section>
            <Tabs.Section
              aria-label="Recently Closed Incidents"
              role={'tabpanel'}
            >
              {!isLoadingClosedIncidents ? (
                recentlyClosedIncidents?.length > 0 ? (
                  recentlyClosedIncidents?.map((incident, index) => (
                    <MainSearchBarIncidentCard
                      key={incident?.id}
                      incident={incident}
                      iconClassName="dark:group-hover:bg-white/[.06]"
                      className="hover:bg-gray-75 dark:hover:bg-white/[.06]"
                      borderBottom={index < latestIncidents?.length - 1}
                    />
                  ))
                ) : (
                  <MainSideNavigationEmptyState>
                    No recent closed incidents in the last year
                  </MainSideNavigationEmptyState>
                )
              ) : (
                <MainSideNavigationIncidentsLoading count={10} />
              )}
            </Tabs.Section>
          </Tabs.Sections>
        </Tabs>
      </section>
    </div>
  );
};

export default MainSideNavigationIncidents;
