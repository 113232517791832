import React from 'react';
import classNames from 'classnames';
import { Text } from '@blastradius/ui';
import Avatar from '../../base-components/avatar';
import Skeleton from 'react-loading-skeleton';
import random from 'lodash/random';

type Props = {
  as?: React.ElementType;
  name: string;
  src?: string;
  loading?: boolean;
};

const ListItemAvatar: React.FC<
  Props & React.HTMLAttributes<HTMLDivElement>
> = ({ name, src, as = 'li', loading, ...props }) => {
  const defaultClassName = `p-4 flex items-center gap-4
    transition-colors duration-75 ease-in-out
    hover:bg-gray-50 hover:dark:bg-gray-925 rounded cursor-pointer`;
  const className = classNames(defaultClassName, props.className, {
    'pointer-events-none': loading,
  });

  return React.createElement(
    as,
    { ...props, className },
    <>
      {!loading ? (
        <Avatar
          src={src}
          size={'small'}
          name={name}
          alt={`Picture of ${name}`}
        />
      ) : (
        <Skeleton circle width={40} height={40} />
      )}
      <Text type="body">
        {!loading ? name : <Skeleton width={random(100, 140)} height={16} />}
      </Text>
    </>,
  );
};

export default ListItemAvatar;
