import useToast from '@blastradius/ui/hooks/use-toast';
import useAuthentication from '@customer-web-app/domains/authentication/hooks/use-authentication';
import Link from 'next/link';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import {
  isWithinInterval,
  differenceInDays,
  formatDistance,
  addDays,
  isBefore,
} from 'date-fns';
import { connectorVendorIcons } from '@customer-web-app/domains/shared/mappers/connector-vendor-icons';
import { Integration } from '@customer-web-app/domains/settings-services/models/integration';
import useConnectorsIntegrations from '@customer-web-app/domains/settings-services/hooks/use-connectors-integrations';
import { ConnectorClasses } from '@customer-web-app/domains/settings-services/enums/connector-classes';
import useCredentialsList from '@customer-web-app/domains/settings-services/hooks/use-credentials-list';
import { Credential } from '@customer-web-app/domains/settings-services/models/credential';

const ExpiredCredentialsWarningProvider = ({ children }) => {
  const { user } = useAuthentication();
  const { notification } = useToast();

  function calculateDaysUntilExpiration(
    expirationDate: Date,
    integrationName: Integration['integrationName'],
  ) {
    const currentDate = new Date();

    if (isBefore(new Date(expirationDate), currentDate)) {
      return `Your ${integrationName} credentials expired.`;
    }

    const daysDifference = differenceInDays(expirationDate, currentDate);

    let expiringCredentialsCopy = `Your ${integrationName} credentials will expire `;

    if (daysDifference === 0) {
      expiringCredentialsCopy += 'today.';
    } else if (daysDifference === 7) {
      expiringCredentialsCopy += 'in a week.';
    } else if (daysDifference > 0) {
      expiringCredentialsCopy += `${formatDistance(
        new Date(expirationDate),
        currentDate,
        {
          addSuffix: true,
        },
      )}.`;
    } else {
      expiringCredentialsCopy += `${formatDistance(
        new Date(expirationDate),
        currentDate,
        {
          addSuffix: true,
        },
      )}.`;
    }

    return expiringCredentialsCopy;
  }

  async function triggerNotification(expiringCredentials: Credential[]) {
    expiringCredentials.forEach((integration) => {
      notification({
        title: 'Expiring Credentials',
        message: calculateDaysUntilExpiration(
          integration.expiresAt,
          integration?.integrationName,
        ),
        duration: 50000000,
        position: 'top-right',
        icon: connectorVendorIcons?.[integration?.integrationVendorCode],
        iconContained: true,
        iconContainedColor: 'red',
        state: 'error',
        customDismiss: true,
        options: [
          {
            type: 'custom',
            component: (
              <Link
                href={{
                  pathname: '/settings/services/data-connectors',
                  query: {
                    expiringCredentialsID: integration?.id,
                  },
                }}
                as="/settings/services/data-connectors"
                passHref
              >
                Update
              </Link>
            ),
          },
          {
            type: 'dismiss',
          },
        ],
      });
    });
  }

  const { credentialsList, isCredentialsListLoading } = useCredentialsList();

  const { connectorsIntegrations, isConnectorsIntegrationsLoading } =
    useConnectorsIntegrations({
      connectorClass: ConnectorClasses.DataConnector,
    });

  React.useEffect(() => {
    const credentialsBeingUsed = connectorsIntegrations?.map(
      ({ integration }) => integration?.id,
    );

    const credentialsToCheckExpiration = credentialsList?.filter(({ id }) =>
      credentialsBeingUsed?.includes(id),
    );

    const currentDate = new Date();

    const credentialsWithExpirationDate = credentialsToCheckExpiration?.filter(
      (credential) => credential?.expiresAt,
    );

    const expiringCredentials = credentialsWithExpirationDate?.filter(
      ({ expiresAt }) =>
        isWithinInterval(new Date(expiresAt), {
          start: currentDate,
          end: addDays(currentDate, 7),
        }) || isBefore(new Date(expiresAt), currentDate),
    );

    if (
      expiringCredentials?.length > 0 &&
      user?.id &&
      !isCredentialsListLoading
    ) {
      triggerNotification(expiringCredentials);
    }
  }, [isCredentialsListLoading, isConnectorsIntegrationsLoading]);

  return (
    <>
      {children}
      <Toaster />
    </>
  );
};

export default ExpiredCredentialsWarningProvider;
