import React from 'react';
import classNames from 'classnames';
import ProgressBar from '../../base-components/progress-bar';
import { Text, Icon } from '@blastradius/ui';

type States = 'incomplete' | 'inProgress' | 'completed';

type StepperItemProps = {
  title: string;
  description?: string;
  state: States;
};

type SubComponents = {
  Item: React.FC<StepperItemProps & React.HTMLAttributes<HTMLDivElement>>;
};

const Stepper: React.FC<React.HTMLAttributes<HTMLDivElement>> &
  SubComponents = ({ children, ...props }) => {
  const defaultClassName = 'flex gap-0.5';

  return (
    <div className={classNames(defaultClassName, props.className)}>
      {children}
    </div>
  );
};

const statesMapper: {
  [key in States]: {
    progress: number;
    icon: React.ComponentProps<typeof Icon>['name'];
    iconColor: string;
  };
} = {
  incomplete: {
    progress: 0,
    icon: 'circle',
    iconColor: 'stroke-gray-950/[.3] dark:stroke-white/[.3]',
  },
  inProgress: {
    progress: 1,
    icon: 'circle-minus',
    iconColor: 'stroke-gray-950 dark:stroke-white',
  },
  completed: {
    progress: 1,
    icon: 'circle-check',
    iconColor: 'fill-green-500',
  },
};

const StepperItem: SubComponents['Item'] = ({
  title,
  description,
  state,
  ...props
}) => {
  const defaultClassName = 'flex flex-col w-full';

  return (
    <div {...props} className={classNames(defaultClassName, props.className)}>
      <ProgressBar progress={statesMapper[state].progress} color="neutral" />
      <div className="flex items-start gap-2 mt-2">
        <Icon
          name={statesMapper[state].icon}
          size={16}
          className={classNames(statesMapper[state].iconColor, '-mt-px')}
        />
        <div className="flex flex-col gap-0.5">
          <Text type="label">{title}</Text>
          {description && (
            <Text type="body" size="x-small" color="text-gray-500">
              {description}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};
Stepper.Item = StepperItem;

export default Stepper;
