import classNames from 'classnames';
import React from 'react';
import Card from '../../base-components/card';
import InputCheckbox from '../../base-components/input-checkbox';
import Text from '../../base-components/text';

type SelectionType = 'multiple' | 'single';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CheckedRow = any;

type ITableContext = {
  comparisonKey: string;
  tableRows: React.ReactElement<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    string | React.JSXElementConstructor<any>
  >[];
  totalRows: number;
  checkedRows: CheckedRow[];
  uncheckAllRows: () => void;
  toggleRow: (checkedRow: CheckedRow) => void;
  toggleAllRows: () => void;
  showActions: boolean;
  setShowActions: React.Dispatch<React.SetStateAction<boolean>>;
};

/* istanbul ignore next */
const TableContext = React.createContext<ITableContext>({
  comparisonKey: '',
  tableRows: [],
  totalRows: 0,
  checkedRows: [],
  uncheckAllRows: () => void 0,
  toggleRow: () => void 0,
  toggleAllRows: () => void 0,
  showActions: false,
  setShowActions: () => void 0,
});

type TableProps = {
  cols: string[];
  withCheckboxes?: boolean;
  loading?: boolean;
  comparisonKey?: string;
  rowsClassName?: string;
  selectionType?: SelectionType;
  onRowChecked?: (checkedRows: CheckedRow[]) => void;
  checkAllRows?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkedItems?: any[];
  allowSelectOnWholeRow?: boolean;
  allowClearFromOtherElements?: boolean;
  reset?: boolean;
};

function TableDeprecated({
  cols,
  withCheckboxes = false,
  loading = false,
  comparisonKey = 'id',
  rowsClassName = '',
  selectionType = 'multiple',
  onRowChecked,
  checkAllRows = false,
  checkedItems = [],
  reset = false,
  allowSelectOnWholeRow = false,
  allowClearFromOtherElements,
  children,
  ...props
}: TableProps & React.HTMLAttributes<HTMLDivElement>) {
  const checkedItemsFormatted = React.useMemo(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    () =>
      checkedItems
        .filter((o) => o)
        .map((item: any) => (item.data ? item : { data: item })),
    [checkedItems],
  );

  const [initialCheckedRows] = React.useState<CheckedRow[]>(
    checkedItemsFormatted,
  );
  const [checkedRows, setCheckedRows] = React.useState<CheckedRow[]>(
    checkedItemsFormatted,
  );
  const [showActions, setShowActions] = React.useState(false);
  const defaultClassName = 'flex flex-col gap-1 w-full';
  const tableRowsClassName = classNames(
    'flex flex-col gap-1 w-full',
    rowsClassName,
  );

  const className = classNames(defaultClassName, props.className, {
    'pointer-events-none': loading,
  });
  const childrenNodes = React.Children.toArray(
    children,
  ) as React.ReactElement[];

  const tableRows = childrenNodes
    .filter(
      (child) => !(child.type as { name: string }).name?.includes('Header'),
    )
    .map((child, index) =>
      React.cloneElement(child, {
        cols,
        ...child.props,
        index,
        comparisonKey,
        withCheckbox: withCheckboxes,
        allowSelectOnWholeRow,
        onRowChecked,
      }),
    );

  const tableHeader = React.cloneElement(
    childrenNodes.find((child) =>
      (child.type as { name: string }).name?.includes('Header'),
    ) || ((<></>) as React.ReactElement),
    { cols, withCheckbox: withCheckboxes && tableRows.length > 0 },
  );

  function toggleRow(checkedRow: CheckedRow) {
    const isIndexChecked = checkedRows.findIndex(
      ({ data }) => checkedRow.data?.[comparisonKey] === data?.[comparisonKey],
    );

    if (selectionType === 'multiple') {
      setCheckedRows((prevValue) => {
        const updatedRows =
          isIndexChecked === -1
            ? [...prevValue, checkedRow]
            : prevValue.filter(
                (currentRows) =>
                  currentRows.data?.[comparisonKey] !==
                  checkedRow.data?.[comparisonKey],
              );

        onRowChecked?.(updatedRows);

        return updatedRows;
      });
    }

    if (selectionType === 'single') {
      setCheckedRows(() => {
        const updatedRows = isIndexChecked === -1 ? [checkedRow] : [];

        onRowChecked?.(updatedRows);

        return updatedRows;
      });
    }
  }

  function toggleAllRows() {
    setCheckedRows(() => {
      const updatedRows =
        checkedRows.length > 0
          ? []
          : tableRows.map(({ props }) => ({ data: props.data }));

      onRowChecked?.(updatedRows);

      return updatedRows;
    });
  }

  function uncheckAllRows() {
    setCheckedRows([]);
  }

  function resetTable() {
    setCheckedRows(initialCheckedRows);
  }

  React.useLayoutEffect(() => {
    if (checkAllRows) {
      toggleAllRows();
    }
  }, []);

  React.useEffect(() => {
    if (reset) {
      resetTable();
    }
  }, [reset]);

  React.useEffect(() => {
    if (
      allowClearFromOtherElements &&
      checkedItems.length === 0 &&
      checkedRows.length > 0
    ) {
      uncheckAllRows();
    }
  }, [checkedItems]);

  return (
    <TableContext.Provider
      value={{
        comparisonKey,
        tableRows,
        totalRows: tableRows.length,
        checkedRows,
        uncheckAllRows,
        toggleRow,
        toggleAllRows,
        showActions,
        setShowActions,
      }}
    >
      <div {...props} className={className} role="table">
        {tableHeader}
        <div className={tableRowsClassName}>{tableRows}</div>
      </div>
    </TableContext.Provider>
  );
}

type HeaderProps = {
  withCheckbox?: boolean;
  withSelectAll?: boolean;
  colsLabels: string[];
  cols?: string[];
  actions?: (
    checkedRows: CheckedRow[],
    uncheckAllRows: () => void,
  ) => React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectAll?: (data: any) => void;
  name: string;
  loading?: boolean;
  renderCounter?: boolean;
};

function Header({
  withCheckbox,
  withSelectAll = true,
  children,
  colsLabels,
  cols = [],
  onSelectAll,
  actions,
  name,
  loading,
  renderCounter = true,
  ...props
}: HeaderProps & React.HTMLAttributes<HTMLDivElement>) {
  const {
    showActions,
    totalRows,
    checkedRows,
    tableRows,
    comparisonKey,
    uncheckAllRows,
    toggleAllRows,
    setShowActions,
  } = React.useContext(TableContext);
  /*
    We use this to update the state when some rows are selected and
    are affected by an optimistic update, 
    e.g.: select items and remove some item from side panel action.
    
    This memo updates the value and counts correctly.
  */
  const [intermediaryCheckedRows, checkedRowsCount] = React.useMemo(() => {
    const tableRowsComparisonKeys = tableRows.map(
      (tableRow) => tableRow?.props?.data?.[comparisonKey],
    );

    const rows = checkedRows.filter((checkedRow) =>
      tableRowsComparisonKeys.includes(checkedRow.data?.[comparisonKey]),
    );

    return [rows, rows.length];
  }, [checkedRows, tableRows]);

  React.useEffect(() => {
    setShowActions(checkedRows.length > 0);
  }, [checkedRows]);

  const checkboxSpacing = withCheckbox ? '2.3rem' : '';
  const gridTemplateColumns = [checkboxSpacing, ...cols].join(' ');

  return (
    <div
      {...props}
      role="rowheader"
      className={classNames('grid', 'items-center py-4 px-5', props.className)}
      style={{ gridTemplateColumns }}
    >
      {withCheckbox && (
        <InputCheckbox
          checked={!!checkedRows.length}
          size="large"
          background={classNames('bg-gray-200 dark:bg-white/20', {
            'opacity-0 invisible pointer-events-none':
              loading || !withSelectAll,
          })}
          partial={checkedRows.length < totalRows}
          onChange={() => {
            toggleAllRows();
            onSelectAll?.(checkedRows);
          }}
          data-testid="table-header-checkbox"
        />
      )}

      {!showActions || !withSelectAll || !renderCounter ? (
        colsLabels.map((label) => (
          <Text key={label} type="body" size="small" color="text-gray-500">
            {label}
          </Text>
        ))
      ) : (
        <div className="flex items-center gap-7">
          <Text type="body" size="small">
            {checkedRowsCount} {name}
            {checkedRowsCount > 1 ? 's' : ''} Selected
          </Text>

          {actions && actions(intermediaryCheckedRows, uncheckAllRows)}
        </div>
      )}
    </div>
  );
}

TableDeprecated.Header = Header;

type RowProps = {
  index?: number;
  withCheckbox?: boolean;
  cols?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect?: (data: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  withHover?: boolean;
  comparisonKey?: string;
  hoverButton?: React.ReactElement;
  allowSelectOnWholeRow?: boolean;
};

const Row = React.forwardRef<
  HTMLDivElement,
  RowProps & React.HTMLAttributes<HTMLDivElement>
>(
  (
    {
      withCheckbox,
      children,
      data,
      cols = [],
      onSelect,
      onClick,
      withHover,
      hoverButton,
      comparisonKey,
      allowSelectOnWholeRow,
      index,
      ...props
    },
    ref,
  ) => {
    const { checkedRows, toggleRow } = React.useContext(TableContext);

    const isChecked = React.useMemo(
      () =>
        checkedRows.some(
          (row) =>
            row.data?.[comparisonKey as string] ===
            data?.[comparisonKey as string],
        ),
      [checkedRows],
    );

    const checkboxRef = React.useRef<HTMLInputElement>(null);

    const checkboxSpacing = withCheckbox ? '2.3rem' : '';
    const hoverButtonSpacing = hoverButton ? '1fr' : '';
    const gridTemplateColumns = [
      checkboxSpacing,
      ...cols,
      hoverButtonSpacing,
    ].join(' ');

    const defaultClassName = 'py-3 pl-5 pr-4 group';

    const className = classNames(
      'grid items-center',
      props.className,
      defaultClassName,
      {
        'cursor-pointer transition-colors ease-in-out duration-200':
          onClick || withHover || hoverButton,
        'hover:bg-gray-75 dark:hover:bg-white/[.12]':
          (onClick || withHover || hoverButton) && !isChecked,
        group: hoverButton,
        'cursor-pointer': allowSelectOnWholeRow,
      },
    );

    function handleSelectRow() {
      toggleRow({ data });

      if (onSelect) {
        onSelect(data);
      }
    }

    function handleClick(e: React.MouseEvent<HTMLDivElement>) {
      if (
        !['input', 'button'].includes(
          (e.target as HTMLElement).tagName.toLowerCase(),
        )
      ) {
        onClick?.(e);

        if (allowSelectOnWholeRow) {
          handleSelectRow();
        }
      }
    }

    return (
      <Card
        {...props}
        className={className}
        style={{ gridTemplateColumns }}
        background={
          !isChecked
            ? 'bg-white dark:bg-gray-900'
            : 'bg-gray-75 dark:bg-white/[.12]'
        }
        onClick={handleClick}
        ref={ref}
        role="row"
        aria-selected={isChecked}
      >
        {withCheckbox && (
          <div
            className={classNames('transition-fade ease-in-out duration-200', {
              'opacity-100 visible': isChecked,
              'opacity-0 invisible group-hover:opacity-100 group-hover:visible':
                !isChecked,
            })}
          >
            <InputCheckbox
              checked={isChecked}
              size="large"
              onChange={handleSelectRow}
              background="bg-gray-200 dark:bg-white/20"
              data-testid="table-row-checkbox"
              ref={checkboxRef}
            />
          </div>
        )}

        {children}

        {hoverButton
          ? React.cloneElement(hoverButton, {
              ...hoverButton.props,
              className: classNames(
                hoverButton.props?.className,
                'ml-auto invisible opacity-0 group-hover:visible group-hover:opacity-100',
              ),
            })
          : null}
      </Card>
    );
  },
);
TableDeprecated.Row = Row;

export default TableDeprecated;
