import React from 'react';

type SubComponents = {
  Navigator: React.FC<React.HTMLAttributes<HTMLDivElement>>;
  Sections: React.FC<React.HTMLAttributes<HTMLDivElement>>;
  Section: React.FC<React.HTMLAttributes<HTMLDivElement>>;
};

interface ITabsContext {
  currentTabIndex: number;
  setCurrentTabIndex: React.Dispatch<
    React.SetStateAction<ITabsContext['currentTabIndex']>
  >;
}

/* istanbul ignore next */
const TabsContext = React.createContext<ITabsContext>({
  currentTabIndex: 0,
  setCurrentTabIndex: () => void 0,
});

const Tabs: React.FC<React.HTMLAttributes<HTMLDivElement>> & SubComponents = ({
  children,
  ...props
}) => {
  const [currentTabIndex, setCurrentTabIndex] =
    React.useState<ITabsContext['currentTabIndex']>(0);

  return (
    <TabsContext.Provider value={{ currentTabIndex, setCurrentTabIndex }}>
      <div {...props}>{children}</div>
    </TabsContext.Provider>
  );
};

const Navigator: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => {
  const navigatorWrapper = children as JSX.Element;

  if (!navigatorWrapper?.props?.children) {
    throw new Error(
      'BlastRadius UI / Elements to compose the navigator not found. Consider using some navigator component, e.g.: <NavigatorX> + <NavigatorX.Item> components',
    );
  }

  const { currentTabIndex, setCurrentTabIndex } = React.useContext(TabsContext);

  const childList = Array.isArray(navigatorWrapper.props.children)
    ? navigatorWrapper.props.children
    : [navigatorWrapper.props.children];

  const navigatorItems = childList.map((item: JSX.Element, index: number) =>
    React.cloneElement(item, {
      ...item.props,
      key: index,
      onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
        setCurrentTabIndex(index);
        item.props.onClick?.(e);
      },
      state: currentTabIndex === index ? 'on' : 'idle',
    }),
  );

  return React.cloneElement(navigatorWrapper, props, navigatorItems);
};
Tabs.Navigator = Navigator;

const Sections: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => {
  const { currentTabIndex } = React.useContext(TabsContext);
  const sections = children as JSX.Element[];

  return <article {...props}>{sections[currentTabIndex] || children}</article>;
};
Tabs.Sections = Sections;

const Section: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => {
  return <section {...props}>{children}</section>;
};
Tabs.Section = Section;

export default Tabs;
