import React from 'react';
import classNames from 'classnames';
import Icon from '../icon';

type ToggleButtonProps = {
  position?: 'left' | 'right';
  disabled?: boolean;
};

type CollapseToggleButton = React.FC<
  ToggleButtonProps & React.HTMLAttributes<HTMLButtonElement>
>;
type CollapseHeader = React.FC<React.HTMLAttributes<HTMLElement>>;
type CollapseContent = React.FC<React.HTMLAttributes<HTMLElement>>;

type SubComponents = {
  ToggleButton: CollapseToggleButton;
  Header: CollapseHeader;
  Content: CollapseContent;
};

interface ICollapseContext {
  isOpened: boolean;
  hadInteraction?: boolean;
  handleToggle: () => void;
  collapseId: string;
  setIsToggling: React.Dispatch<React.SetStateAction<boolean>>;
}

type Props = {
  as?: React.ElementType;
  open?: boolean;
  close?: boolean;
  render?: (props: Omit<ICollapseContext, 'setIsToggling'>) => React.ReactNode;
};

/* istanbul ignore next */
const CollapseContext = React.createContext<ICollapseContext>({
  isOpened: false,
  hadInteraction: false,
  handleToggle: () => void 0,
  collapseId: '',
  setIsToggling: () => void 0,
});

const Collapse: React.FC<Props & React.HTMLAttributes<HTMLElement>> &
  SubComponents = ({
  open = false,
  close = false,
  render,
  children,
  ...props
}) => {
  const [isOpened, setIsOpened] = React.useState(false);
  const [hadInteraction, setHadInteraction] = React.useState(open);
  const [collapseId, setCollapseId] = React.useState('');
  const [isToggling, setIsToggling] = React.useState(false);

  React.useEffect(() => {
    setCollapseId(Math.random().toString(36).slice(2));
  }, []);

  React.useEffect(() => {
    setIsOpened(open);
  }, [open]);

  React.useEffect(() => {
    if (close) {
      setIsToggling(true);
      setIsOpened(false);
      setTimeout(() => {
        setIsToggling(false);
      }, 100);
    }
  }, [close]);

  function handleToggle() {
    if (!isToggling) {
      setIsOpened(!isOpened);
      setHadInteraction(true);
    }
  }

  return (
    <CollapseContext.Provider
      value={{
        isOpened,
        hadInteraction,
        handleToggle,
        collapseId,
        setIsToggling,
      }}
    >
      <div aria-expanded={open} {...props}>
        {render ? render({ isOpened, handleToggle, collapseId }) : children}
      </div>
    </CollapseContext.Provider>
  );
};

const ToggleButton: CollapseToggleButton = ({
  position = 'right',
  disabled = false,
  children,
  ...props
}) => {
  const [isToggling, setIsToggling] = React.useState(false);
  const { isOpened, handleToggle } = React.useContext(CollapseContext);

  const buttonClassName = classNames(props.className, {
    'cursor-default': disabled,
  });

  const iconClassName = classNames(
    'transition-transform duration-200 ease-in-out',
    {
      'rotate-90': isOpened,
      'opacity-0 invisible': disabled,
    },
  );

  React.useEffect(() => {
    if (isOpened) {
      setIsToggling(true);
      // 10ms after the effect to open/close the collapse concludes
      setTimeout(() => {
        setIsToggling(false);
      }, 210);
    }
  }, [isOpened]);

  return (
    <button
      {...props}
      className={buttonClassName}
      onClick={!isToggling && !disabled ? handleToggle : undefined}
      aria-label="Collapse toggle"
    >
      {position === 'left' && (
        <Icon className={iconClassName} name="carrot-right" size={16} />
      )}
      {children}
      {position === 'right' && !disabled && (
        <Icon className={iconClassName} name="carrot-right" size={16} />
      )}
    </button>
  );
};
Collapse.ToggleButton = ToggleButton;

const Header: CollapseHeader = ({ children, ...props }) => {
  return <header {...props}>{children}</header>;
};
Collapse.Header = Header;

const Content: CollapseContent = ({ children, className, ...props }) => {
  const { isOpened, hadInteraction, collapseId } =
    React.useContext(CollapseContext);
  const ref = React.createRef<HTMLDivElement>();
  const [contentHeight, setContentHeight] = React.useState<number | string>(0);
  const defaultClassName = classNames(
    'transition-all duration-200 ease-in-out overflow-hidden',
  );
  const contentClassName = classNames('transition-all ease-in-out', {
    'opacity-0 invisible -translate-y-1 duration-[0ms]': !isOpened,
    'opacity-1 visible translate-y-0 duration-200': isOpened,
  });

  function onTransitionEnd() {
    const self = document.getElementById(collapseId);
    const opened = /true/i.test(self?.getAttribute('data-open') as string);

    if (opened) {
      setContentHeight('auto');
    }
  }

  React.useEffect(() => {
    if (ref.current) {
      const self = document.getElementById(collapseId);
      const opened = /true/i.test(self?.getAttribute('data-open') as string);

      if (opened && self?.style.height !== 'auto') {
        setContentHeight(ref.current?.offsetHeight as number);
      }

      if (!opened && self?.style.height === 'auto') {
        setContentHeight(ref.current?.offsetHeight as number);

        setTimeout(() => {
          setContentHeight(0);
        });
      }

      self?.addEventListener('transitionend', onTransitionEnd);

      return () => {
        self?.removeEventListener('transitionend', onTransitionEnd);
      };
    }

    return;
  }, [ref, collapseId]);

  return (
    <div
      {...props}
      data-open={isOpened}
      data-had-interaction={hadInteraction}
      id={collapseId}
      className={classNames(className, defaultClassName)}
      style={{
        ...props.style,
        height: contentHeight,
      }}
    >
      <section
        ref={ref}
        aria-label="Collapse Content"
        className={contentClassName}
      >
        {children}
      </section>
    </div>
  );
};
Collapse.Content = Content;

export default Collapse;
