import React from 'react';
import JsonView from 'react18-json-view';
import ButtonIcon from '../button-icon';
import useToast from '../../hooks/use-toast';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customizeCopy?: (copy: any) => void;
  highlightedField?: string;
  style?: {
    [key in string]: string;
  };
};

function JsonPrint({
  log,
  highlightedField,
  style = {
    paddingTop: '2rem',
    paddingBottom: '2rem',
    fontSize: '14px',
  },
  ...props
}: Props) {
  const [isContentCopied, setIsContentCopied] = React.useState(false);
  const { notification } = useToast();

  React.useEffect(() => {
    if (isContentCopied) {
      setTimeout(() => {
        setIsContentCopied(false);
      }, 2000);
    }
  }, [isContentCopied]);

  function copyText(value: string) {
    navigator.clipboard.writeText(value);
    setIsContentCopied(true);
    notification({
      message: 'Text has been copied to clipboard',
      options: [],
    });
  }

  return (
    <JsonView
      src={log}
      style={style}
      customizeNode={(node) => {
        if (!highlightedField) {
          return node.node;
        }

        const isHighlighted = node.indexOrName === highlightedField;

        return isHighlighted ? (
          <div className="relative inline-block highlight-log text-[#2fcd94] group">
            {typeof node.node === 'string' ? `"${node.node}"` : node.node}
            <div className="absolute -top-9 left-[31.25rem] opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all ease-in-out duration-200">
              <ButtonIcon
                icon={!isContentCopied ? 'copy' : 'check'}
                ghost
                onClick={() => copyText(node.node)}
              />
            </div>
          </div>
        ) : undefined;
      }}
      {...props}
    />
  );
}

export default JsonPrint;
