import React from 'react';
import classNames from 'classnames';
import styles from './input-checkbox.module.scss';
import Text from '../text';

type Sizes = 'small' | 'regular' | 'large';

type Props = {
  background?: string;
  checkedBackground?: string;
  label?: React.ReactNode;
  description?: string;
  value?: string;
  withHover?: boolean;
  fullWidth?: boolean;
  idle?: boolean;
  size?: Sizes;
  onClickOnly?: React.MouseEventHandler<HTMLElement>;
  partial?: boolean;
  disabled?: boolean;
  textClassName?: string;
  onlyClickTextClassName?: string;
  textContainerClassName?: string;
};

const sizeMapper: {
  [key in Sizes]: string;
} = {
  small: 'w-[0.914rem] h-[0.914rem]',
  regular: 'w-[0.914rem] h-[0.914rem]',
  large: 'w-[1.125rem] h-[1.125rem]',
};

const textSizeMapper: {
  [key in Sizes]: Sizes;
} = {
  small: 'small',
  regular: 'small',
  large: 'large',
};

const InputCheckbox = React.forwardRef<
  HTMLInputElement,
  Props &
    React.DetailedHTMLProps<
      Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>,
      HTMLInputElement
    >
>(
  (
    {
      background = 'bg-gray-200 dark:bg-gray-700',
      checkedBackground = 'checked:!bg-gray-950 dark:checked:!bg-white',
      withHover = true,
      label,
      description,
      value,
      idle = false,
      size = 'regular',
      onClickOnly,
      partial,
      disabled = false,
      fullWidth = !!label,
      textClassName,
      onlyClickTextClassName,
      textContainerClassName,
      ...props
    },
    ref,
  ) => {
    const defaultClassName = `relative rounded appearance-none outline-0 cursor-pointer
    transition-colors duration-150 ease-in-out before:border-white dark:before:border-black`;
    const className = classNames(
      props.className,
      defaultClassName,
      background,
      checkedBackground,
      sizeMapper[size],
      {
        [styles.inputCheckbox]: !partial,
        [styles.inputCheckboxPartial]: partial,
        'group-hover:dark:bg-gray-600 group-hover:bg-gray-300':
          label && withHover,
        [styles.inputCheckboxIdle]: idle,
        'before:top-[0.12rem] before:left-[0.31rem]':
          size === 'regular' && !partial,
        'before:top-[46.5%] before:left-[2.6px]': size === 'regular' && partial,
        'before:top-[0.2rem] before:left-[0.4rem] before:scale-[115%]':
          size === 'large' && !partial,
        'before:top-[46.5%] before:left-[0.25rem] before:scale-[115%]':
          size === 'large' && partial,
        'opacity-50 pointer-events-none': disabled,
      },
    );

    const inputCheckboxId = React.useMemo(
      () => Math.random().toString(36).slice(2),
      [],
    );

    return (
      <div
        className={classNames('flex items-center group', {
          [styles.inputCheckboxHover]: label && withHover,
          'w-full': label && fullWidth,
          '!items-start': description,
        })}
      >
        <input
          className={className}
          type="checkbox"
          id={inputCheckboxId}
          data-testid="input-checkbox"
          value={value}
          ref={ref}
          {...props}
        />
        <div
          className={classNames(
            'flex flex-col gap-2 pl-2',
            {
              'opacity-50 pointer-events-none': disabled,
            },
            textContainerClassName,
          )}
        >
          {label && (
            <Text
              as="label"
              type="body"
              size={textSizeMapper[size]}
              htmlFor={inputCheckboxId}
              className={classNames(
                `cursor-pointer transition-colors ease-in-out duration-200 mt-px`,
                textClassName,
              )}
              data-testid="input-checkbox-label"
              color={
                idle
                  ? 'text-gray-500 hover:text-gray-950 dark:hover:text-gray-50'
                  : undefined
              }
            >
              {label}
            </Text>
          )}

          {description && (
            <Text
              as="label"
              type="body"
              size="small"
              data-testid="input-checkbox-description"
              color="text-gray-500"
            >
              {description}
            </Text>
          )}
        </div>

        {onClickOnly && (
          <Text
            as="button"
            type="body"
            size="x-small"
            className={classNames(
              'cursor-pointer transition ease-in-out duration-200 mt-px invisible opacity-0 group-hover:visible group-hover:opacity-100 ml-2',
              onlyClickTextClassName,
            )}
            data-testid="input-checkbox-only-label"
            color="text-gray-500 hover:text-gray-950 dark:hover:text-gray-50"
            onClick={onClickOnly}
            aria-label={`Select only ${label} option`}
          >
            Only
          </Text>
        )}
      </div>
    );
  },
);

export default InputCheckbox;
