import React from 'react';
import classNames from 'classnames';
import logoDark from './images/rs-logo-dark.svg';
import logoLight from './images/rs-logo-light.svg';
import useTheme from '../../hooks/use-theme';

const logoMapper = {
  light: logoDark,
  dark: logoLight,
};

const Logo: React.FC<React.HTMLAttributes<HTMLImageElement>> = (props) => {
  const { theme } = useTheme();
  const className = classNames('h-8', props.className);

  return (
    <img
      {...props}
      src={logoMapper[theme]}
      alt="Logo of BlastRadius"
      className={className}
    />
  );
};

export default Logo;
