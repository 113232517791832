import { destroyCookie } from 'nookies';
import { ApplicationRoutes } from '@customer-web-app/domains/shared/enums/application-routes';
import axios from 'axios';
import { User } from '@customer-web-app/domains/users/models/user';

async function logoutFromClient() {
  destroyCookie({}, 'token', {
    path: '/',
  });
  localStorage.removeItem('isTenantSelected');
  window.location.assign(ApplicationRoutes.Logout);
}

async function getLoggedUser(userAuthorization: string): Promise<User> {
  const { data } = await axios({
    baseURL: process.env.FRONTEGG_BR_API_DOMAIN,
    method: 'GET',
    url: '/identity/resources/users/v2/me',
    headers: {
      authorization: userAuthorization,
    },
  });

  return data;
}

async function isSuperUser(userAuthorization: string): Promise<boolean> {
  const user = await getLoggedUser(userAuthorization);

  return (
    user.superUser &&
    (user.email.endsWith('blastradius.ai') ||
      user.email.endsWith('radiantsecurity.ai'))
  );
}

const SessionService = {
  logoutFromClient,
  getLoggedUser,
  isSuperUser,
};

export default SessionService;
