import { Icon, LoaderSpinner, Text } from '@blastradius/ui';
import classNames from 'classnames';
import { TextSizes, TextTypes } from 'libs/ui/src/base-components/text';
import React from 'react';

type Props = {
  as?: 'a' | 'button';
  icon?: React.ComponentProps<typeof Icon>['name'];
  iconSize?: React.ComponentProps<typeof Icon>['size'];
  iconClassName?: string;
  loading?: boolean;
  disabled?: boolean;
  withHover?: boolean;
  fullWidth?: boolean;
  textSize?: TextSizes;
  textType?: TextTypes;
  textColor?: string;
  textClassName?: string;
};

const ButtonTransparent = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  Props &
    React.AnchorHTMLAttributes<HTMLAnchorElement> &
    React.ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      as = 'button',
      icon,
      iconSize = 16,
      iconClassName = '',
      loading,
      disabled,
      children,
      withHover = true,
      fullWidth = true,
      textType = 'body',
      textSize = 'small',
      textColor = '',
      textClassName = '',
      ...props
    },
    ref,
  ) => {
    const defaultClassName =
      'flex items-center rounded transition-all ease-in-out duration-200';
    const className = classNames(props.className, defaultClassName, {
      'pointer-events-none': disabled || loading,
      'hover:bg-gray-800/[.06] dark:hover:bg-white/[.06]': withHover,
      'w-full': fullWidth,
    });

    return React.createElement(
      as,
      {
        type: as === 'button' ? 'button' : undefined,
        ...props,
        className,
        ref,
      },
      <>
        {!loading ? (
          icon && (
            <Icon
              name={icon}
              size={iconSize}
              className={classNames('mr-4', iconClassName, {
                'opacity-50': disabled,
              })}
            />
          )
        ) : (
          <LoaderSpinner size="w-6 h-6" className="mr-4" />
        )}

        <Text
          type={textType}
          size={textSize}
          color={classNames(textClassName, textColor, {
            'text-gray-950 dark:text-white': !disabled && !textColor,
            'text-gray-500 dark:text-gray-700': disabled && !textColor,
          })}
        >
          {children}
        </Text>
      </>,
    );
  },
);

export default ButtonTransparent;
