import React from 'react';
import { FieldValues, RefCallBack, UseFormReturn } from 'react-hook-form';
import DatePicker from '../date-picker';
import InputText, { InputTextProps } from '../input-text';
import ButtonTransparent from '../button-transparent';
import { ReactDatePickerProps } from 'react-datepicker';
import { subDays, subYears, isSameDay, format } from 'date-fns';
import classNames from 'classnames';

const currentDate = new Date();

type TimeFrameOptions =
  | 'Today'
  | 'Last 7 Days'
  | 'Last 30 Days'
  | 'Last 90 Days'
  | 'Last Year';

const timeFrameMapper: Record<TimeFrameOptions, (date: Date) => Date> = {
  Today: (currentDate: Date) => currentDate,
  'Last 7 Days': (currentDate: Date) => subDays(currentDate, 7),
  'Last 30 Days': (currentDate: Date) => subDays(currentDate, 30),
  'Last 90 Days': (currentDate: Date) => subDays(currentDate, 90),
  'Last Year': (currentDate: Date) => subYears(currentDate, 1),
};

function getTimeFrameRange(dateRange: Date[]) {
  if (dateRange?.some((date) => !date)) {
    return null;
  }

  const [startDate, endDate] = dateRange;
  const currentDate = new Date();

  for (const frame of Object.keys(timeFrameMapper) as TimeFrameOptions[]) {
    const frameStartDate = timeFrameMapper[frame](currentDate);

    if (
      isSameDay(frameStartDate, startDate) &&
      isSameDay(currentDate, endDate)
    ) {
      return frame;
    }
  }

  return null;
}

type Props = {
  startDateName: string;
  endDateName: string;
  initialStartDate?: Date | string;
  initialEndDate?: Date | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, FieldValues>;
  initialTimeFrame?: TimeFrameOptions;
  inputClassName?: string;
  withError?: boolean;
  leftOffset?: string;
  inputTextClassName?: string;
};

type DatePickerTimeFrameProps = Partial<ReactDatePickerProps> & Props;

const DatePickerTimeFrameInput = React.forwardRef<
  RefCallBack,
  InputTextProps & {
    setIsOpen: (state: boolean) => void;
    dateRange: Date[];
    isOpen: boolean;
    inputClassName?: string;
    inputTextClassName?: string;
  } & React.InputHTMLAttributes<HTMLInputElement>
>(
  (
    {
      value,
      onClick,
      setIsOpen,
      dateRange,
      isOpen,
      inputClassName,
      inputTextClassName,
      ...props
    },
    ref,
  ) => {
    return (
      <InputText
        {...props}
        value={getTimeFrameRange(dateRange) || value}
        iconRight="carrot-down"
        onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
          setIsOpen(true);
          onClick?.(e);
        }}
        iconClassName={classNames({
          'rotate-180': isOpen,
        })}
        ref={ref}
        className={inputClassName}
        inputClassName={inputTextClassName}
      />
    );
  },
);

const DatePickerTimeFrame = ({
  startDateName,
  endDateName,
  initialStartDate,
  initialEndDate,
  form,
  initialTimeFrame,
  leftOffset = '-64px',
  inputClassName,
  inputTextClassName,
  withError,
  ...props
}: DatePickerTimeFrameProps) => {
  const { setValue } = form;

  const [timeFrame, setTimeFrame] = React.useState<
    TimeFrameOptions | undefined
  >();
  const [dateRange, setDateRange] = React.useState<Date[]>([]);
  const [startDate, endDate] = dateRange;

  const [isOpen, setIsOpen] = React.useState(false);

  function handleClickStartInput() {
    setIsOpen(true);
  }

  const timeFrameOptions: TimeFrameOptions[] = [
    'Today',
    'Last 7 Days',
    'Last 30 Days',
    'Last 90 Days',
    'Last Year',
  ];

  function closeDatePicker(e: MouseEvent) {
    const hasDatePickerInComposition = e
      .composedPath()
      .some((path) =>
        (path as HTMLElement)
          .getAttribute?.('class')
          ?.startsWith('react-datepicker'),
      );

    if (!hasDatePickerInComposition) {
      setIsOpen(false);
    }
  }

  React.useEffect(() => {
    document.addEventListener('click', closeDatePicker);

    return () => {
      document.removeEventListener('click', closeDatePicker);
    };
  }, []);

  React.useEffect(() => {
    if (dateRange?.every((date) => date)) {
      setIsOpen(false);
    }

    const formattedDates = dateRange?.map((date) =>
      date ? format(new Date(date), 'yyyy-MM-dd') : date,
    );

    const startDate = formattedDates?.[0];
    const endDate = formattedDates?.[1];

    if (startDate && endDate) {
      setValue(startDateName, startDate);
      setValue(endDateName, endDate);
    }
  }, dateRange);

  React.useEffect(() => {
    if (timeFrameOptions.includes(timeFrame as TimeFrameOptions)) {
      setDateRange([
        timeFrameMapper[timeFrame as TimeFrameOptions](currentDate),
        new Date(),
      ]);
    }
  }, [timeFrame]);

  React.useEffect(() => {
    if (timeFrameOptions.includes(initialTimeFrame as TimeFrameOptions)) {
      setDateRange([
        timeFrameMapper[initialTimeFrame as TimeFrameOptions](currentDate),
        new Date(),
      ]);
    }
  }, []);

  return (
    <DatePicker
      {...props}
      selectsRange
      open={isOpen}
      dateFormat={'MM/dd/yyyy'}
      startDate={startDate}
      endDate={endDate}
      onChange={(dates) => {
        setDateRange(dates as unknown as Date[]);
      }}
      customInput={
        <DatePickerTimeFrameInput
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onClick={handleClickStartInput}
          dateRange={dateRange}
          inputClassName={inputClassName}
          inputTextClassName={inputTextClassName}
        />
      }
      shouldCloseOnSelect
      maxDate={currentDate}
      openToDate={endDate || new Date()}
      className="absolute"
      calendarContainer={({ children }) => {
        return (
          <div
            className="react-datepicker__calendar-container"
            style={{
              position: 'absolute',
              top: '100%',
              left: leftOffset,
            }}
          >
            {children as React.ReactNode}
          </div>
        );
      }}
    >
      <div className="flex flex-col gap-2">
        {timeFrameOptions.map((timeFrameOption) => (
          <ButtonTransparent
            key={`${timeFrameOption}-date-picker`}
            className={classNames('pl-4 h-10', {
              'bg-gray-800/[.06] dark:bg-white/[.06]':
                getTimeFrameRange(dateRange) === timeFrameOption,
            })}
            onClick={() => {
              setTimeFrame(timeFrameOption);
              setIsOpen(false);
            }}
          >
            {timeFrameOption}
          </ButtonTransparent>
        ))}
      </div>
    </DatePicker>
  );
};

export default DatePickerTimeFrame;
