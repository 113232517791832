import React from 'react';
import {
  GlobalStoreContext,
  GlobalStoreState as GlobalStoreStateFromContext,
} from '@customer-web-app/domains/shared/providers/global-store-provider';

export type GlobalStoreState = GlobalStoreStateFromContext;

function useGlobalStore() {
  const { globalStoreState, setGlobalStoreState } =
    React.useContext(GlobalStoreContext);

  return {
    globalStore: globalStoreState,
    setGlobalStore: setGlobalStoreState,
  };
}

export default useGlobalStore;
