import { SearchFilterParams } from '@customer-web-app/domains/search/services/search-filter-service';
import {
  AlertStatuses,
  AlertTypes,
} from '@customer-web-app/domains/alerts/models/alert';

type Params = {
  filters?: SearchFilterParams['alertFilter'];
  internalFilters?: SearchFilterParams['alertFilter'];
};

const defaultParamValues: Params = {
  filters: {},
  internalFilters: {},
};

export default function getInternalReviewMainScreen(params?: Params) {
  const { filters, internalFilters } = {
    ...defaultParamValues,
    ...params,
  };

  const alertsFilters = {
    alertStatus: [
      AlertStatuses.Incident,
      AlertStatuses.Falsified,
      AlertStatuses.Pending,
      AlertStatuses.Enriched,
    ],
    alertType: [
      AlertTypes.Phishing,
      AlertTypes.Endpoint,
      AlertTypes.Identity,
      AlertTypes.Network,
      AlertTypes.Cloud,
      AlertTypes.Generic,
    ],
    alertPriority: ['low', 'medium', 'high'],
    ...filters,
    ...internalFilters,
  };

  return `/admin/internal/internal-review?filter=${encodeURIComponent(
    JSON.stringify({
      globalFilter: { entities: ['Alert'] },
      alertFilter: {
        ...alertsFilters,
      },
    }),
  )}`;
}
