import React from 'react';
import InputTextMask from 'react-input-mask';
import InputText, { InputTextProps } from '../input-text';

type Masks = 'date' | 'cost';

type Props = {
  mask: Masks;
} & InputTextProps;

const patternsMapper: {
  [key in Masks]: string;
} = {
  date: '99/99/9999',
  cost: '999,999',
};

const InputMask = React.forwardRef<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  Props & React.InputHTMLAttributes<HTMLInputElement>
>(({ mask, ...props }, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <InputTextMask
      {...{ maskChar: null }}
      mask={patternsMapper[mask]}
      onChange={props.onChange}
      onBlur={props.onBlur}
      value={props.value}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {() => <InputText ref={ref} {...props} />}
    </InputTextMask>
  );
});

export default InputMask;
