import React from 'react';
import Dialog from '../dialog';
import Button from '../../base-components/button';
import Text from '../../base-components/text';
import ButtonGhost from '../../base-components/button-ghost';
import { CloseConfirmType, CloseConfirmButtonType } from '.';

type ButtonProps = {
  option: CloseConfirmButtonType;
};

function DrawerCloseConfirmButton({
  option,
}: ButtonProps & React.HTMLAttributes<HTMLButtonElement>) {
  const [isSaving, setIsSaving] = React.useState(false);

  return (
    <Button
      size="small"
      key={option.label}
      color="red"
      acting={isSaving}
      onClick={async () => {
        setIsSaving(true);
        try {
          await option.onClick();
        } catch {
          // Ignore the error
        } finally {
          setIsSaving(false);
        }
      }}
    >
      {option.label}
    </Button>
  );
}

function DrawerCloseConfirmDialog({
  dialogTitle,
  dialogDescription,
  options,
}: CloseConfirmType & React.HTMLAttributes<HTMLElement>) {
  return (
    <Dialog data-testid="drawer-close-confirm-dialog">
      <div className="h-full p-6 flex flex-col items-center justify-between">
        <header className="grid gap-24">
          <Dialog.Deactivate className="justify-self-end" />

          <div className="flex flex-col items-center gap-4 px-4">
            <Text type="heading" size="regular">
              {dialogTitle}
            </Text>
            <Text
              type="heading"
              size="x-small"
              color="text-gray-300"
              className="text-center"
            >
              {dialogDescription}
            </Text>
          </div>
        </header>
        <div className="grid grid-cols-[13.813rem_13.813rem] gap-4">
          {options?.map((option, index) =>
            index === 0 ? (
              <ButtonGhost
                size="small"
                key={option.label}
                onClick={option.onClick}
              >
                {option.label}
              </ButtonGhost>
            ) : (
              <DrawerCloseConfirmButton option={option} />
            ),
          )}
        </div>
      </div>
    </Dialog>
  );
}

export default DrawerCloseConfirmDialog;
