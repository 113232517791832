import { Card } from '@blastradius/ui';
import useAuthentication from '@customer-web-app/domains/authentication/hooks/use-authentication';
import classNames from 'classnames';
import React from 'react';

type Props = {
  visible?: boolean;
  isMainNavigatorOpen: boolean;
};

function MainSideNavigatorDropdown({
  children,
  visible = false,
  isMainNavigatorOpen,
}: Props & React.HTMLAttributes<HTMLDivElement>) {
  const { isSuperUser } = useAuthentication();

  return (
    visible && (
      <Card
        background="bg-transparent"
        position="absolute"
        className={classNames('z-40 !bg-transparent pl-2', {
          '-top-0': !isSuperUser,
          '-top-[6.5rem]': isSuperUser,
          'left-[244px]': isMainNavigatorOpen,
          'left-[64px]': !isMainNavigatorOpen,
        })}
      >
        <div className="bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-800 rounded">
          <ul className="flex flex-col gap-1 p-2 w-60">{children}</ul>
        </div>
      </Card>
    )
  );
}

export default MainSideNavigatorDropdown;
