import React from 'react';
import classNames from 'classnames';

type Props = {
  color: 'red' | 'green' | 'white' | 'blue' | 'gray';
  children?: React.ReactNode;
};

const Bullet: React.FC<Props & React.HTMLAttributes<HTMLElement>> = ({
  color,
  children,
  ...props
}) => {
  const bulletClassName = classNames('rounded-full w-1.5 h-1.5', {
    'bg-gradient-to-br from-red-500 to-pink-500': color === 'red',
    'bg-green-500': color === 'green',
    'bg-white': color === 'white',
    'bg-blue-600 dark:bg-blue-500': color === 'blue',
    'bg-gray-600 dark:bg-gray-500': color === 'gray',
  });

  return (
    <div
      {...props}
      className={classNames(
        props.className,
        'inline-flex gap-1 items-center justify-center',
      )}
    >
      <div className={bulletClassName} data-testid="bullet" />
      {children ? (
        <div className="-mt-0.5" data-testid="children">
          {children}
        </div>
      ) : null}
    </div>
  );
};

export default Bullet;
