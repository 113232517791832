import { ButtonIcon, ButtonTransparent, Icon, Text } from '@blastradius/ui';
import useToast from '@blastradius/ui/hooks/use-toast';
import { Icons } from '@blastradius/ui/icons';
import getAlertsMainScreen from '@customer-web-app/domains/alerts/services/get-alerts-main-screen';
import useIncidentsCounters from '@customer-web-app/domains/incidents/hooks/use-incidents-counters';
import getIncidentsMainScreen from '@customer-web-app/domains/incidents/services/get-incidents-main-screen';
import getSettingsMainScreen from '@customer-web-app/domains/settings/services/get-settings-main-screen';
import Container from '@customer-web-app/domains/shared/components/container';
import StickyHeaderWrapper from '@customer-web-app/domains/shared/components/sticky-header-wrapper';
import useFeatureFlags from '@customer-web-app/domains/shared/hooks/use-feature-flags';
import { NewFeaturesContext } from '@customer-web-app/domains/shared/providers/new-features-provider';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import styles from './main-side-navigation.module.scss';

const MainSideNavigation: React.FC<React.HTMLAttributes<HTMLElement>> = (
  props,
) => {
  const { liveIncidentsCount } = useIncidentsCounters();
  const flags = useFeatureFlags();
  const { toast, notification } = useToast();
  const { setNewFeatureAsViewed } = React.useContext(NewFeaturesContext);
  const [isShowingMenu, setIsShowingMenu] = React.useState(false);

  const router = useRouter();
  useEffect(() => {
    if (!isShowingMenu)
      notification({
        duration: 9999999, // forever
        message: `Radiant is optimized for desktop use only`,
        position: 'bottom-center',
        options: [{ type: 'dismiss' }],
      });

    return () => {
      toast.dismiss();
    };
  }, [isShowingMenu]);

  const navigationRoutes = {
    incidentsPage: getIncidentsMainScreen({
      hasLiveIncidents: liveIncidentsCount > 0,
      useRedirectRoute: router.pathname === '/incidents',
      enableEndpointAlerts: flags.alertsEnableEndpointAlerts as boolean,
      enableIdentityAlerts: flags.alertsEnableIdentityAlerts as boolean,
      enableNetworkAlerts: flags.alertsEnableNetworkAlerts as boolean,
      enableGenericAlerts:
        flags.alertsEnableTriageRequiredAlertStatus as boolean,
    }),
    alertsPage: getAlertsMainScreen({
      enableEndpointAlerts: flags.alertsEnableEndpointAlerts as boolean,
      enableIdentityAlerts: flags.alertsEnableIdentityAlerts as boolean,
      enableNetworkAlerts: flags.alertsEnableNetworkAlerts as boolean,
      enableCloudAlerts: flags.alertsEnableCloudAlerts as boolean,
      enableGenericAlerts:
        flags.alertsEnableTriageRequiredAlertStatus as boolean,
      enablePriorityFilter: flags.alertsEnablePriorityFilter as boolean,
    }),
    settingsPage: getSettingsMainScreen(flags),
    searchPage: `/search?filter=${encodeURIComponent(
      JSON.stringify({
        globalFilter: { keyword: '' },
      }),
    )}`,
    workflowsPage: '/workflows',
  };

  return (
    <>
      <StickyHeaderWrapper className={`z-70 !top-0 w-full`}>
        <header
          {...props}
          className={props.className}
          data-testid="main-side-navigation-mobile"
        >
          <Container
            className={`h-12 flex flex-row justify-between ${styles.bottomShadow} items-center`}
            width="w-full"
            data-testid="main-side-navigation-container"
          >
            <Link
              href="/"
              className="py-3 pl-3 w-12 h-12 bg-pink-500 rounded-br-3xl mb-[1px] relative"
            >
              <Icon
                name="blastradius"
                size={24}
                className="!fill-white -mt-1"
              />
            </Link>
            <ButtonIcon
              icon="menu-hamburger"
              iconSize={24}
              onClick={() => setIsShowingMenu((current) => !current)}
              className="mr-8 h-8 w-8"
              iconClassName="fill-black dark:fill-white w-6 h-6"
            />
          </Container>
        </header>
      </StickyHeaderWrapper>

      <StickyHeaderWrapper
        className={classNames(
          'z-[999] !absolute !block overflow-hidden transition-all duration-500 ease-in-out w-full bg-gray-50 dark:bg-gray-950',
          {
            'h-screen': isShowingMenu,
            'h-0 ': !isShowingMenu,
          },
        )}
      >
        <div className="flex justify-between flex-col h-screen">
          <Container
            className=" transition-all duration-500 ease-in-out flex !flex-col justify-start items-stretch gap-4 px-8 bottom-shadow"
            width={classNames('w-screen', {
              'mt-6': isShowingMenu,
              'h-0': !isShowingMenu,
            })}
            data-testid="main-side-navigation-container"
          >
            {[
              {
                link: navigationRoutes.incidentsPage,
                label: 'Incidents',
                icon: 'incidents' as Icons,
                enabled: true,
              },
              {
                link: navigationRoutes.alertsPage,
                label: 'Alerts',
                icon: 'alert' as Icons,
                enabled: true,
              },
              {
                link: navigationRoutes.workflowsPage,
                label: 'Workflows',
                icon: 'workflows' as Icons,
                enabled: flags.workflowsEnablePage,
              },
              {
                link: navigationRoutes.workflowsPage,
                label: 'Notebooks',
                icon: 'notebook' as Icons,
                enabled: flags.notebooksEnablePage,
              },
              {
                link: navigationRoutes.searchPage,
                label: 'Search',
                icon: 'search' as Icons,
                enabled: true,
              },
              {
                link: navigationRoutes.settingsPage,
                label: 'Settings',
                icon: 'settings' as Icons,
                enabled: true,
              },
            ].map(({ link, label, icon, enabled }) => {
              return (
                enabled && (
                  <Link
                    href={link}
                    key={link}
                    onClick={() => {
                      setIsShowingMenu(false);
                      setNewFeatureAsViewed({
                        features: [`main-side-navigation-mobile-${icon}`],
                      });
                    }}
                  >
                    <ButtonTransparent
                      className="h-12 flex p-2"
                      icon={icon}
                      iconSize={32}
                      textSize="x-large"
                      iconClassName="fill-black dark:fill-white"
                    >
                      {label}
                    </ButtonTransparent>
                  </Link>
                )
              );
            })}
          </Container>
          <Text
            color="text-gray-400 dark:text-gray-500"
            className={classNames(
              'absolute bottom-20 left-0 right-0 text-center transition-all duration-500 ease-in-out',
              {
                'opacity-100': isShowingMenu,
                'opacity-0': !isShowingMenu,
              },
            )}
            as="div"
            type="body"
            size="large"
          >
            Radiant is optimized for desktop use only
          </Text>
        </div>
      </StickyHeaderWrapper>
    </>
  );
};

export default MainSideNavigation;
