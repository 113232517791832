import React from 'react';
import { Themes } from '@blastradius/ui/themes';
import colorPalette from '@blastradius/ui/colors';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import useTheme from '@blastradius/ui/hooks/use-theme';

const themes: {
  [key in Themes]: { baseColor: string; highlightColor: string };
} = {
  dark: {
    baseColor: colorPalette.gray['800'],
    highlightColor: colorPalette.gray['700'],
  },
  light: {
    baseColor: colorPalette.gray['200'],
    highlightColor: colorPalette.gray['100'],
  },
};

const SkeletonLoaderProvider: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
}) => {
  const { theme } = useTheme();

  return <SkeletonTheme {...themes[theme]}>{children}</SkeletonTheme>;
};

export default SkeletonLoaderProvider;
