import React from 'react';
import { ReactComponent as EmptyStateVector } from './assets/empty-state-vector.svg';
import { Text } from '@blastradius/ui';
import classNames from 'classnames';

type Props = {
  height?: string;
};

const EmptyStateScreen: React.FC<Props & React.HTMLAttributes<HTMLElement>> = ({
  children,
  height = 'h-[80vh]',
  ...props
}) => {
  const defaultClassName = 'flex items-center justify-center px-4 relative';
  const className = classNames(defaultClassName, height);

  return (
    <div className={className} {...props}>
      <EmptyStateVector className="fill-gray-100 dark:fill-gray-900 absolute" />
      <div className="flex justify-center items-center w-[31rem] h-16  relative z-10">
        <div className="w-1/4 h-full absolute top-0 bg-gray-50 dark:bg-gray-950" />
        <div className="w-72 dark:w-full h-full absolute top-0 bg-gray-50 dark:bg-gray-950 blur-xl z-10" />
        <Text type="heading" color="text-gray-500" className="relative z-20">
          {children}
        </Text>
      </div>
    </div>
  );
};

export default EmptyStateScreen;
