const { join } = require('path');
const config = require('../../tailwind.workspace-preset');

module.exports = {
  ...config,
  content: [
    join(
      __dirname,
      '{src,pages,components,app}/**/*!(*.stories|*.spec).{ts,tsx,html}',
    ),
  ],
};
