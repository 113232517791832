import { api } from '@customer-web-app/domains/shared/api';

export const internalFetcher = api({ baseURL: '/' });
export const fronteggFetcher = api({
  baseURL: process.env.NEXT_PUBLIC_FRONTEGG_DOMAIN,
});
export const bffFetcher = api({
  baseURL: process.env.NEXT_PUBLIC_BFF_API_DOMAIN,
});

const FetchersService = {
  internalFetcher,
  fronteggFetcher,
  bffFetcher,
};

export default FetchersService;
