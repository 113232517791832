import classNames from 'classnames';
import React from 'react';
import Bullet from '../bullet';
import LoaderSpinner from '../loader-spinner';
import Text from '../text';

type Colors = 'green' | 'gray' | 'red';

type Props = {
  color: Colors;
  loading?: boolean;
};

const backgroundColorMapper: {
  [key in Colors]: string;
} = {
  gray: 'bg-gray-500/[.12] dark:bg-gray-500/[.1]',
  green: 'bg-green-600/[.1] dark:bg-green-500/[.1]',
  red: 'bg-pink-500/[.08] dark:bg-pink-500/[.1]',
};

const BulletPill: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  color,
  loading,
  children,
  ...props
}) => {
  const defaultClassName = 'h-5 px-2 flex items-center gap-2 rounded-sm w-fit';

  const className = classNames(defaultClassName, props.className, [
    backgroundColorMapper[color],
  ]);

  return (
    <div {...props} className={className}>
      {!loading ? (
        <Bullet color={color} />
      ) : (
        <LoaderSpinner color={color} size="w-2.5 h-2.5" />
      )}

      <Text type="body" size="small">
        {children}
      </Text>
    </div>
  );
};

export default BulletPill;
