import useAuthentication from '@customer-web-app/domains/authentication/hooks/use-authentication';
import useTenants from '@customer-web-app/domains/tenants/hooks/use-tenants';
import { Tenant } from '@customer-web-app/domains/tenants/models/tenant';
import orderBy from 'lodash/orderBy';
import React from 'react';
import SwitchTenantListItem from '@customer-web-app/domains/tenants/components/switch-tenant-list-item';
import classNames from 'classnames';
import { EmptyState, InputText } from '@blastradius/ui';
import useArrowsNavigation from '@blastradius/ui/hooks/use-arrows-navigation';

type Props = {
  currentTenant?: Tenant;
};

const SwitchTenantsList: React.FC<
  Props & React.HTMLAttributes<HTMLDivElement>
> = ({ currentTenant }) => {
  const tenantListWrapperClassName = classNames(
    'w-[31rem] pb-2 mt-4 overflow-y-auto',
    {
      'h-[calc(100%-14.438rem)]': currentTenant,
      'h-[calc(100%-4.75rem)]': !currentTenant,
    },
  );

  const { updateCurrentTenant, isRequesting, accessibleTenants, isSuperUser } =
    useAuthentication();

  const { tenants, form } = useTenants();

  const switchTenantOptions = orderBy(
    tenants?.filter(
      (tenant) =>
        tenant?.tenantId !== currentTenant?.tenantId &&
        (isSuperUser || accessibleTenants.includes(tenant?.tenantId)),
    ),
    ['name'],
  );

  async function onEnterPress(selectedElement: React.RefObject<HTMLElement>) {
    const newTenantId = selectedElement?.current?.id;

    if (newTenantId) {
      const newTenant = switchTenantOptions?.find(
        (tenant) => tenant.tenantId === newTenantId,
      );

      await updateCurrentTenant(newTenant);
    }
  }

  const { currentSelection, selectedRef } = useArrowsNavigation({
    selector: '#switch-tenant-wrapper',
    listLength: switchTenantOptions?.length || 0,
    onEnterPress,
    disabled: !!isRequesting,
  });

  React.useEffect(() => {
    setTimeout(() => {
      form.setFocus('search');
    }, 300);
  }, []);

  const className = classNames('flex flex-col gap-3 w-[29.5rem] pb-2', {
    'pointer-events-none': isRequesting,
  });

  const hasSwitchTenantOptions = switchTenantOptions?.length > 0;

  return (
    <>
      <InputText
        iconLeft="search"
        placeholder={`Search ${switchTenantOptions?.length} Available Tenants`}
        className="w-[29.5rem] my-4"
        {...form.register('search')}
        autoFocus
      />

      {hasSwitchTenantOptions && (
        <div
          data-testid="tenants-list-wrapper"
          className={tenantListWrapperClassName}
        >
          <ul className={className} data-testid="tenants-list">
            {switchTenantOptions?.map((tenant, index) => {
              const isTenantSelected = currentSelection === index;

              return (
                <SwitchTenantListItem
                  key={tenant.tenantId}
                  id={tenant.tenantId}
                  value={tenant.name}
                  onClick={async () => {
                    await updateCurrentTenant(tenant);
                  }}
                  acting={isRequesting === tenant?.tenantId}
                  selected={isTenantSelected}
                  ref={isTenantSelected ? selectedRef : null}
                />
              );
            })}
          </ul>
        </div>
      )}

      {!hasSwitchTenantOptions && (
        <EmptyState
          title="Your search returned no results"
          subTitle="Try adjusting the term to find what you're looking for."
          className="mt-4"
        />
      )}
    </>
  );
};

export default SwitchTenantsList;
