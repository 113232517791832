import React from 'react';
import { Root, createRoot } from 'react-dom/client';

export default function ShadowDom({ children }: { children: React.ReactNode }) {
  const ref = React.useRef<HTMLDivElement>(null);
  const [shadowRoot, setShadowRoot] = React.useState<Root | null>(null);

  React.useEffect(() => {
    if (ref.current) {
      const shadowRootInstance = ref.current.attachShadow({ mode: 'open' });

      setShadowRoot(createRoot(shadowRootInstance as ShadowRoot));
    }
  }, [ref]);

  return (
    <div ref={ref}>
      {shadowRoot ? <>{shadowRoot.render(children)}</> : null}
    </div>
  );
}
