import React from 'react';
import { format } from 'date-fns';

type DateFormatPattens = 'simple' | 'long' | 'longWithAt' | 'email';

const dateFormatPatternsMapper: { [key in DateFormatPattens]: string } = {
  simple: 'MM/dd/yyyy',
  long: 'MM/dd/yyyy h:mmaaa',
  longWithAt: "MM/dd/yy 'at' h:mmaaa",
  email: "EEEE, LLLL dd, yyyy 'at' h:mm aa",
};

type Props = {
  value: Date | string;
  pattern: DateFormatPattens;
  fallback?: string;
};

export function dateFormat({ value, pattern, fallback = '-' }: Props) {
  return value != null
    ? format(new Date(value), dateFormatPatternsMapper[pattern])
    : fallback;
}

const DateFormat: React.FC<Props> = (props) => {
  const date = React.useMemo(() => dateFormat(props), [props]);

  return <>{date}</>;
};

export default DateFormat;
