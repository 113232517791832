import React from 'react';
import useAuthentication from '@customer-web-app/domains/authentication/hooks/use-authentication';

function AuthenticatedRoutesWrapper({ children }) {
  const { user, redirectUserByAuthentication } = useAuthentication();

  React.useEffect(() => {
    redirectUserByAuthentication();
  }, [user]);

  return user?.id ? children : <></>;
}

export default AuthenticatedRoutesWrapper;
