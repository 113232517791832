import useFeatureFlags from '@customer-web-app/domains/shared/hooks/use-feature-flags';
import React from 'react';
import useAuthentication from '@customer-web-app/domains/authentication/hooks/use-authentication';
import useLocalStorage from '@customer-web-app/domains/shared/hooks/use-local-storage';
import { NewFeature } from '@customer-web-app/domains/shared/providers/feature-flags-provider';
import { differenceInDays, isBefore, isSameDay } from 'date-fns';
import { delay } from '@customer-web-app/domains/shared/services/delay';

type Feature = {
  [key: string]: boolean;
};

interface INewFeaturesContext {
  newFeatures: Feature;
  setNewFeatureAsViewed: ({ features }: { features: string[] }) => void;
}

function isSameOrBefore(releaseDate: string, userCreatedDate: Date) {
  return (
    isSameDay(new Date(releaseDate), new Date(userCreatedDate)) ||
    isBefore(new Date(releaseDate), new Date(userCreatedDate))
  );
}

export const NewFeaturesContext = React.createContext<INewFeaturesContext>({
  newFeatures: {},
  setNewFeatureAsViewed: () => void 0,
});

const NewFeaturesProvider: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
}) => {
  const { generalTagNewFeatures } = useFeatureFlags();
  const newFeaturesList = (generalTagNewFeatures as NewFeature[]) || [];

  const { user } = useAuthentication();

  const [newFeatures, setNewFeatures] = useLocalStorage(
    `new_features_${user?.id}`,
    newFeaturesList?.reduce((features, newFeature) => {
      const newStatusExpired =
        differenceInDays(new Date(), new Date(newFeature.releaseDate)) >
        (newFeature.expirationDays || 7);

      const isFirstTimeLoggingIn = isSameOrBefore(
        newFeature.releaseDate,
        user?.createdAt,
      );

      const newFeatureOnStorage = JSON.parse(
        localStorage.getItem(`new_features_${user?.id}`) || '{}',
      )[newFeature.feature];

      return {
        ...features,
        [newFeature.feature]:
          newFeatureOnStorage === false
            ? false
            : !isFirstTimeLoggingIn && !newStatusExpired,
      };
    }, {}),
    { updateInitialValueOnLoad: true },
  );

  async function setNewFeatureAsViewed({ features }: { features: string[] }) {
    await delay(50);

    if (newFeaturesList.length) {
      setNewFeatures({
        ...newFeatures,
        ...features
          .filter((item) => newFeatures[item])
          .reduce(
            (cur, updatedFeature) => ({
              ...cur,
              [updatedFeature]: false,
            }),
            {},
          ),
      });
    }
  }

  return (
    <NewFeaturesContext.Provider
      value={{
        newFeatures,
        setNewFeatureAsViewed,
      }}
    >
      {children}
    </NewFeaturesContext.Provider>
  );
};

export default NewFeaturesProvider;
