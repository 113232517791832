import { ConnectorVendorCodes } from '@customer-web-app/domains/shared/enums/connector-vendor-codes';
import { IncidentStep } from '@customer-web-app/domains/incidents/models/incident-step';
import { Alert } from '@customer-web-app/domains/alerts/models/alert';

type IncidentStepCount = {
  done: number;
  total: number;
  created?: number;
  inprogress?: number;
};

export type IncidentSteps = IncidentStatuses | 'remediated';

export enum IncidentTypes {
  Phishing = 'phishing',
  BEC = 'bec',
  Malware = 'malware',
  Endpoint = 'endpoint',
  Identity = 'identity',
  Network = 'network',
  Generic = 'generic',
}

export enum IncidentStatuses {
  Opened = 'opened',
  Closed = 'closed',
  Pending = 'pending',
  Remediated = 'remediated',
}

export enum IncidentInvestigationErrors {
  NotEnoughData = 'notEnoughData',
  EmailPrecedesConnector = 'emailPrecedesConnector',
}

export interface Incident {
  id: string;
  incidentNumber: string;
  incidentName: string;
  incidentStatus: IncidentStatuses;
  incidentType: IncidentTypes;
  isLive?: boolean;
  exposureStartedOn: Date;
  exposureEndedOn: Date;
  exposureDurationMinutes: number;
  createdOn: Date;
  assignedTo?: string;
  closedBy?: string;
  closedOn?: Date;
  closedAsFalsePositive?: boolean;
  alertsCount: number;
  iamIdentitiesCount?: number;
  devicesCount?: number;
  applicationsCount?: number;
  dataObjectsCount?: number;
  steps?: IncidentStep[];
  stepsCount?: {
    triage?: IncidentStepCount;
    investigation: IncidentStepCount;
    containment: IncidentStepCount;
    remediation: IncidentStepCount;
  };
  alert?: Alert;
  alerts: {
    alertID: string;
    alertNumber: string;
    alertName: string;
    alertCreatedOn: Date;
    alertConnectorVendorCode: ConnectorVendorCodes;
  }[];
  isEmailIncident?: boolean;
  investigationError?: {
    code: IncidentInvestigationErrors;
    message?: string;
  };
}
