import classNames from 'classnames';
import { Dropdown, Icon, InputText, Text } from '../../index';
import { Children, useState } from 'react';

type HeaderProps = {
  children: (search: string) => React.ReactElement;
  placeholder?: string;
};
function DropdownSearchHeader({
  children,
  placeholder,
  ...props
}: HeaderProps) {
  const [search, setSearch] = useState('');

  return (
    <>
      <Dropdown.Header onClickOutside={() => setSearch('')} {...props}>
        <InputText
          className="m-2"
          iconLeft="search"
          type="text"
          value={search}
          handleClearValue={() => {
            setSearch('');
          }}
          placeholder={placeholder}
          onChange={(e: { target: { value: string } }) =>
            setSearch(e.target.value)
          }
        />
      </Dropdown.Header>
      {children(search)}
    </>
  );
}
function EmptyState({
  label,
  description,
}: {
  label: string;
  description: string;
}) {
  return (
    <div className="flex flex-col items-center justify-center gap-6 p-6 pt-12 pb-12">
      <Icon name="search" size={56} />
      <div className="flex flex-col items-center justify-center gap-2">
        <Text type="heading" size="x-small" className="text-center">
          {label}
        </Text>
        <Text
          type="body"
          size="regular"
          className="text-center"
          color="text-gray-400 dark:text-gray-400"
        >
          {description}
        </Text>
      </div>
    </div>
  );
}
type ItemsProps = {
  children: React.ReactElement[];
  emptyStateLabel?: string;
  emptyStateDescription?: string;
};

function Items({
  emptyStateLabel = 'There were no items found.',
  emptyStateDescription = 'You may want to try using different keywords or checking for typos',
  children,
  ...props
}: ItemsProps & React.HTMLAttributes<HTMLUListElement>) {
  if (!Children.count(children)) {
    return (
      <EmptyState label={emptyStateLabel} description={emptyStateDescription} />
    );
  }
  return (
    <Dropdown.Items
      {...props}
      className={classNames(
        props.className,
        'max-h-72 overflow-y-auto overflow-x-hidden pb-2 pt-2 px-2',
      )}
    >
      {children}
    </Dropdown.Items>
  );
}

function Content(props: React.HTMLAttributes<HTMLDivElement>) {
  const className = classNames('-right-16 w-72 z-50', props.className);

  return <Dropdown.Content {...props} className={className} />;
}
type DropdownProps = {
  open?: boolean;
};

function DropdownSearch({
  children,
  ...props
}: DropdownProps & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <Dropdown {...props} closeOnAnyClick={false}>
      {children}
    </Dropdown>
  );
}
DropdownSearch.Header = DropdownSearchHeader;
DropdownSearch.EmptyState = EmptyState;
DropdownSearch.Items = Items;
DropdownSearch.Content = Content;

// default to dropdown for the following ones:
DropdownSearch.ItemSeparator = Dropdown?.ItemSeparator;
DropdownSearch.Activator = Dropdown?.Activator;
DropdownSearch.Item = Dropdown?.Item;

export default DropdownSearch;
