import { gql, useQuery } from '@apollo/client';

type UseIncidentsCountersResult = {
  alertsCount: number;
  incidentsCount: number;
  openedIncidentsCount: number;
  liveIncidentsCount: number;
  closedIncidentsCount: number;
  isLoadingIncidentsCounters: boolean;
  incidentsCountersError: unknown;
};

interface IncidentsCountResponse {
  counts: {
    incidentCount: {
      total: number;
      opened: number;
      live: number;
      closed: number;
    };
    alertCount: {
      falsified: number;
      incident: number;
      total: number;
    };
  };
}

export const GET_INCIDENTS_COUNTERS_QUERY = gql`
  query GetIncidentsCounters {
    counts {
      incidentCount {
        closed
        live
        opened
        total
      }
      alertCount {
        total
      }
    }
  }
`;

function useIncidentsCounters(): UseIncidentsCountersResult {
  const { data, error, loading } = useQuery<IncidentsCountResponse>(
    GET_INCIDENTS_COUNTERS_QUERY,
    {
      fetchPolicy:
        process.env.NEXT_PUBLIC_ENVIRONMENT === 'test'
          ? 'no-cache'
          : /* istanbul ignore next */
            'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const incidentsCountersResults = data?.counts?.incidentCount;

  return {
    alertsCount: data?.counts?.alertCount?.total || 0,
    incidentsCount: incidentsCountersResults?.total || 0,
    openedIncidentsCount: incidentsCountersResults?.opened || 0,
    liveIncidentsCount: incidentsCountersResults?.live || 0,
    closedIncidentsCount: incidentsCountersResults?.closed || 0,
    isLoadingIncidentsCounters: loading,
    incidentsCountersError: error,
  };
}

export default useIncidentsCounters;
