import React from 'react';
import classNames from 'classnames';
import { Text, Icon } from '@blastradius/ui';

type InputMessageStates = 'info' | 'success' | 'error';

type Props = {
  state: InputMessageStates;
  children: React.ReactNode;
};

const iconMapper: {
  [key in InputMessageStates]: {
    icon: React.ComponentProps<typeof Icon>['name'];
    fill?: string;
  };
} = {
  info: {
    icon: 'circle-info',
  },
  success: {
    icon: 'circle-check',
    fill: 'fill-green-500',
  },
  error: {
    icon: 'circle-warning',
    fill: 'fill-pink-500',
  },
};

const InputMessage: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  state,
  children,
  ...props
}) => {
  const className = classNames('flex items-center gap-2', props.className);

  return (
    <div {...props} className={className}>
      <Icon
        name={iconMapper[state].icon}
        className={iconMapper[state].fill}
        size={16}
      />
      <Text type="body" size="x-small">
        {children}
      </Text>
    </div>
  );
};

export default InputMessage;
