import React from 'react';
import useSWR from 'swr';
import { Connector } from '@customer-web-app/domains/settings-services/models/connector';
import { ConnectorClasses } from '@customer-web-app/domains/settings-services/enums/connector-classes';
import { Integration } from '@customer-web-app/domains/settings-services/models/integration';
import orderBy from 'lodash/orderBy';
import normalizeString from '@customer-web-app/domains/shared/services/normalize-string';
import { UseFormReturn } from 'react-hook-form';
import useCredentialsList from '@customer-web-app/domains/settings-services/hooks/use-credentials-list';
import useAuthentication from '@customer-web-app/domains/authentication/hooks/use-authentication';

interface ConnectorsResponse {
  data: {
    items: Connector[];
  };
}

type UseConnectorsIntegrationsParams = {
  connectorClass: ConnectorClasses;
  form?: UseFormReturn<{ search: string }>;
  onlyRegistered?: boolean;
};

export type ConnectorVendor = {
  connectorIntegrationType: Connector['connectorIntegrationType'];
  integration: Integration;
};

function useConnectorsIntegrations({
  connectorClass,
  form,
  onlyRegistered,
}: UseConnectorsIntegrationsParams) {
  const { user } = useAuthentication();

  const { credentialsList, isCredentialsListLoading } = useCredentialsList();

  const { data, error } = useSWR<ConnectorsResponse>(
    user?.id && '/connectors/list',
  );

  const connectors = (data?.data?.items || []).filter(
    (connector) =>
      connector.connectorClass === connectorClass && connector.enabled,
  );

  const searchValue = form?.watch('search');

  // Integrations Sort
  const [connectorsIntegrationsSort, setConnectorsIntegrationsSort] =
    React.useState<'asc' | 'desc'>('asc');

  // Group the list of connectors by integration type
  const connectorsIntegrations = React.useMemo(() => {
    let vendors: ConnectorVendor[] = [];

    if (connectors?.length > 0 && credentialsList?.length > 0) {
      for (const connector of connectors) {
        const vendorCreated = vendors.find(
          (vendor) =>
            vendor.connectorIntegrationType ===
            connector.connectorIntegrationType,
        );

        if (vendorCreated) {
          continue;
        }

        vendors.push({
          connectorIntegrationType: connector.connectorIntegrationType,
          integration: credentialsList.find(
            (credential) => credential.id === connector.connectorIntegrationID,
          ),
        });
      }

      if (form) {
        if (onlyRegistered) {
          vendors = vendors.filter((vendor) => vendor.integration);
        }

        vendors = vendors.filter(({ integration }) => {
          const normalizedSearchValue = normalizeString(searchValue);

          return normalizeString(integration?.integrationName || '').includes(
            normalizedSearchValue,
          );
        });
      }

      vendors = orderBy(
        vendors,
        ['integration.integrationName'],
        [connectorsIntegrationsSort],
      );
    }

    return vendors;
  }, [connectors, credentialsList, searchValue]);

  // Integrations Search
  function handleClearSearch() {
    form.setValue('search', '');
  }

  return {
    connectorsIntegrations,
    isConnectorsIntegrationsLoading:
      (!data && !error) || isCredentialsListLoading,
    connectorsIntegrationsError: error,
    connectorsIntegrationsSearchValue: searchValue,
    connectorsIntegrationsSort,
    clearConnectorsIntegrationsSearch: handleClearSearch,
    sortConnectorsIntegrations: setConnectorsIntegrationsSort,
  };
}

export default useConnectorsIntegrations;
