import { Key, useSWRConfig } from 'swr';

type Props = {
  mutate: ReturnType<typeof useSWRConfig>['mutate'];
};

export default function swrOptimisticUpdate<T>(
  key: Key,
  data: (data: T) => Partial<T> | T[],
  { mutate }: Props,
) {
  mutate(
    key,
    (cache) => {
      if (Array.isArray(cache)) {
        return cache.map((_, index) => ({
          data: {
            ...cache[index].data,
            ...data(cache[index].data),
          },
        }));
      } else if (cache?.data && !Array.isArray(cache.data)) {
        return {
          data: {
            ...cache.data,
            ...data(cache.data),
          },
        };
      } else if (cache?.data && Array.isArray(cache.data)) {
        return {
          data: data(cache.data) as T[],
        };
      } else {
        return {
          ...cache,
          ...data(cache),
        };
      }
    },
    { populateCache: true, revalidate: false },
  );
}
