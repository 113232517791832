import React from 'react';
import { Dialog, Icon, Text } from '@blastradius/ui';
import classNames from 'classnames';
import { Tenant } from '@customer-web-app/domains/tenants/models/tenant';
import SwitchTenantsList from '@customer-web-app/domains/tenants/components/switch-tenants-list';
import SwitchTenantListItem from '@customer-web-app/domains/tenants/components/switch-tenant-list-item';

type Props = {
  title: string;
  currentTenant?: Tenant;
  withDeactivate?: boolean;
  withBackground?: boolean;
};

const SwitchTenantDialog: React.FC<
  Props & React.HTMLAttributes<HTMLDivElement>
> = ({
  title,
  currentTenant,
  withBackground = true,
  withDeactivate = true,
  ...props
}) => {
  return (
    <Dialog
      {...props}
      withBackground={withBackground}
      width="w-[43.5rem]"
      height="h-[80vh] max-h-[46.25rem]"
      color="gray"
      className="top-[10%]"
      data-testid="switch-tenant-dialog"
    >
      <div
        className="h-full pt-4 px-4 flex flex-col items-center outline-none"
        id="switch-tenant-wrapper"
        tabIndex={0}
      >
        <header className="w-full mb-10">
          {withDeactivate && (
            <div className="flex items-center justify-end w-full">
              <Dialog.Deactivate>
                <Icon name="close" size={16} role="button" />
              </Dialog.Deactivate>
            </div>
          )}

          <div
            className={classNames('flex flex-col items-center', {
              'pt-7': withDeactivate,
              'pt-10': !withDeactivate,
            })}
          >
            <Text type="heading" size="regular" as="h1">
              {title}
            </Text>
          </div>
        </header>

        <section className="h-[calc(100%-6.625rem)] w-[29.5rem]">
          {currentTenant && (
            <>
              <div className="flex flex-col gap-4">
                <Text type="body" size="regular">
                  Currently viewing as
                </Text>

                <SwitchTenantListItem value={currentTenant?.name} />
              </div>

              <div className="mt-10">
                <Text type="body" size="regular">
                  Find another tenant
                </Text>
              </div>
            </>
          )}

          <SwitchTenantsList currentTenant={currentTenant} />
        </section>
      </div>
    </Dialog>
  );
};

export default SwitchTenantDialog;
