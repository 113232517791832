import React from 'react';
import classNames from 'classnames';
import Icon from '../icon';
import LoaderSpinner from '../loader-spinner';

type Sizes = 'x-small' | 'small' | 'medium' | 'large' | 'x-large';

const sizeMapper: {
  [key in Sizes]: string;
} = {
  'x-small': 'w-8 h-8 !basis-8',
  small: 'w-9 h-9 !basis-9',
  medium: 'w-10 h-10 !basis-10',
  large: 'w-12 h-12 !basis-12',
  'x-large': 'w-14 h-14 !basis-14',
};

type Props = {
  as?: React.ElementType;
  icon: React.ComponentProps<typeof Icon>['name'];
  iconSize?: React.ComponentProps<typeof Icon>['size'];
  iconStroke?: boolean;
  iconClassName?: string;
  active?: boolean;
  disabled?: boolean;
  loading?: boolean;
  ghost?: boolean;
  size?: Sizes;
  transparent?: boolean;
};

const ButtonIcon = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  Props &
    React.AnchorHTMLAttributes<HTMLAnchorElement> &
    React.ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      as = 'button',
      icon,
      iconSize = 16,
      iconStroke = false,
      active = false,
      iconClassName,
      disabled,
      loading,
      ghost,
      size = 'x-small',
      transparent = true,
      ...props
    },
    ref,
  ) => {
    const defaultClassName = `group inline-flex justify-center items-center rounded
      transition-all ease-in-out duration-200 cursor-pointer shrink-0`;

    const className = classNames(
      defaultClassName,
      sizeMapper[size],
      {
        'bg-transparent hover:bg-gray-100 hover:dark:bg-gray-925':
          transparent && !active && !ghost,
        'bg-white dark:bg-gray-900': active,
        'pointer-events-none': disabled || loading,
        'border border-gray-200 dark:border-gray-700 hover:border-gray-400 hover:dark:border-gray-500':
          ghost,
        'bg-gray-950/[.06] dark:bg-gray-100/[.08] hover:bg-gray-800/[.04] dark:hover:bg-white/[.06]':
          !transparent,
      },
      props.className,
    );
    const mergedIconClassName = classNames(
      'transition-colors duration-200ms ease-in-out',
      {
        'fill-gray-500': !active && !iconStroke,
        'fill-gray-900 dark:fill-white': active && !iconStroke,
        'fill-gray-950 dark:fill-white': !transparent || ghost,
      },
      iconClassName,
    );

    return React.createElement(
      as,
      {
        ...props,
        className,
        ref,
      },
      !loading ? (
        <Icon name={icon} size={iconSize} className={mergedIconClassName} />
      ) : (
        <LoaderSpinner />
      ),
    );
  },
);

export default ButtonIcon;
