import classNames from 'classnames';
import React from 'react';
import Icon from '../icon';
import Text from '../text';

type BannerType = 'success' | 'error';

type Props = {
  type: BannerType;
};

const bannerColorMapper: {
  [key in BannerType]: {
    color: string;
    icon: React.ComponentProps<typeof Icon>['name'];
    iconColor: string;
  };
} = {
  error: {
    color: 'border-l-pink-500 bg-pink-500/[.05] dark:bg-pink-500/[.08]',
    icon: 'error-warning',
    iconColor: '!fill-pink-500',
  },
  success: {
    color: 'border-l-green-600 bg-green-600/[.04] dark:bg-green-600/[.07]',
    icon: 'circle-check',
    iconColor: '!fill-green-600',
  },
};

function Banner({
  type,
  children,
  ...props
}: Props & React.HTMLAttributes<HTMLDivElement>) {
  const { color, icon, iconColor } = bannerColorMapper[type];

  const defaultClassName =
    'w-full flex items-center gap-2 py-3 h-11 border-l pl-5';
  const className = classNames(defaultClassName, props.className, color);

  return (
    <section {...props} className={className}>
      <Icon name={icon} className={iconColor} size={16} />
      <Text type="body" size="regular" color="text-gray-950 dark:text-white">
        {children}
      </Text>
    </section>
  );
}

export default Banner;
