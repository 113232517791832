import React from 'react';
import classNames from 'classnames';
import { ButtonGhost, Icon, NavigatorX, Tabs, Text } from '@blastradius/ui';
import Link from 'next/link';
import { sub } from 'date-fns';
import MainSearchBarAlertCard from '@customer-web-app/domains/search/components/main-search-bar-alert-card';
import useSearch from '@customer-web-app/domains/search/hooks/use-search';
import SearchFilterService from '@customer-web-app/domains/search/services/search-filter-service';
import { SearchEntityTypes } from '@customer-web-app/domains/search/models/search';
import MainSideNavigationEmptyState from '@customer-web-app/domains/shared/components/main-side-navigation-empty-state';
import getAlertsMainScreen from '@customer-web-app/domains/alerts/services/get-alerts-main-screen';
import useFeatureFlags from '@customer-web-app/domains/shared/hooks/use-feature-flags';

type Props = {
  disableHover?: boolean;
};

const MainSideNavigationAlertsLoading: React.FC<
  { count: number } & React.HTMLAttributes<HTMLElement>
> = ({ count }) => (
  <>
    {Array.from({ length: count }).map((_, index) => (
      <MainSearchBarAlertCard
        key={index}
        alert={null}
        iconClassName="dark:group-hover:bg-white/[.06]"
        borderBottom={index < count - 1}
        loading
      />
    ))}
  </>
);

const MainSideNavigationAlerts: React.FC<
  Props & React.HTMLAttributes<HTMLElement>
> = ({ disableHover, ...props }) => {
  const flags = useFeatureFlags();

  const currentDate = new Date();

  const defaultFilterValues = SearchFilterService.getDefaultSearchFilter();

  const alertsFilter = React.useMemo(
    () => ({
      ...defaultFilterValues,
      globalFilter: {
        ...defaultFilterValues.globalFilter,
        dateRange: {
          from: sub(currentDate, {
            years: 1,
          }),

          to: currentDate,
        },
        entities: ['Alert'] as SearchEntityTypes[],
      },
    }),
    [],
  );

  const { searchResults, isLoadingSearch } = useSearch({
    formValues: alertsFilter,
  });

  const latestAlerts = searchResults?.slice(0, 10);

  const defaultClassName = `bg-white dark:bg-gray-900 w-16
  fixed top-0 left-[15.25rem] h-full
  border-l border-l-gray-200 dark:border-l-gray-800
  border-r border-r-gray-200 dark:border-r-gray-800
  w-[49.938rem] z-40
  transition-fade duration-200 ease-in-out delay-200
  -translate-x-4 opacity-0 invisible 
  group-deeper-hover:translate-x-0 group-deeper-hover:opacity-100 group-deeper-hover:visible
  cursor-default pointer-events-none`;

  const className = classNames(defaultClassName, props.className, {
    'group-hover:pointer-events-auto': !disableHover,
  });

  return (
    <div className={className}>
      <header className="flex justify-between pt-4 pl-8 pr-10">
        <Text type="heading" size="small">
          Alerts
        </Text>
        <Link
          href={getAlertsMainScreen({
            enableEndpointAlerts: flags.alertsEnableEndpointAlerts as boolean,
            enableIdentityAlerts: flags.alertsEnableIdentityAlerts as boolean,
            enableNetworkAlerts: flags.alertsEnableNetworkAlerts as boolean,
            enableCloudAlerts: flags.alertsEnableCloudAlerts as boolean,
            enableGenericAlerts:
              flags.alertsEnableTriageRequiredAlertStatus as boolean,
            enablePriorityFilter: flags.alertsEnablePriorityFilter as boolean,
          })}
          passHref
          legacyBehavior
        >
          <ButtonGhost
            as="a"
            size="x-small"
            className="flex items-center"
            aria-label="View all alerts"
          >
            View All Alerts{' '}
            <Icon
              name="carrot-right"
              size={16}
              className="fill-gray-950 dark:fill-white ml-1"
            />
          </ButtonGhost>
        </Link>
      </header>

      <section>
        <Tabs className="mt-2 pl-8 pr-10">
          <Tabs.Navigator>
            <NavigatorX>
              <NavigatorX.Item
                as="button"
                size="small"
                aria-label="View Latest Alerts"
                role={'tab'}
                className="pb-3"
              >
                Latest
              </NavigatorX.Item>
            </NavigatorX>
          </Tabs.Navigator>
          <Tabs.Sections className="mt-6">
            <Tabs.Section aria-label="Latest Alerts" role={'tabpanel'}>
              {!isLoadingSearch ? (
                latestAlerts?.length > 0 ? (
                  latestAlerts?.map((alert, index) => (
                    <MainSearchBarAlertCard
                      key={alert.id}
                      alert={alert}
                      iconClassName="dark:group-hover:bg-white/[.06]"
                      className="hover:bg-gray-75 dark:hover:bg-white/[.06]"
                      borderBottom={index < latestAlerts.length - 1}
                    />
                  ))
                ) : (
                  <MainSideNavigationEmptyState>
                    No recent alerts in the last year
                  </MainSideNavigationEmptyState>
                )
              ) : (
                <MainSideNavigationAlertsLoading count={10} />
              )}
            </Tabs.Section>
          </Tabs.Sections>
        </Tabs>
      </section>
    </div>
  );
};

export default MainSideNavigationAlerts;
