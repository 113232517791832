import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function trackAnalyticEvent(name: string, additionalData?: any) {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'production') {
    window.heap.track(name, additionalData);
  }
}

type Props = {
  name: string;
  additionalData?: object;
  children: React.ReactElement;
};

const TrackAnalyticEvent: React.FC<Props> = ({
  name,
  additionalData,
  children,
}) => {
  function handleClick(e: React.MouseEvent<HTMLElement>) {
    trackAnalyticEvent(name, additionalData);

    children.props.onClick?.(e);
  }

  return React.cloneElement(children, {
    ...children.props,
    onClick: handleClick,
  });
};

export default TrackAnalyticEvent;
