import React from 'react';
import useDialog from '../../hooks/use-dialog';
import Dialog, { DialogProps } from '../dialog';
import { DialogComponent } from '../dialog/dialog-provider';

function ShadowDomImagePreviewImageDialog({
  imageSRC,
  onClose,
  ...props
}: DialogProps & {
  imageSRC: string;
  onClose: () => void;
} & React.HTMLAttributes<HTMLElement>) {
  return (
    <Dialog
      {...props}
      data-testid="shadow-dom-image-dialog"
      closeDialog={onClose}
      width="w-fit max-w-[calc(100%-8rem)]"
      height="h-fit"
      color="gray"
      className="flex flex-col items-center gap-4 pt-4 px-10 pb-12 !top-0"
      backgroundClassName="flex items-center justify-center"
    >
      <Dialog.Deactivate className="self-end" closeDialog={onClose} />

      <img
        src={imageSRC}
        alt="Full-size"
        className="max-w-[100%] max-h-[calc(100vh-24rem)]"
      />
    </Dialog>
  );
}

export default function ShadowDomImagePreview({
  children,
}: {
  children: React.ReactNode;
}) {
  const { openDialog } = useDialog();

  const ref = React.useRef<HTMLDivElement>(null);
  const [imageSRC, setImageSRC] = React.useState<string | null>(null);

  React.useEffect(() => {
    const handleImageClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.tagName === 'IMG') {
        setImageSRC((target as HTMLImageElement).src);
      }
    };

    if (ref.current) {
      ref.current.addEventListener('click', handleImageClick);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('click', handleImageClick);
      }
    };
  }, []);

  React.useEffect(() => {
    if (imageSRC) {
      openDialog(ShadowDomImagePreviewImageDialog as DialogComponent, {
        imageSRC,
        onClose: () => setImageSRC(null),
      });
    }
  }, [imageSRC]);

  return <div ref={ref}>{children}</div>;
}
