import React from 'react';
import { FeatureFlagsStructure } from '@customer-web-app/domains/shared/providers/feature-flags-provider';

export type GlobalStoreState = {
  identityPictures: { [key in string]: string };
  featureFlags: FeatureFlagsStructure;
  alertEmailImages: { [key in string]: string };
};

interface IGlobalStoreContext {
  globalStoreState: GlobalStoreState;
  setGlobalStoreState: <T>(key: keyof GlobalStoreState, value: T) => void;
}

export const GlobalStoreContext = React.createContext<IGlobalStoreContext>({
  globalStoreState: {
    identityPictures: {},
    featureFlags: {} as FeatureFlagsStructure,
    alertEmailImages: {},
  },
  setGlobalStoreState: () => void 0,
});

const GlobalStoreProvider: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
}) => {
  const [globalStoreState, setGlobalStoreState] =
    React.useState<GlobalStoreState>({
      identityPictures: {},
      featureFlags: {} as FeatureFlagsStructure,
      alertEmailImages: {},
    });

  function setGlobalStore<T>(key: keyof GlobalStoreState, value: T) {
    setGlobalStoreState((prevValue) => ({
      ...prevValue,
      [key]: {
        ...prevValue[key],
        ...value,
      },
    }));
  }

  return (
    <GlobalStoreContext.Provider
      value={{
        globalStoreState,
        setGlobalStoreState: setGlobalStore,
      }}
    >
      {children}
    </GlobalStoreContext.Provider>
  );
};

export default GlobalStoreProvider;
