import useSWR from 'swr';
import React from 'react';
import { Credential } from '@customer-web-app/domains/settings-services/models/credential';
import orderBy from 'lodash/orderBy';
import { UseFormReturn } from 'react-hook-form';
import normalizeString from '@customer-web-app/domains/shared/services/normalize-string';
import { Table } from '@blastradius/ui';
import useAuthentication from '@customer-web-app/domains/authentication/hooks/use-authentication';

interface CredentialsListResponse {
  data: {
    items: Credential[];
    lastQueryToken: string;
  };
}

type UseCredentialsListParams = {
  form?: UseFormReturn<{ search: string }>;
  sort?: React.ComponentProps<typeof Table>['initialSort'];
};

function useCredentialsList({ form, sort }: UseCredentialsListParams = {}) {
  const { user } = useAuthentication();

  const { data, error } = useSWR<CredentialsListResponse>(
    user?.id && '/connectors/credentials/list',
  );

  const searchValue = form ? form.watch('search') : undefined;

  const credentialsList = React.useMemo(() => {
    let credentials = data?.data?.items || [];

    if (form) {
      credentials = credentials.filter(
        ({ integrationDisplayName, integrationName }) => {
          const normalizedSearchValue = normalizeString(searchValue);

          return (
            normalizeString(integrationDisplayName).includes(
              normalizedSearchValue,
            ) ||
            normalizeString(integrationName).includes(normalizedSearchValue)
          );
        },
      );
    }

    if (sort) {
      return orderBy(
        credentials,
        [(data) => data[sort.field]?.toLowerCase()],
        [sort.direction],
      );
    }

    return credentials;
  }, [data, searchValue, sort]);

  function handleClearSearch() {
    form.setValue('search', '');
  }

  return {
    credentialsList,
    isCredentialsListLoading: !data && !error,
    credentialsListError: error,
    clearCredentialsSearch: handleClearSearch,
    form,
    searchValue,
  };
}

export default useCredentialsList;
