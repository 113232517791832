import { Icon } from '@blastradius/ui';
import { IncidentSteps } from '@customer-web-app/domains/incidents/models/incident';

const incidentIconsMapper: {
  [key in IncidentSteps]: React.ComponentProps<typeof Icon>['name'];
} = {
  opened: 'incident-open',
  pending: 'incident-pending',
  remediated: 'incident-remediated',
  closed: 'incident-closed',
};

export default incidentIconsMapper;
