import React from 'react';
import classNames from 'classnames';
import { Icon, Text, IconContained } from '@blastradius/ui';
import Skeleton from 'react-loading-skeleton';
import styles from './button-ghost-composed.module.scss';

type Sizes = 'medium' | 'large';

type Props = {
  as?: 'a' | 'button';
  label?: string;
  description?: string;
  footer?: React.ReactNode;
  disabled?: boolean;
  locked?: boolean;
  leftIcon?: React.ComponentProps<typeof Icon>['name'];
  leftIconColor?: React.ComponentProps<typeof Icon>['color'];
  leftIconSize?: React.ComponentProps<typeof Icon>['size'];
  rightIcon?: React.ComponentProps<typeof Icon>['name'];
  rightIconClassName?: string;
  rightIconSize?: React.ComponentProps<typeof Icon>['size'];
  loading?: boolean;
  selected?: boolean;
  size?: Sizes;
};

const iconSizeMapper: {
  [key in Sizes]: {
    iconSize: React.ComponentProps<typeof Icon>['size'];
    iconContainerSize: number;
  };
} = {
  medium: {
    iconSize: 16,
    iconContainerSize: 8,
  },
  large: {
    iconSize: 28,
    iconContainerSize: 13,
  },
};

const ButtonGhostComposed = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  Props &
    React.AnchorHTMLAttributes<HTMLAnchorElement> &
    React.ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      as = 'button',
      label,
      description,
      footer,
      disabled,
      locked,
      leftIcon,
      leftIconColor,
      leftIconSize,
      rightIcon,
      rightIconClassName,
      rightIconSize = 20,
      loading = false,
      selected = false,
      size = 'large',
      ...props
    },
    ref,
  ) => {
    const defaultClassName = `group flex justify-between items-center w-full py-4 pl-4 pr-7 rounded transition-colors duration-200 ease-in-out cursor-pointer
    bg-transparent dark:bg-white/[.06] border border-solid border-gray-950/[.25]
    dark:border-gray-700 hover:bg-gray-950/[.04] dark:hover:bg-white/[.12]`;

    const className = classNames(
      defaultClassName,
      styles.buttonGhostComposed,
      props.className,
      {
        'pointer-events-none': locked || disabled || loading,
        '!bg-gray-950/[.04] dark:!bg-white/[.12] !border-black dark:!border-white':
          selected,
      },
    );

    return React.createElement(
      as,
      {
        type: as === 'button' ? 'button' : undefined,
        'aria-label': label,
        ...props,
        className,
        ref,
        'aria-selected': selected,
      },
      <>
        <div className="flex items-center gap-4">
          {leftIcon ? (
            !loading ? (
              <IconContained
                icon={leftIcon}
                iconSize={leftIconSize || iconSizeMapper[size].iconSize}
                iconColor={leftIconColor}
                size={`w-${iconSizeMapper[size].iconContainerSize} h-${iconSizeMapper[size].iconContainerSize}`}
                className={classNames('!bg-gray-100 dark:!bg-gray-925', {
                  'fill-gray-300 dark:fill-gray-700 opacity-60': disabled,
                })}
              />
            ) : (
              <Skeleton width={52} height={52} />
            )
          ) : null}

          <div className="flex items-start flex-col gap-1">
            {!loading ? (
              <Text
                type="body"
                size="large"
                className={classNames('text-left', {
                  'opacity-50': disabled,
                })}
              >
                {label}
              </Text>
            ) : (
              <Skeleton width={140} height={22} />
            )}

            {description ? (
              !loading ? (
                <Text
                  type="body"
                  size="regular"
                  color="text-gray-500"
                  className="text-left"
                >
                  {description}
                </Text>
              ) : (
                <Skeleton width={300} height={18} />
              )
            ) : null}

            {footer ? (
              <div className="flex items-center gap-1 mt-5">
                {!loading ? (
                  footer
                ) : (
                  <>
                    <Skeleton width={100} height={18} />
                    <Skeleton width={100} height={18} />
                  </>
                )}
              </div>
            ) : null}
          </div>
        </div>
        {rightIcon && !loading && (
          <Icon
            name={!disabled ? rightIcon : 'block'}
            size={rightIconSize}
            className={classNames(
              rightIconClassName,
              { 'fill-gray-950 dark:fill-white': !disabled },
              { 'fill-gray-500 opacity-60': disabled },
            )}
          />
        )}
      </>,
    );
  },
);

export default ButtonGhostComposed;
