/* eslint-disable @typescript-eslint/no-var-requires */
const resolveConfig = require('tailwindcss/resolveConfig');
const tailwindConfig = require('../../../tailwind.config');
const { theme } = resolveConfig(tailwindConfig);

// We need this because of the tooltip arrows
const TooltipStyles = (userTheme) => ({
  'data-background-color': `${
    userTheme === 'light' ? theme.colors.gray['800'] : theme.colors.white
  }`,
});

export default TooltipStyles;
