import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import DebounceLink from 'apollo-link-debounce';
import { parseCookies } from 'nookies';
import React from 'react';
import { delay } from '@customer-web-app/domains/shared/services/delay';

const httpLink = ApolloLink.from([
  new DebounceLink(0),
  new HttpLink({
    uri: `${process.env.NEXT_PUBLIC_BLASTRADIUS_GRAPHQL_BASE_URL}/graphql`,
  }),
]);

const authLink = setContext(async (_, config) => {
  let token = parseCookies().token;

  if (!token) {
    await delay(100);
    token = parseCookies().token;
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    },
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    EmailRecipient: {
      keyFields: ['email', 'displayName'],
    },
    Identity: {
      keyFields: ['displayName', 'email'],
    },
  },
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});

const GraphQLProvider: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
}) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphQLProvider;
