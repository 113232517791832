import classNames from 'classnames';
import React from 'react';
import Text from '../text';
import EndTruncation from '../end-truncation';

type Props = {
  label: string;
  value: string;
};

function FieldTag({
  label,
  value,
  ...props
}: Props & React.HTMLAttributes<HTMLDivElement>) {
  const defaultClassName =
    'rounded border border-gray-300 dark:border-gray-600 flex items-center justify-between h-5 w-fit';
  const className = classNames(defaultClassName, props.className);

  return (
    <div className={className}>
      <div className="flex items-center justify-center bg-gray-75 dark:bg-white/[.06] h-full px-1.5 rounded-l">
        <Text
          type="body"
          size="small"
          color="text-gray-500"
          className="whitespace-nowrap"
        >
          {label}
        </Text>
      </div>
      <div className="flex items-center justify-center bg-gray-100 dark:bg-gray-800 h-full px-1.5 rounded-r">
        <Text type="body" size="small" copyable>
          {typeof value === 'string' ? (
            <EndTruncation length={30} value={value} />
          ) : (
            value
          )}
        </Text>
      </div>
    </div>
  );
}

export default FieldTag;
