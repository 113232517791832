import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Icon, { Icons } from '../icon';
import Text from '../text';
import classNames from 'classnames';

type Props = {
  iconLeft: Icons;
  iconRight?: Icons;
  disabled?: boolean;
  loading?: boolean;
  selected?: boolean;
  idle?: boolean;
};

function OptionIcon({
  iconLeft,
  iconRight,
  disabled,
  loading,
  selected,
  idle,
  children,
  ...props
}: Props & React.HTMLAttributes<HTMLButtonElement>) {
  const defaultClassName = `border rounded flex items-center justify-between gap-2 h-8 w-fit pr-2 overflow-hidden transition-all ease-in-out duration-200`;

  const className = classNames(defaultClassName, props.className, {
    'pointer-events-none': disabled || loading,
    'bg-white dark:bg-white/[.06] border-gray-950/[0.25] dark:border-white/[0.25] hover:bg-gray-950/[.04] hover:dark:bg-white/[.12]':
      !selected,
    'border-gray-950 dark:border-white bg-gray-950/[.04] dark:bg-white/[.12]':
      selected,
  });

  return (
    <button type="button" {...props} className={className}>
      <div
        className={classNames(
          'bg-gray-200 dark:bg-gray-950 flex items-center justify-center h-full w-8',
          {
            'opacity-50': disabled || idle,
          },
        )}
      >
        {!loading && (
          <Icon
            name={iconLeft}
            size={16}
            className="fill-gray-950 dark:fill-white"
          />
        )}
      </div>

      {!loading ? (
        <Text
          type="body"
          size="large"
          className={classNames({
            'opacity-50': disabled,
          })}
        >
          {children}
        </Text>
      ) : (
        <Skeleton height={20} width={100} />
      )}

      {iconRight && !loading && (
        <Icon
          name={iconRight}
          size={16}
          className="fill-gray-900 dark:fill-white"
        />
      )}
    </button>
  );
}

export default OptionIcon;
