import Icon from '@blastradius/ui/icons';
import classNames from 'classnames';
import React from 'react';
import Text from '../../base-components/text';

type Sizes = 'small' | 'regular' | 'large';

type PaginationContextProps = {
  currentPage: number;
  skip: number;
  take: number;
  itemsPerPage: number;
  goToPage: (pageNumber: number) => void;
  setSkipTake: (pageNumber: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPaginatedRegisters: (items: any) => any[];
  setSkip: React.Dispatch<React.SetStateAction<number>>;
  setTake: React.Dispatch<React.SetStateAction<number>>;
  setItemsPerPage: React.Dispatch<React.SetStateAction<number>>;
};

export const PaginationContext = React.createContext<PaginationContextProps>({
  currentPage: 0,
  skip: 0,
  take: 0,
  itemsPerPage: 0,
  goToPage: () => void 0,
  setSkipTake: () => void 0,
  getPaginatedRegisters: () => [],
  setSkip: () => void 0,
  setTake: () => void 0,
  setItemsPerPage: () => void 0,
});

export function PaginationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [skip, setSkip] = React.useState(0);
  const [take, setTake] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(0);

  function setSkipTake(pageNumber: number) {
    const skip = pageNumber * itemsPerPage;

    setSkip(skip);
    setTake(skip + itemsPerPage);
  }

  function goToPage(pageNumber: number) {
    setCurrentPage(pageNumber);
    setSkipTake(pageNumber);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getPaginatedRegisters(items: any) {
    return items.slice(skip, take);
  }

  return (
    <PaginationContext.Provider
      value={{
        currentPage,
        skip,
        take,
        itemsPerPage,
        goToPage,
        setSkipTake,
        setSkip,
        setTake,
        setItemsPerPage,
        getPaginatedRegisters,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
}

type Props = {
  totalItems: number;
  itemsPerPage: number;
  initialPage?: number;
  size?: Sizes;
};

const MAX_PAGES = 10;

export default function Pagination({
  totalItems,
  itemsPerPage,
  initialPage = 0,
  size = 'regular',
  className,
  ...props
}: Props & React.HTMLAttributes<HTMLElement>) {
  const defaultClassName = classNames('flex gap-1 justify-center', className, {
    'scale-[0.80]': size === 'small',
    'scale-[1.15]': size === 'large',
  });
  const buttonClassName = `flex items-center justify-center min-w-10 w-fit h-10 rounded select-none transition-all duration-200 ease-in-out px-1`;
  const textClassName = 'text-gray-950 dark:text-white';
  const iconClassName = 'fill-gray-950 dark:fill-white';
  const disableClassName = 'pointer-events-none opacity-40';

  const {
    itemsPerPage: itemsPerPageState,
    currentPage,
    goToPage,
    setItemsPerPage,
  } = React.useContext(PaginationContext);

  const totalPages = React.useMemo(
    () => Math.ceil(totalItems / itemsPerPage),
    [totalItems, itemsPerPage],
  );

  React.useEffect(() => {
    setItemsPerPage(itemsPerPage);
  }, []);

  React.useEffect(() => {
    goToPage(initialPage);
  }, [itemsPerPageState]);

  if (totalItems <= itemsPerPage) {
    return null;
  }

  // TODO: why calculating it again and not use "totalPages"?
  const numberOfPages = Math.ceil(totalItems / itemsPerPage);
  const lastPage = numberOfPages - 1;

  const createPageButton = (page: number) => {
    return (
      <button
        key={`pagination_item_${page}`}
        className={classNames(buttonClassName, textClassName, {
          'bg-gray-900 dark:bg-gray-100 !text-white dark:!text-gray-950':
            page === currentPage,
          'hover:bg-gray-800/[0.04] dark:hover:bg-white/[0.06]':
            page !== currentPage,
        })}
        onClick={() => {
          goToPage(page);
        }}
        aria-label={`Go to page ${page + 1}`}
      >
        {page + 1}
      </button>
    );
  };

  const createEllipsisButton = (page: number) => (
    <button
      key={`pagination_item_ellipsis_${page}`}
      className="min-w-8 w-fit h-8 flex items-center justify-center"
      onClick={() => goToPage(page)}
      aria-label={`Go to page ${page + 1}`}
    >
      <Text type="label" size="regular" as="p">
        ...
      </Text>
    </button>
  );

  return (
    <div {...props} aria-label="Pagination" className={defaultClassName}>
      <button
        className={classNames(buttonClassName, {
          [disableClassName]: currentPage <= 0,
        })}
        onClick={() => {
          goToPage(currentPage - 1);
        }}
        aria-label="Go to previous page"
      >
        <Icon name="carrot-left" size={16} className={iconClassName} />
      </button>

      {numberOfPages <= MAX_PAGES && (
        <>
          {/* [0..totalPages[ */}
          {Array.from({ length: totalPages }).map((_, page) =>
            createPageButton(page),
          )}
        </>
      )}

      {numberOfPages > MAX_PAGES && (
        <>
          {createPageButton(0)}

          {currentPage > 3 &&
            createEllipsisButton(Math.max(currentPage - 3, 0))}

          {[
            currentPage - 2,
            currentPage - 1,
            currentPage,
            currentPage + 1,
            currentPage + 2,
          ]
            .filter((page) => page >= 1 && page <= lastPage - 1)
            .map((page) => createPageButton(page))}

          {Math.abs(currentPage - lastPage) > 3 &&
            createEllipsisButton(Math.max(currentPage + 3, 0))}

          {createPageButton(lastPage)}
        </>
      )}

      <button
        className={classNames(buttonClassName, {
          [disableClassName]: currentPage + 1 >= totalPages,
        })}
        onClick={() => {
          goToPage(currentPage + 1);
        }}
        aria-label="Go to next page"
      >
        <Icon name="carrot-right" size={16} className={iconClassName} />
      </button>
    </div>
  );
}

export const withPagination = <T extends React.HTMLAttributes<HTMLElement>>(
  Component: React.ComponentType<T>,
) => {
  return (props: T) => {
    return (
      <PaginationProvider>
        <Component {...props} />
      </PaginationProvider>
    );
  };
};
