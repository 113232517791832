import React from 'react';
import classNames from 'classnames';

type Props = {
  as?: React.ElementType;
  width?: string;
};

const Container: React.FC<Props & React.HTMLAttributes<HTMLHeadingElement>> = ({
  as = 'div',
  width = 'w-container-lg',
  children,
  ...props
}) => {
  const className = classNames(props.className, 'ml-auto mr-auto', {
    [width]: width !== 'w-container-lg',
    'w-[80.5rem] 1.5xl:w-container-lg 2.5xl:w-[calc(100%-4rem)] 2.5xl:mx-6':
      width === 'w-container-lg',
  });

  return React.createElement(as, { ...props, className }, children);
};

export default Container;
