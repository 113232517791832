import { parseCookies } from 'nookies';
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { delay } from '@customer-web-app/domains/shared/services/delay';

async function accessTokenInterceptor(config: AxiosRequestConfig) {
  let token = parseCookies().token;

  if (!token) {
    await delay(100);
    token = parseCookies().token;
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    } as AxiosRequestHeaders,
  };
}

export default accessTokenInterceptor;
