import { Text } from '@blastradius/ui';
import classNames from 'classnames';
import React from 'react';

const NewPill: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const defaultClassName = `absolute bg-gradient-to-r from-purple-200 to-blue-300 dark:from-purple-600
  dark:to-blue-600 flex items-center justify-center rounded w-9 h-[1.125rem] shrink-0`;

  const className = classNames(defaultClassName, props.className);

  return (
    <div {...props} className={className}>
      <Text type="body" size="x-small">
        New
      </Text>
    </div>
  );
};

export default NewPill;
