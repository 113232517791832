import React from 'react';
import { Text } from '@blastradius/ui';
import classNames from 'classnames';

type Props = {
  title: React.ReactNode;
  subTitle: React.ReactNode;
  action?: React.ReactNode;
  illustration?: React.ReactNode;
};

function EmptyStateGhost({
  title,
  subTitle,
  action,
  illustration,
  ...props
}: Props & React.HTMLAttributes<HTMLElement>) {
  const defaultClassName = 'flex flex-col items-center pt-16';
  const className = classNames(defaultClassName, props.className);

  return (
    <div {...props} className={className}>
      {illustration}

      <div className="mb-6 flex flex-col gap-2 items-center">
        <Text type="heading" size="small">
          {title}
        </Text>
        <Text type="body" size="small" color="text-gray-500">
          {subTitle}
        </Text>
      </div>

      {action}
    </div>
  );
}

export default EmptyStateGhost;
