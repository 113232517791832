import { IncidentTypes } from '@customer-web-app/domains/incidents/models/incident';

export default function getIncidentDetailsEntryPointURL(
  incidentType: IncidentTypes,
  enabledIncidentSummaryByContext: string[] = [],
) {
  if (incidentType === IncidentTypes.Generic) {
    return 'investigation';
  }
  return enabledIncidentSummaryByContext.includes(incidentType)
    ? 'summary'
    : 'triage';
}
