import React from 'react';
import useShortcut from '../../hooks/use-shortcut';

export type Themes = 'dark' | 'light';
type ThemeModes = 'system' | 'user';

export interface IThemeContext {
  theme: Themes;
  themeMode: ThemeModes;
  setAndSaveTheme: (theme: Themes, themeMode: ThemeModes) => void;
}

const defaultTheme: Themes = 'light';
const defaultThemeMode: ThemeModes = 'system';

/* istanbul ignore next */
const ThemeContext = React.createContext<IThemeContext>({
  theme: defaultTheme,
  themeMode: defaultThemeMode,
  setAndSaveTheme: () => void 0,
});

export const ThemeProvider: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
}) => {
  const [theme, setTheme] = React.useState<Themes>(defaultTheme);
  const [themeMode, setThemeMode] =
    React.useState<ThemeModes>(defaultThemeMode);

  function setAndSaveTheme(theme: Themes, themeMode: ThemeModes) {
    setTheme(theme);
    setThemeMode(themeMode);
    localStorage.setItem('theme', theme);
    localStorage.setItem('themeMode', themeMode);

    document.body.classList.remove('dark', 'light');
    document.body.classList.add(theme);
  }

  React.useEffect(() => {
    const userChosenTheme =
      (localStorage.getItem('themeMode') as ThemeModes) === 'user' &&
      (localStorage.getItem('theme') as Themes);

    if (userChosenTheme) {
      setAndSaveTheme(userChosenTheme as Themes, 'user');
    } else if (window.matchMedia) {
      const systemIsDarkMode = window.matchMedia(
        '(prefers-color-scheme: dark)',
      ).matches;

      const systemTheme: Themes = systemIsDarkMode ? 'dark' : 'light';

      setAndSaveTheme(systemTheme, 'system');
    } else {
      setAndSaveTheme('light', 'system');
    }

    // Listener for storage changes
    function storageListener({ newValue, key }: StorageEvent) {
      if (key === 'theme') {
        setTheme(newValue as Themes);
      }
    }

    window.addEventListener('storage', storageListener);

    return () => {
      window.removeEventListener('storage', storageListener);
    };
  }, []);

  function onThemeShortcut() {
    setAndSaveTheme(theme === 'dark' ? 'light' : 'dark', 'user');
  }

  useShortcut([
    { keys: ['l'], addCtrlModifier: true, callback: onThemeShortcut },
  ]);

  return (
    <ThemeContext.Provider value={{ theme, themeMode, setAndSaveTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
