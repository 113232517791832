import { Themes } from '@blastradius/ui/themes';
import { FronteggProviderNoSSR } from '@frontegg/nextjs/no-ssr';
import colors from '@blastradius/ui/colors';
import logoBlack from '@customer-web-app/domains/authentication/assets/images/rs-logo-black.png';
import logoWhite from '@customer-web-app/domains/authentication/assets/images/rs-logo-white.png';
import loginBackground from '@customer-web-app/domains/authentication/assets/images/login-background.png';

export const fronteggThemeOptions: {
  [key in Themes]: React.ComponentProps<
    typeof FronteggProviderNoSSR
  >['themeOptions'];
} = {
  dark: {
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            div: {
              fontFamily: 'Public Sans, sans-serif',
            },
            '.fe-title': {
              fontWeight: 500,
            },
            '[data-test-id="company-logo"]': {
              height: '41px',
            },
            '[data-test-id="powered-by-logo"]': {
              display: 'none',
            },
            '.fe-alert-error': {
              background: colors.transparent,
              borderColor: colors.pink[500],
            },
            '.fe-alert-error .MuiAlert-icon': {
              color: colors.pink[500],
            },
            '.fe-alert-error .MuiAlert-message': {
              color: colors.pink[500],
            },
            '[data-test-id="back-to-login-btn"]': {
              color: colors.white,
            },
            '[data-test-id="back-to-login-btn"]:hover': {
              background: colors.transparent,
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: 'Public Sans, sans-serif',
            color: colors.white,
          },
          label: {
            fontSize: '14px',
            color: `${colors.white} !important`,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: `${colors.white} !important`,
            textDecoration: 'underline',
            '&[data-test-id="forgot-password-btn"]': {
              color: `${colors.white} !important`,
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            '&.Mui-error': {
              color: colors.pink[500],
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: `${colors.white} !important`,
            borderRadius: '4px',
            rect: {
              fill: `${colors.white} !important`,
              stroke: `${colors.white} !important`,
            },
            path: {
              fill: `${colors.gray[950]} !important`,
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(255,255,255,0.06) !important',
            border: 'none !important',
            svg: {
              path: {
                fill: `${colors.white} !important`,
              },
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            svg: {
              path: {
                fill: `${colors.white} !important`,
              },
            },
          },
        },
      },
    },
    loginBox: {
      logo: {
        image: `<img class="MuiCompanyLogo-img" src="${logoWhite.src}" alt="company-logo" data-test-id="company-logo" />`,
      },
      rootStyle: {
        background: `${colors.gray[950]} url(${loginBackground.src}) bottom center / cover no-repeat`,
      },
      boxStyle: {
        background: colors.gray[900],
        color: colors.white,
      },
      inputTheme: {
        base: {
          background: colors.gray[900],
          borderColor: colors.gray[600],
          color: colors.gray[50],
        },
        hover: {
          outline: 'none',
          boxShadow: 'none',
          borderColor: colors.gray[300],
        },
        active: {
          outline: 'none',
          boxShadow: 'none',
          color: colors.gray[50],
        },
        focus: {
          outline: 'none',
          boxShadow: 'none',
          color: colors.gray[50],
        },
        disabled: {
          background: colors.gray[900],
          borderColor: colors.gray[600],
          color: colors.gray[50],
        },
        error: {
          borderColor: `${colors.pink[500]} !important`,
        },
      },
      submitButtonTheme: {
        base: {
          backgroundColor: colors.pink[500],
          borderColor: colors.pink[500],
        },
        hover: {
          background: colors.pink[600],
          borderColor: colors.pink[600],
        },
        active: {
          background: colors.pink[600],
          borderColor: colors.pink[600],
        },
        focus: {
          background: colors.pink[600],
          borderColor: colors.pink[600],
        },
        disabled: {
          background: colors.pink[600],
          borderColor: colors.pink[600],
        },
      },
    },
  },
  light: {
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            div: {
              fontFamily: 'Public Sans, sans-serif',
            },
            '.fe-title': {
              fontWeight: 500,
            },
            '[data-test-id="company-logo"]': {
              height: '41px',
            },
            '[data-test-id="powered-by-logo"]': {
              display: 'none',
            },
            '.fe-alert-error': {
              background: colors.transparent,
              borderColor: colors.pink[500],
            },
            '.fe-alert-error .MuiAlert-icon': {
              color: colors.pink[500],
            },
            '.fe-alert-error .MuiAlert-message': {
              color: colors.pink[500],
            },
            '[data-test-id="back-to-login-btn"]': {
              color: colors.white,
            },
            '[data-test-id="back-to-login-btn"]:hover': {
              background: colors.transparent,
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: 'Public Sans, sans-serif',
            color: colors.gray[950],
          },
          label: {
            fontSize: '14px',
            color: `${colors.gray[950]} !important`,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: `${colors.gray[950]} !important`,
            textDecoration: 'underline',
            '&[data-test-id="forgot-password-btn"]': {
              color: `${colors.gray[950]} !important`,
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            '&.Mui-error': {
              color: colors.pink[500],
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: `${colors.gray[950]} !important`,
            borderRadius: '4px',
            rect: {
              fill: `${colors.gray[950]} !important`,
              stroke: `${colors.gray[950]} !important`,
            },
            path: {
              fill: `${colors.white} !important`,
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(48,52,67,0.06) !important',
            border: 'none !important',
            svg: {
              path: {
                fill: `${colors.gray[950]} !important`,
              },
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            svg: {
              path: {
                fill: `${colors.gray[950]} !important`,
              },
            },
          },
        },
      },
    },
    loginBox: {
      logo: {
        image: `<img class="MuiCompanyLogo-img" src="${logoBlack.src}" alt="company-logo" data-test-id="company-logo" />`,
      },
      rootStyle: {
        background: `${colors.gray[950]} url(${loginBackground.src}) bottom center / cover no-repeat`,
      },
      boxStyle: {
        background: colors.white,
        color: colors.gray[950],
      },
      inputTheme: {
        base: {
          background: colors.white,
          borderColor: colors.gray[300],
          color: colors.gray[950],
        },
        hover: {
          outline: 'none',
          boxShadow: 'none',
          borderColor: colors.gray[600],
        },
        active: {
          outline: 'none',
          boxShadow: 'none',
          color: colors.gray[950],
        },
        focus: {
          outline: 'none',
          boxShadow: 'none',
          color: colors.gray[950],
        },
        disabled: {
          background: colors.white,
          borderColor: colors.gray[300],
          color: colors.gray[950],
        },
        error: {
          borderColor: `${colors.pink[500]} !important`,
        },
      },
      submitButtonTheme: {
        base: {
          backgroundColor: colors.pink[500],
          borderColor: colors.pink[500],
        },
        hover: {
          background: colors.pink[600],
          borderColor: colors.pink[600],
        },
        active: {
          background: colors.pink[600],
          borderColor: colors.pink[600],
        },
        focus: {
          background: colors.pink[600],
          borderColor: colors.pink[600],
        },
        disabled: {
          background: colors.pink[600],
          borderColor: colors.pink[600],
        },
      },
    },
  },
};
