const config = require('../../tailwind.workspace-preset');

module.exports = {
  ...config,
  content: [
    '../../libs/**/src/**/*.tsx',
    '../../libs/**/src/**/*.ts',
    '../../libs/**/src/**/*.svg',
    '../../libs/**/src/**/*.scss',
    '../../apps/**/src/domains/**/components/**/*.tsx',
    '../../apps/**/src/domains/**/components/**/*.ts',
    '../../apps/**/src/domains/**/hooks/**/*.ts',
    '../../apps/**/src/domains/**/services/**/*.ts',
    '../../apps/**/src/domains/**/screens/**/*.tsx',
    '../../apps/**/src/domains/**/providers/**/*.tsx',
    '../../apps/**/pages/**/*.tsx',
  ],
};
