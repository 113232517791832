export enum ConnectorVendorCodes {
  AWS = 'aws',
  Crowdstrike = 'crowdstrike',
  Cisco = 'cisco',
  MicrosoftDefender = 'msdef',
  MicrosoftOffice = 'msoff365',
  Okta = 'okta',
  PaloAltoNetworks = 'paloaltonw',
  SentinelOne = 'sentinelone',
  ZScaler = 'zscaler',
  BlastRadius = 'br',
  MicrosoftAzureAD = 'msazuread',
  GoogleWorkspace = 'google',
  KnowBe4 = 'knowbe4',
  Mimecast = 'mimecast',
  Slack = 'slack',
  FireEyeHX = 'fireeyehx',
  Cribl = 'cribl',
  CriblWebHook = 'cribl_webhook',
  MicrosoftTeams = 'msteams',
  PulseSecure = 'pulsesecure',
  Proofpoint = 'proofpoint',
  Fleet = 'fleet',
  SonicWall = 'sonicwall',
  Fortinet = 'fortinet',
  Rapid7 = 'rapid7',
  Barracuda = 'barracuda',
  Atlassian = 'atlassian',
  Netskope = 'netskope',
  TrendMicro = 'trendmicro',
  Forcepoint = 'forcepoint',
  Avanan = 'avanan',
  Splunk = 'splunk',
  Aruba = 'aruba',
  ManageEngine = 'manage_engine',
  DataDog = 'datadog',
  Dragos = 'dragos',
  OnePassword = 'onepassword',
  Darktrace = 'darktrace',
  AdaptiveShield = 'adaptive_shield',
  CheckPoint = 'checkpoint',
}
