import React from 'react';
import classNames from 'classnames';
import { ReactComponent as Checker } from './assets/checker.svg';
import Icon from '../icon';
import Text from '../text';

type Props = {
  enabled?: boolean;
  disabled?: boolean;
  locked?: boolean;
  disabledIcon?: React.ComponentProps<typeof Icon>['name'];
  onChangeValue: (currentValue: boolean) => Promise<void>;
  withLabel?: boolean;
};

const Toggle: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  enabled = false,
  disabled,
  locked,
  disabledIcon,
  children,
  onChangeValue,
  withLabel = false,
  ...props
}) => {
  const [isEnabled, setIsEnabled] = React.useState(enabled);

  React.useEffect(() => {
    setIsEnabled(enabled);
  }, [enabled]);

  const defaultClassName =
    'group w-[3.094rem] h-6 p-[0.188rem] rounded-full cursor-pointer transition duration-200 ease-in-out';
  const wrapperDisabledClassName =
    'bg-gray-500 dark:bg-gray-600 hover:bg-gray-400 dark:hover:bg-gray-500';
  const wrapperEnabledClassName =
    'bg-green-500 dark:bg-green-600 hover:bg-green-400 dark:hover:bg-green-500';
  const className = classNames(
    defaultClassName,
    {
      'pointer-events-none opacity-30': disabled,
      'pointer-events-none': locked,
      [wrapperDisabledClassName]: !isEnabled,
      [wrapperEnabledClassName]: isEnabled,
    },
    props.className,
  );

  const knobDefaultClassName = `flex items-center justify-center absolute w-4.5 h-4.5 bg-white rounded-full
     shadow-[0_0.281rem_0.656rem_rgba(0,0,0,0.12)] transition-all duration-100 ease-in delay-[10ms]`;

  const knobEnabledClassName = classNames(knobDefaultClassName, {
    'left-[calc(100%-1.125rem)]': isEnabled,
    'left-0': !isEnabled,
    'opacity-0': !isEnabled && disabledIcon,
  });
  const knobEnabledIconClassName = classNames(
    'mt-px ml-px transition-all duration-200 ease-in-out',
    {
      'opacity-0': !isEnabled,
      'opacity-100': isEnabled,
    },
  );

  const knobDisabledClassName = classNames(knobDefaultClassName, {
    'right-[calc(100%-1.125rem)]': !isEnabled,
    'right-0 opacity-0': isEnabled,
  });
  const knobDisabledIconClassName = classNames(
    'transition-all duration-200 ease-in-out',
    {
      'opacity-0': isEnabled,
      'opacity-100': !isEnabled,
    },
  );

  const labelClassName = classNames('absolute right-14 bottom-[0.188rem]', {
    'pointer-events-none opacity-30': disabled,
  });

  return (
    <div className="relative">
      <div
        {...props}
        className={className}
        role="switch"
        aria-disabled={disabled}
        aria-checked={isEnabled}
        onClick={async () => {
          try {
            setIsEnabled(!isEnabled);
            await onChangeValue(!isEnabled);
          } catch {
            setIsEnabled(isEnabled);
          }
        }}
      >
        <div className="relative w-full pointer-events-none">
          {disabledIcon && (
            <div className={knobDisabledClassName}>
              <Icon
                name={disabledIcon}
                size={16}
                className={knobDisabledIconClassName}
              />
            </div>
          )}

          <div className={knobEnabledClassName}>
            <Checker className={knobEnabledIconClassName} />
          </div>
        </div>
      </div>

      {withLabel && (
        <Text type="body" size="regular" className={labelClassName}>
          {isEnabled ? 'Active' : 'Inactive'}
        </Text>
      )}
    </div>
  );
};

export default Toggle;
