import { FeatureFlagsStructure } from '@customer-web-app/domains/shared/providers/feature-flags-provider';

export default function getSettingsMainScreen(flags: FeatureFlagsStructure) {
  // First flag "true" will be redirected to specific route
  const screens = [
    {
      active: flags.settingsEnableSecurity,
      route: '/settings/organization/security',
    },
    {
      active: flags.settingsEnableUserManagement,
      route: '/settings/organization/users',
    },

    {
      active: flags.settingsEnableDataConnectors,
      route: '/settings/services/data-connectors',
    },
    {
      active: flags.settingsEnableEmailNotifications,
      route: '/settings/notifications/emails',
    },
  ];

  return screens.find((flag) => flag.active && flag.route)?.route;
}
