/**
 ***************************
 * 🎨 Radiant Security Color Palette 🎨
 * 🔗 Based on: https://www.figma.com/file/1EE68eKmVMdmj2AdSvxUVV/Blast-Radius-Design-System?node-id=0%3A1
 *************************** *
 *
 * Object built for TailwindCSS (https://tailwindcss.com/) configuration file.
 *
 * ⚠️ NOTE ⚠️
 * Avoid use this object, insted use directly with Tailwind
 * For SCSS files use the `@apply` mixin (https://tailwindcss.com/docs/extracting-components#extracting-component-classes-with-apply)
 * and for components use classNames
 *
 */

const colorPalette = {
  transparent: 'transparent',
  black: '#000',
  white: '#fff',
  gray: {
    25: '#f8f8fc',
    50: '#f3f4fa',
    65: '#f7f7f8',
    75: '#f7f7fb',
    100: '#e7eaf5',
    125: '#e8ebf5',
    200: '#d7dbea',
    300: '#b4bad1',
    400: '#9aa0ba',
    500: '#898ea3',
    600: '#5c6277',
    675: '#353744',
    700: '#3f4353',
    800: '#303443',
    825: '#31343F',
    850: '#2A2E3B',
    900: '#242733',
    925: '#1A1C2A',
    950: '#151725',
  },
  red: {
    100: '#ffe4e4',
    200: '#ffcacb',
    300: '#ffa0a2',
    400: '#ff7e80',
    500: '#ff5d60',
    600: '#ff3a3e',
    700: '#c71d20',
    800: '#8f0003',
    900: '#4b0001',
  },
  pink: {
    100: '#ffeaf0',
    200: '#ffbfd4',
    300: '#ff86ad',
    400: '#ff4e86',
    500: '#ff1560',
    600: '#db0046',
    700: '#a30034',
    800: '#6a0022',
    900: '#3a0013',
  },
  green: {
    100: '#d6fff9',
    200: '#a3fff1',
    300: '#70ffea',
    400: '#0cf2d0',
    500: '#00d8b9',
    600: '#00ab92',
    700: '#00ab92',
    800: '#00564a',
    900: '#002c25',
  },
  blue: {
    100: '#d5f7fe',
    200: '#9feefe',
    300: '#69e4fe',
    400: '#34ddff',
    500: '#00d0fc',
    550: '#4F6BD9',
    600: '#00a1c1',
    700: '#007289',
    800: '#004c5b',
    900: '#00262e',
  },
  yellow: {
    100: '#fff4e6',
    200: '#ffeacd',
    300: '#ffdaa5',
    400: '#ffc97e',
    500: '#ffb956',
    600: '#f0981c',
    700: '#c47200',
    800: '#7b4800',
    900: '#331d00',
  },
  purple: {
    100: '#f4edfd',
    200: '#e9dbfb',
    300: '#d3b8f6',
    400: '#be95f2',
    500: '#a871ee',
    600: '#8031e6',
    700: '#5d16b8',
    800: '#3c0e77',
    900: '#1b0736',
  },
};

module.exports = colorPalette;
