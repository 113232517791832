import React from 'react';
import classNames from 'classnames';
import { Icon, IconContained, Text } from '@blastradius/ui';
import toast, { Toast, Toaster, ToastPosition } from 'react-hot-toast';

type ToastStates = 'success' | 'error';

type Props = {
  title?: string;
  message: string;
  position?: ToastPosition;
  duration?: Toast['duration'];
  size?: string;
  background?: string;
  onUndoClick?: () => void;
  icon?: React.ComponentProps<typeof Icon>['name'];
  iconContained?: boolean;
  iconContainedColor?: React.ComponentProps<typeof IconContained>['color'];
  avatar?: React.ReactNode;
  state?: ToastStates;
  options?: {
    type: 'dismiss' | 'undo' | 'custom';
    component?: JSX.Element;
  }[];
  customDismiss?: boolean;
  className?: string;
};

export type ToastContextType = {
  notification: (props: Props) => void;
  toast: typeof toast;
  dismiss: () => void;
};

export const ToastContext = React.createContext<ToastContextType>({
  notification: () => void 0,
  toast: {} as typeof toast,
  dismiss: () => void 0,
});

const animationsMapper: {
  [key in ToastPosition]: { enter: string; leave: string };
} = {
  'bottom-left': {
    enter: 'animate-slide-enter',
    leave: 'animate-slide-leave',
  },
  'bottom-center': {
    enter: 'animate-enter',
    leave: 'animate-leave',
  },
  'bottom-right': {
    enter: 'animate-enter',
    leave: 'animate-leave',
  },
  'top-center': {
    enter: 'animate-enter',
    leave: 'animate-leave',
  },
  'top-left': {
    enter: 'animate-enter',
    leave: 'animate-leave',
  },
  'top-right': {
    enter: 'animate-enter',
    leave: 'animate-leave',
  },
};

const statesMapper: {
  [key in ToastStates]: {
    borderColor: string;
    icon: React.ComponentProps<typeof Icon>['name'];
  };
} = {
  success: {
    borderColor: 'border-green-500',
    icon: 'circle-check-green',
  },
  error: {
    borderColor: 'border-pink-500',
    icon: 'circle-exclamation-red',
  },
};

export const ToastProvider: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
}) => {
  const notification = ({
    title,
    message,
    position = 'bottom-left',
    background = 'bg-gray-800 dark:bg-white',
    duration = 3000,
    icon,
    size = '',
    iconContained,
    iconContainedColor,
    avatar,
    state,
    onUndoClick,
    customDismiss = false,
    options = [],
    className,
  }: Props) => {
    const defaultClassName = `${background} ${size} flex items-center rounded shadow-xl
    dark:shadow-sm max-w-[26.25rem]`;
    const toastClassName = classNames(defaultClassName, className, {
      'justify-between': options.length > 0,
    });

    return toast.custom(
      ({ id, visible }) => {
        const definedOptions = {
          undo: (
            <button
              onClick={onUndoClick}
              aria-label="Undo"
              className={`border-l border-l-gray-700 dark:border-l-gray-200 px-8 h-full bg-transparent hover:bg-white/[0.06]
              dark:hover:bg-gray-800/[0.06] transition-all duration-200 ease-in-out`}
            >
              <Text
                type="body"
                size="small"
                className="text-white dark:text-gray-950"
              >
                Undo
              </Text>
            </button>
          ),
          dismiss: (
            <button
              data-testid={id}
              onClick={() => toast.dismiss(id)}
              aria-label="Dismiss notification"
              className={`border-l border-l-gray-700 dark:border-l-gray-200 px-8 h-full bg-transparent hover:bg-white/[0.06]
            dark:hover:bg-gray-800/[0.06] transition-all duration-200 ease-in-out`}
            >
              <Text
                type="body"
                size="small"
                className="text-white dark:text-gray-950"
              >
                Dismiss
              </Text>
            </button>
          ),
          custom: (component: JSX.Element) => (
            <Text
              as="div"
              type="body"
              size="small"
              className={`border-l border-l-gray-700 dark:border-l-gray-200 
                h-full bg-transparent px-3
                hover:bg-white/[0.06] dark:hover:bg-gray-800/[0.06] transition-all duration-200 ease-in-out
                text-white dark:text-gray-950`}
              onClick={() => {
                if (customDismiss) {
                  toast.dismiss(id);
                }
              }}
            >
              {React.cloneElement(component, {
                className: 'flex w-full h-full items-center justify-center',
              })}
            </Text>
          ),
        };

        return (
          <div
            role={'alert'}
            className={classNames(toastClassName, {
              [animationsMapper[position].enter]: visible,
              [animationsMapper[position].leave]: !visible,
              [`border-l-2 border-solid`]: state,
              [statesMapper?.[state as ToastStates]?.borderColor]: state,
            })}
          >
            <div className="flex items-center gap-4 p-4">
              {icon &&
                (iconContained ? (
                  <IconContained
                    icon={icon}
                    size="w-10 h-10 basis-10 shrink-0"
                    iconSize={20}
                    data-testid="icon-contained"
                    color={iconContainedColor}
                  />
                ) : (
                  <Icon name={icon} className="shrink-0" />
                ))}

              {state && !icon && (
                <Icon
                  name={statesMapper[state].icon}
                  size={32}
                  className="shrink-0"
                  data-testid="icon-state"
                />
              )}

              {avatar}

              <div className="flex flex-col gap-0.5">
                {title && (
                  <Text
                    type="body"
                    size="regular"
                    className="text-white dark:text-gray-950"
                  >
                    {title}
                  </Text>
                )}
                <Text
                  type="body"
                  size="regular"
                  color={classNames({
                    'text-white dark:text-gray-950': !title,
                    'text-gray-300 dark:text-gray-600': title,
                  })}
                  className="line-clamp-3"
                >
                  {message}
                </Text>
              </div>
            </div>

            {options.length > 0 && (
              <div
                className="grid min-w-[7.5rem] h-full relative text-center"
                style={{
                  gridTemplateRows: options.length === 1 ? '1fr' : '1fr 1fr',
                }}
              >
                {options.length > 1 && (
                  <div
                    className="w-full h-px absolute left-0 top-1/2 bg-gray-700 dark:bg-gray-200"
                    data-testid="options-divider-x"
                  />
                )}
                {
                  options
                    .slice(0, 2)
                    .map((option) =>
                      option.type === 'custom' && option?.component
                        ? definedOptions.custom(option.component)
                        : definedOptions[option.type],
                    ) as JSX.Element[]
                }
              </div>
            )}
          </div>
        );
      },
      { position, duration },
    );
  };

  function dismiss() {
    toast.dismiss();
  }

  return (
    <ToastContext.Provider value={{ toast, notification, dismiss }}>
      {children}
      <Toaster />
    </ToastContext.Provider>
  );
};
