import React from 'react';
import classNames from 'classnames';
import { format, isAfter, isMatch, isValid } from 'date-fns';
import { ReactDatePickerProps } from 'react-datepicker';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import DatePicker from '../date-picker';
import InputMask from '../input-mask';

type DateFormats = 'MM/dd/yyyy' | 'yyyy-mm-dd';

type Props = {
  startDateName: string;
  endDateName?: string;
  initialStartDate?: Date | string;
  initialEndDate?: Date | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, FieldValues>;
  selectsRange?: boolean;
  withError?: boolean;
};

type InputDatePickerProps = Omit<
  Partial<ReactDatePickerProps>,
  'selectsRange'
> &
  Props;

function isValidDate(date: string, format: DateFormats) {
  return isMatch(date, format) && date?.length === 10;
}

const InputDatePicker = ({
  startDateName,
  endDateName,
  initialStartDate = '',
  initialEndDate = '',
  form,
  selectsRange,
  withError,
  ...props
}: InputDatePickerProps) => {
  const { setValue } = form;

  const [startDate, setStartDate] = React.useState<Date | undefined>(
    isValidDate(initialStartDate as string, 'yyyy-mm-dd')
      ? new Date(initialStartDate as string)
      : undefined,
  );
  const [endDate, setEndDate] = React.useState<Date | undefined>(
    isValidDate(initialEndDate as string, 'yyyy-mm-dd')
      ? new Date(initialEndDate as string)
      : undefined,
  );

  const [formattedStartDate, setFormattedStartDate] = React.useState(
    isValidDate(initialStartDate as string, 'yyyy-mm-dd')
      ? format(new Date(`${initialStartDate} 00:00:000`), 'MM/dd/yyyy')
      : '',
  );
  const [formattedEndDate, setFormattedEndDate] = React.useState(
    isValidDate(initialEndDate as string, 'yyyy-mm-dd')
      ? format(new Date(`${initialEndDate} 00:00:000`), 'MM/dd/yyyy')
      : '',
  );
  const [focusedInput, setFocusedInput] = React.useState<'start' | 'end'>(
    'start',
  );
  const [isOpen, setIsOpen] = React.useState(false);

  const inputClassName = classNames({
    'w-[calc(50%-0.063rem)] float-left': selectsRange,
    'w-full': !selectsRange,
  });

  function closeDatePicker(e: MouseEvent) {
    const hasDatePickerInComposition = e
      .composedPath()
      .some((path) =>
        (path as HTMLElement)
          .getAttribute?.('class')
          ?.startsWith('react-datepicker'),
      );

    if (!hasDatePickerInComposition) {
      setIsOpen(false);
    }
  }

  React.useEffect(() => {
    document.addEventListener('click', closeDatePicker);

    return () => {
      document.removeEventListener('click', closeDatePicker);
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleChangeDate(selectedDate: any, event: any) {
    if (selectsRange && (event.type === 'click' || event.key === 'Enter')) {
      let [start, end] = selectedDate as unknown as Date[];

      if (focusedInput === 'start' && startDate && endDate) {
        setFormattedEndDate('');
        setEndDate(undefined);
      }

      if (focusedInput === 'end') {
        if (startDate && endDate && isAfter(start, startDate)) {
          end = start;
          start = startDate as Date;
        } else {
          setFormattedEndDate('');
          setEndDate(undefined);
        }
      }

      setStartDate(start);
      setFormattedStartDate(format(start as Date, 'MM/dd/yyyy'));
      setEndDate(end);

      if (isValid(start) && isValid(end)) {
        setValue(startDateName, start);
        setFormattedEndDate(format(end as Date, 'MM/dd/yyyy'));
        setValue(endDateName as string, end);

        setIsOpen(false);
      }
    } else if (event.type === 'click' || event.key === 'Enter') {
      setValue(startDateName, selectedDate);

      if (endDateName) {
        setValue(endDateName, selectedDate);
      }

      setStartDate(selectedDate as Date);
      setFormattedStartDate(format(selectedDate as Date, 'MM/dd/yyyy'));

      setIsOpen(false);
    }
  }

  function handleChangeStartValue(e: React.ChangeEvent<HTMLInputElement>) {
    if (!selectsRange && isValidDate(e.target.value, 'MM/dd/yyyy')) {
      const selectedDate = new Date(e.target.value);
      setValue(startDateName, selectedDate);

      if (endDateName) {
        setValue(endDateName, selectedDate);
      }

      setStartDate(selectedDate as Date);
    }

    if (
      selectsRange &&
      isValidDate(e.target.value, 'MM/dd/yyyy') &&
      isValidDate(formattedEndDate, 'MM/dd/yyyy')
    ) {
      const selectedDate = new Date(e.target.value);
      setValue(startDateName, selectedDate);
      setStartDate(selectedDate as Date);

      setValue(endDateName as string, new Date(formattedEndDate));
      setEndDate(new Date(formattedEndDate) as Date);
    }

    setFormattedStartDate(e.target.value);
  }

  function handleClickStartInput() {
    setFocusedInput('start');
    setIsOpen(true);
  }

  function handleChangeEndValue(e: React.ChangeEvent<HTMLInputElement>) {
    if (
      isValidDate(e.target.value, 'MM/dd/yyyy') &&
      isValidDate(formattedStartDate, 'MM/dd/yyyy')
    ) {
      const selectedDate = new Date(e.target.value);
      setValue(endDateName as string, selectedDate);
      setEndDate(selectedDate as Date);

      setValue(startDateName, new Date(formattedStartDate));
      setStartDate(new Date(formattedStartDate) as Date);
    }

    setFormattedEndDate(e.target.value);
  }

  function handleClickEndInput() {
    setFocusedInput('end');
    setIsOpen(true);
  }

  return (
    <DatePicker
      {...props}
      open={isOpen}
      selected={startDate}
      startDate={startDate}
      endDate={endDate}
      selectsRange={selectsRange}
      onChange={handleChangeDate}
      customInput={
        <div className="flex w-full">
          <InputMask
            id="date-picker-start"
            iconRight="calendar"
            label={!selectsRange ? 'Date' : 'Start'}
            mask="date"
            placeholder="mm/dd/yyyy"
            value={formattedStartDate}
            onChange={handleChangeStartValue}
            onClick={handleClickStartInput}
            className={inputClassName}
            data-testid="date-picker-start"
            error={
              withError &&
              formattedStartDate.length > 0 &&
              !isValidDate(formattedStartDate, 'MM/dd/yyyy')
            }
            group={selectsRange ? 'first' : undefined}
          />
          {selectsRange && (
            <InputMask
              id="date-picker-end"
              iconRight="calendar"
              label="End"
              mask="date"
              placeholder="mm/dd/yyyy"
              value={formattedEndDate}
              onChange={handleChangeEndValue}
              onClick={handleClickEndInput}
              className={inputClassName}
              data-testid="date-picker-end"
              error={
                withError &&
                formattedEndDate.length > 0 &&
                !isValidDate(formattedEndDate, 'MM/dd/yyyy')
              }
              group="last"
            />
          )}
        </div>
      }
    />
  );
};

export default InputDatePicker;
