import React from 'react';
import classNames from 'classnames';
import Text from '../text';

type Colors = 'red' | 'green' | 'pink' | 'gray';
type Size = 'small' | 'normal';

const colorsMapper: {
  [key in Colors]: {
    background?: string;
    fontColor?: string;
    ghostFontColor: string;
    ghostBackground: string;
    ghostBorder: string;
  };
} = {
  red: {
    background: 'bg-gradient-to-b from-red-500 to-pink-500',
    fontColor: 'text-white',
    ghostFontColor: 'text-gray-950 dark:text-white',
    ghostBackground: 'bg-transparent',
    ghostBorder: 'border border-solid border-red-to-pink',
  },
  green: {
    background: 'bg-green-500',
    fontColor: 'text-white',
    ghostFontColor: 'text-gray-950 dark:text-white',
    ghostBackground: 'bg-transparent',
    ghostBorder: 'border border-solid border-green-500',
  },
  pink: {
    background: 'bg-pink-500',
    fontColor: 'text-white',
    ghostFontColor: 'text-gray-950 dark:text-white',
    ghostBackground: 'bg-transparent',
    ghostBorder: 'border border-solid border-pink-500',
  },
  gray: {
    background: 'bg-gray-600',
    fontColor: 'text-white',
    ghostFontColor: 'text-gray-500',
    ghostBackground: 'bg-transparent',
    ghostBorder: 'border border-solid border-gray-500',
  },
};

type Props = {
  color: Colors;
  ghost?: boolean;
  size?: Size;
  label?: React.ReactNode;
};

const PillBadge: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  color,
  ghost,
  size = 'normal',
  children,
  label,
  ...props
}) => {
  const {
    background,
    fontColor,
    ghostFontColor,
    ghostBackground,
    ghostBorder,
  } = colorsMapper[color];

  const wrapperClassName = classNames(
    props.className,
    'inline-flex rounded-sm overflow-hidden items-center',
  );
  const labelTextSize = size === 'small' ? 'small' : 'regular';
  const labelClassName = classNames('inline-flex overflow-hidden px-1 mt-px');
  const className = classNames(
    'inline-flex select-none items-center justify-center rounded-full px-1 min-w-[1.063rem] h-[1.063rem]',
    ghost ? ghostBackground : background,
    {
      [ghostBorder]: ghost,
    },
  );
  const noGhostProps = {
    color: fontColor,
  };
  const ghostProps = {
    color: ghostFontColor,
  };

  return (
    <div className={wrapperClassName}>
      <div {...props} className={className}>
        <Text
          type="label-caps"
          size="regular"
          className="uppercase text-center"
          {...(ghost ? ghostProps : noGhostProps)}
        >
          {children}
        </Text>
      </div>
      {label && (
        <div className={labelClassName}>
          <Text as="label" type="body" size={labelTextSize}>
            {label}
          </Text>
        </div>
      )}
    </div>
  );
};

export default PillBadge;
