import { Incident } from '../models/incident';

const typeColorsMapper: {
  [key in Incident['incidentStatus']]: { gradient?: boolean; color: string };
} = {
  opened: {
    gradient: true,
    color: 'from-red-500 to-pink-500',
  },
  pending: {
    color: 'text-gray-500',
  },
  remediated: {
    color: 'text-green-500',
  },
  closed: {
    color: 'text-gray-500',
  },
};

export default typeColorsMapper;
