import classNames from 'classnames';
import React from 'react';
import Text from '../text';
import Number from '../number';

type Sizes = 'regular' | 'large';

type Props = {
  percentage: number;
  size?: Sizes;
  total?: number;
  totalText?: string;
};

const textSizeMapper: {
  [key in Sizes]: {
    textSize: React.ComponentProps<typeof Text>['size'];
  };
} = {
  large: {
    textSize: 'xx-large',
  },
  regular: {
    textSize: 'x-large',
  },
};

function Percentage({
  percentage,
  total,
  totalText,
  size = 'large',
  ...props
}: Props & React.HTMLAttributes<HTMLDivElement>) {
  const defaultClassName = 'flex items-baseline gap-1.5';
  const className = classNames(defaultClassName, props.className);

  const { textSize } = textSizeMapper[size];

  const percentageValue = !isNaN(percentage) ? percentage : 0;

  return (
    <div
      {...props}
      className={className}
      data-testid={`percentage-${percentage}`}
    >
      <Text
        type="heading"
        size={textSize}
        className="!font-blast"
        color={classNames({
          'text-gray-500': percentageValue === 0,
          'text-gray-950 dark:text-gray-50': percentageValue !== 0,
        })}
        as="p"
      >
        {percentageValue}
        {percentage ? '%' : ''}
      </Text>

      {Boolean(total && percentageValue) && (
        <Text type="blast" size="small" as="p">
          of <Number value={total as number} />{' '}
          {totalText && total && total > 1 ? `${totalText}s` : totalText}
        </Text>
      )}
    </div>
  );
}

export default Percentage;
