import { isValid as isValidDate, sub, formatISO } from 'date-fns';
import { SearchEntityTypes } from '@customer-web-app/domains/search/models/search';
import cloneDeep from 'lodash/cloneDeep';
import { AlertPriority } from '@customer-web-app/domains/alerts/models/alert';

enum RootEntityFieldNames {
  sender = 'sender',
  recipient = 'recipient',
}

type RootEntityFilters = {
  fieldName?: string;
  selectValues?: string[];
};

export type SearchFilterParams = {
  globalFilter?: {
    keyword?: string;
    entities?: SearchEntityTypes[];
    dateRange?: {
      from?: Date | string;
      to?: Date | string;
    };
  };
  incidentFilter?: {
    incidentID?: string[];
    incidentStatus?: string[];
    incidentPhase?: string[];
    incidentType?: string[];
    assignedTo?: string[];
  };
  alertFilter?: {
    alertID?: string[];
    alertStatus?: string[];
    alertType?: string[];
    alertPriority?: AlertPriority[];
    connectorVendorCode?: string[];
    detectionCategory?: string[];
    filters?: RootEntityFilters[];
    senders?: string[];
    recipients?: string[];
  };
};

function getDefaultSearchFilter(): SearchFilterParams {
  const currentDate = new Date();

  return {
    globalFilter: {
      keyword: '',
      entities: [],
      dateRange: {
        from: sub(currentDate, {
          days: 30,
        }),
        to: currentDate,
      },
    },
    incidentFilter: {
      incidentStatus: [],
      incidentPhase: [],
      incidentType: [],
    },
    alertFilter: {
      connectorVendorCode: [],
      alertStatus: [],
      alertType: [],
      alertPriority: [],
      detectionCategory: [],
      filters: [],
      senders: [],
      recipients: [],
    },
  };
}

function buildSearchFilter(filters: SearchFilterParams) {
  // Removing the form reference
  filters = cloneDeep(filters);

  const newSenderSelectValues = filters?.alertFilter?.senders || [];
  const newEmailAddressesSelectValues = filters?.alertFilter?.recipients || [];

  if (filters?.alertFilter?.filters) {
    filters.alertFilter.filters = [
      {
        fieldName: RootEntityFieldNames.sender,
        selectValues: newSenderSelectValues,
      },
      {
        fieldName: RootEntityFieldNames.recipient,
        selectValues: newEmailAddressesSelectValues,
      },
    ];
  }

  delete filters?.alertFilter?.senders;
  delete filters?.alertFilter?.recipients;

  if (isValidDate(filters?.globalFilter?.dateRange?.from)) {
    filters.globalFilter.dateRange.from = formatISO(
      new Date(filters?.globalFilter?.dateRange?.from),
      { representation: 'date' },
    );
  }
  if (isValidDate(filters?.globalFilter?.dateRange?.to)) {
    filters.globalFilter.dateRange.to = new Date(
      filters?.globalFilter?.dateRange?.to,
    )
      .toISOString()
      .split('T')[0];
  }

  return filters;
}

const alphabetSpecialChars = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
const alphabetCommonChars = 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';

function highlightKeyword(text: string, keyword: string) {
  if (!text || !keyword) {
    return text;
  }

  const normalizedText = text
    .toLowerCase()
    .trim()
    .replace(new RegExp(alphabetSpecialChars.split('').join('|'), 'g'), (c) =>
      alphabetCommonChars.charAt(alphabetSpecialChars.indexOf(c)),
    );
  const normalizedKeyword = keyword
    .toLowerCase()
    .trim()
    .replace(new RegExp(alphabetSpecialChars.split('').join('|'), 'g'), (c) =>
      alphabetCommonChars.charAt(alphabetSpecialChars.indexOf(c)),
    );

  const [startAt, endAt] = [
    normalizedText.indexOf(normalizedKeyword),
    normalizedText.indexOf(normalizedKeyword) + normalizedKeyword.length,
  ];

  if (startAt === -1) {
    return text;
  }

  return `${text.substring(
    0,
    startAt,
  )}<span class="bg-blue-500 bg-opacity-30 p-0.5 rounded-sm">${text.substring(
    startAt,
    endAt,
  )}</span>${text.substring(endAt)}`;
}

const SearchFilterService = {
  getDefaultSearchFilter,
  buildSearchFilter,
  highlightKeyword,
};

export default SearchFilterService;
