import React from 'react';
import Text from '../text';

type Props = {
  type?: React.ComponentProps<typeof Text>['type'];
  size?: React.ComponentProps<typeof Text>['size'];
  bytes: number;
};

function StorageUnitText({
  type = 'heading',
  size = 'small',
  bytes = 0,
  ...props
}: Props & React.HTMLAttributes<HTMLElement>) {
  const storageUnit = React.useMemo(() => {
    if (!bytes) {
      return '0 B';
    }

    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let unitIndex = 0;

    while (bytes >= 1000 && unitIndex < units.length - 1) {
      bytes /= 1000;
      unitIndex++;
    }

    const formattedValue =
      bytes % 1 === 0 ? bytes.toFixed(0) : bytes.toFixed(2);
    return formattedValue + ' ' + units[unitIndex];
  }, [bytes]);

  return (
    <Text {...props} type={type} size={size}>
      {storageUnit}
    </Text>
  );
}

export default StorageUnitText;
