import React from 'react';
import classNames from 'classnames';
import Icon from '../icon';
import Text from '../text';

type Colors = 'red' | 'gray';

const iconColorsMapper: {
  [key in Colors]: { background: string; fill: string };
} = {
  red: {
    background: '!bg-pink-500 dark:!bg-pink-500',
    fill: 'fill-white dark:fill-white',
  },
  gray: {
    background: '!bg-gray-200 dark:!bg-gray-700',
    fill: 'fill-gray-500',
  },
};

type Props = {
  icon: React.ComponentProps<typeof Icon>['name'];
  color: Colors;
  iconSize?: React.ComponentProps<typeof Icon>['size'];
  size?: string;
  value?: string;
  iconClassName?: string;
};

const IconBadge: React.FC<Props & React.HTMLAttributes<HTMLElement>> = ({
  icon,
  color,
  iconSize = 10,
  size = 'w-4 h-4',
  value,
  iconClassName,
  ...props
}) => {
  const { background, fill } = iconColorsMapper[color];
  const defaultClassName =
    'flex items-center justify-center rounded-full p-1 bg-gray-50 dark:bg-gray-950';
  const className = classNames(
    defaultClassName,
    background,
    {
      'py-0.5 pr-2 gap-1 float-left': value,
      [size]: !value,
    },
    props.className,
  );

  return (
    <span {...props} className={className}>
      <Icon name={icon} size={iconSize} className={iconClassName || fill} />
      {value && (
        <Text type="body" size="x-small">
          {value}
        </Text>
      )}
    </span>
  );
};

export default IconBadge;
