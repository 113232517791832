import React from 'react';
import { PatternFormat } from 'react-number-format';

type Formats = 'phone';

type Props = {
  value: string | number;
  format: Formats;
};

const formatsMapper: { [key in Formats]: string } = {
  phone: '(###) ### ####',
};

const Mask = React.forwardRef<HTMLElement, Props>(({ value, format }, ref) => {
  return value ? (
    <PatternFormat
      getInputRef={ref}
      value={value}
      format={formatsMapper[format]}
      displayType="text"
    />
  ) : (
    <>-</>
  );
});

export default Mask;
