import React from 'react';
import classNames from 'classnames';
import styles from './input-checkbox-composed.module.scss';
import Text from '../text';
import Icon from '../icon';
import Skeleton from 'react-loading-skeleton';

type Props = {
  value?: string;
  idle?: boolean;
  label?: string;
  description?: string;
  icon?: React.ComponentProps<typeof Icon>['name'];
  locked?: boolean;
  loading?: boolean;
  ghost?: boolean;
};

const InputCheckboxComposed = React.forwardRef<
  HTMLInputElement,
  Props &
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
>(
  (
    {
      label,
      description,
      value,
      icon = 'plus',
      locked = false,
      checked,
      loading = false,
      ghost = false,
      ...props
    },
    ref,
  ) => {
    const defaultClassName = `absolute top-0 left-0 w-full h-full rounded appearance-none outline-0
  cursor-pointer bg-transparent group-hover:bg-gray-925 dark:group-hover:bg-white transition-colors
  duration-200 ease-in-out`;

    const className = classNames(props.className, defaultClassName, {
      'bg-gray-925 dark:bg-white': checked,
      [styles.inputCheckboxComposed]: !ghost,
      [styles.inputCheckboxComposedGhost]: ghost,
    });

    const inputCheckboxComposedId = Math.random().toString(36).slice(2);

    return (
      <div
        className={classNames(
          `relative group flex items-center justify-between group py-3.5 px-6 border border-solid border-gray-200
        dark:border-gray-700 transition-colors duration-200 ease-in-out rounded`,
          {
            'w-full': label,
            'pointer-events-none': locked || loading,
          },
        )}
      >
        <input
          className={className}
          type="checkbox"
          id={inputCheckboxComposedId}
          data-testid="input-checkbox-composed"
          value={value}
          ref={ref}
          {...props}
        />

        <div className="flex flex-col justify-center">
          {!loading ? (
            <Text
              type="body"
              size="large"
              as="label"
              className={classNames(
                `group-hover:text-white dark:group-hover:text-gray-950
            transition-colors ease-in-out duration-200 z-10 pointer-events-none`,
                {
                  'text-white dark:text-gray-950': checked && !ghost,
                  'text-gray-950 dark:text-white': checked && ghost,
                },
              )}
            >
              {label}
            </Text>
          ) : (
            <Skeleton width={110} height={18} className="my-0.5" />
          )}

          {!loading ? (
            description && (
              <Text
                type="body"
                size="small"
                className="z-10 pointer-events-none mt-1"
                color="text-gray-500"
              >
                {description}
              </Text>
            )
          ) : (
            <Skeleton width={100} height={14} className="mt-1" />
          )}
        </div>

        {!loading ? (
          <Icon
            name={!checked ? icon : 'circle-check'}
            size={24}
            className={classNames(
              `fill-gray-950 dark:fill-white z-10 transition-colors ease-in-out duration-200 pointer-events-none`,
              {
                'fill-white dark:fill-gray-950': checked,
                'group-hover:fill-white dark:group-hover:fill-gray-950':
                  !checked,
              },
            )}
          />
        ) : (
          <Skeleton width={20} height={20} />
        )}
      </div>
    );
  },
);

export default InputCheckboxComposed;
