import React from 'react';
import classNames from 'classnames';
import { Icon, Text } from '@blastradius/ui';
import styles from './list-item.module.scss';

type Props = {
  value?: React.ReactNode;
  copyable?: boolean;
  copyableValue?: string;
  label?: React.ReactNode;
  as?: React.ElementType;
  truncate?: boolean;
  icon?: React.ComponentProps<typeof Icon>['name'];
  withUnderline?: boolean;
  action?: React.ReactNode;
};

const ListItem: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  value,
  label,
  copyable = false,
  withUnderline = true,
  copyableValue,
  as = 'div',
  truncate,
  icon,
  action = <></>,
  ...props
}) => {
  const defaultClassName = `w-full py-2 pr-2 flex items-center justify-center
    transition-colors duration-75 ease-in-out`;
  const className = classNames(defaultClassName, props.className, {
    [styles.listItem]: copyable || props.onClick,
  });
  const valueClassName = classNames('flex-1 flex items-center justify-start', {
    'cursor-pointer': props.onClick,
    'hover:underline': props.onClick && withUnderline,
    truncate: truncate,
  });
  // Checks if value is a React Element and gets the correct copyable value accordingly
  function handleValue(value: React.ReactNode) {
    if (React.isValidElement(value)) {
      return value?.props?.value?.toString() || '-';
    }
    return value || '-';
  }

  return (
    <Text
      {...props}
      type="body"
      size="small"
      copyable={copyable}
      copyIconProps={{ className: '-mr-2' }}
      copyableValue={copyableValue || (handleValue(value) as string)}
      as={as}
      className={className}
      aria-label={props['aria-label'] || `${label} ${handleValue(value)}`}
    >
      {label && (
        <Text
          type="body"
          size="small"
          className="w-4 h-4 flex items-center justify-start"
          color="text-gray-500"
          aria-hidden
        >
          {label}
        </Text>
      )}
      {icon && <Icon name={icon} size={16} className="mr-4" />}
      <Text
        type="body"
        size="small"
        className={valueClassName}
        color="text-gray-950 dark:text-white"
        aria-hidden
      >
        {truncate ? <span className="truncate">{value}</span> : value}
        {!value && '-'}
        {!!action && <span className="ml-3">{action}</span>}
      </Text>
    </Text>
  );
};

export default ListItem;
