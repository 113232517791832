import useAuthentication from '@customer-web-app/domains/authentication/hooks/use-authentication';
import { trackAnalyticEvent } from '@customer-web-app/domains/shared/providers/analytics-provider/track-analytic-event';
import LogRocket from 'logrocket';
import React from 'react';

function LogsProvider({ children }: React.HTMLAttributes<HTMLElement>) {
  const { user } = useAuthentication();

  React.useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_ENVIRONMENT == 'production' ||
      process.env.NEXT_PUBLIC_ENVIRONMENT == 'staging'
    ) {
      LogRocket.init(process.env.NEXT_PUBLIC_LOG_ROCKET_ID, {
        dom: {
          textSanitizer: true,
          inputSanitizer: true,
        },
        network: {
          isEnabled: false,
        },
      });
    }
  }, []);

  React.useEffect(() => {
    if (
      (process.env.NEXT_PUBLIC_ENVIRONMENT == 'production' ||
        process.env.NEXT_PUBLIC_ENVIRONMENT == 'staging') &&
      user
    ) {
      LogRocket.identify(user.id, {
        name: user.name,
        email: user.email,
        Tenant: `${user.tenant.name} (ID: ${user.tenantId.substring(
          0,
          4,
        )}...${user.tenantId.substring(user.tenantId.length - 6)})`,
      });
    }

    if (process.env.NEXT_PUBLIC_ENVIRONMENT == 'production' && user) {
      LogRocket.getSessionURL((sessionURL) => {
        trackAnalyticEvent('LogRocket Session', { sessionURL });
      });
    }
  }, [user]);

  return <>{children}</>;
}

export default LogsProvider;
