import React from 'react';
import classNames from 'classnames';
import Text from '../text';
import Icon from '../icon';
import styles from './text-link.module.scss';

type Props = {
  as?: 'a' | 'button';
  withLeftArrow?: boolean;
  withRightArrow?: boolean;
  underline?: boolean;
  underlineOnHover?: boolean;
  textType?: React.ComponentProps<typeof Text>['type'];
  textSize?: React.ComponentProps<typeof Text>['size'];
  colorLightMode?: string;
  underlineLightMode?: string;
  colorDarkMode?: string;
  underlineDarkMode?: string;
  rightIcon?: React.ComponentProps<typeof Icon>['name'];
  rightIconSize?: React.ComponentProps<typeof Icon>['size'];
  rightIconClassName?: string;
  commonUnderline?: boolean;
};

const TextLink = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  Props &
    React.AnchorHTMLAttributes<HTMLAnchorElement> &
    React.ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      as = 'a',
      withLeftArrow,
      withRightArrow,
      underline = true,
      underlineOnHover = false,
      children,
      textType = 'body',
      textSize = 'small',
      colorLightMode = 'gray-500',
      underlineLightMode = colorLightMode,
      colorDarkMode = 'gray-500',
      underlineDarkMode = colorDarkMode,
      rightIcon,
      rightIconSize = 16,
      rightIconClassName = '',
      ...props
    },
    ref,
  ) => {
    const specialUnderlineClassName = {
      [styles.textLink]: true,
      [styles.textLinkUnderline]: true,
      [styles.textLinkUnderlineWithArrow]: withLeftArrow || withRightArrow,
      [styles.textLinkUnderlineWithArrowLeft]: withLeftArrow,
    };
    const className = classNames(
      props.className,
      'relative cursor-pointer group-deep',
      !underline && specialUnderlineClassName,
    );

    return React.createElement(
      as,
      {
        type: as === 'button' ? 'button' : undefined,
        ...props,
        className,
        ref,
      },
      <>
        {withLeftArrow && (
          <Icon
            name="arrow-left"
            size={16}
            className="mr-1.5"
            data-testid="arrow-left"
          />
        )}
        <Text
          type={textType}
          size={textSize}
          color={`text-${colorLightMode} dark:text-${colorDarkMode}`}
          className={classNames({
            'border-b border-solid': underline,
            [`border-${underlineLightMode} dark:border-${underlineDarkMode}`]:
              !underlineOnHover,
            [`border-transparent hover:border-${underlineLightMode} dark:hover:border-${underlineDarkMode}`]:
              underlineOnHover,
          })}
        >
          {children}
        </Text>
        {withRightArrow && (
          <Icon
            name="arrow-right"
            size={16}
            className="ml-1.5"
            data-testid="arrow-right"
          />
        )}

        {!!rightIcon && (
          <Icon
            name={rightIcon}
            size={rightIconSize}
            className={classNames(
              'ml-1.5 opacity-0  group-deep-hover:opacity-100 transition-all ease-in-out duration-200',
              rightIconClassName,
            )}
            data-testid="right-text-link-icon"
          />
        )}
      </>,
    );
  },
);

export default TextLink;
