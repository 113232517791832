import React from 'react';
import { ThemeContext } from '@blastradius/ui';

function useTheme(): React.ComponentProps<
  typeof ThemeContext.Provider
>['value'] {
  const themeContext = React.useContext(ThemeContext);

  return themeContext;
}

export default useTheme;
