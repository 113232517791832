import { Bullet, Pill, Text, Number, Icon } from '@blastradius/ui';
import incidentTypesCopies from '@customer-web-app/domains/incidents/mappers/incident-types-copies';
import typeColorsMapper from '@customer-web-app/domains/incidents/mappers/type-colors-mapper';
import {
  Incident,
  IncidentStatuses,
} from '@customer-web-app/domains/incidents/models/incident';
import useFeatureFlags from '@customer-web-app/domains/shared/hooks/use-feature-flags';
import getIncidentStep from '@customer-web-app/domains/shared/services/get-incident-step';
import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import SearchFilterService from '@customer-web-app/domains/search/services/search-filter-service';
import getIncidentDetailsEntryPointURL from '@customer-web-app/domains/incident-details/services/get-incident-details-entry-point-url';
import incidentIconsMapper from '@customer-web-app/domains/incidents/mappers/incident-icons-mapper';

type Props = {
  incident: Incident;
  loading?: boolean;
  selected?: boolean;
  borderBottom?: boolean;
  keyword?: string;
  iconClassName?: string;
};

type IncidentDetailRowProps = {
  value: React.ReactNode;
  label: string;
  loading: boolean;
};

const IncidentDetailRow: React.FC<
  IncidentDetailRowProps & React.HTMLAttributes<HTMLDivElement>
> = ({ value, label, loading, ...props }) => {
  return (
    <div {...props} className="flex items-center">
      <Text type="body" size="x-small" color="text-gray-500">
        {!loading ? (
          <>
            {label}:{' '}
            <Text type="body" size="x-small" color="text-gray-500">
              {value}
            </Text>
          </>
        ) : (
          <Skeleton width={100} height={11} />
        )}
      </Text>
    </div>
  );
};

const MainSearchBarIncidentCard = React.forwardRef<
  HTMLAnchorElement,
  Props & React.HTMLAttributes<HTMLAnchorElement>
>(
  (
    {
      incident,
      loading,
      selected,
      borderBottom,
      keyword,
      iconClassName,
      ...props
    },
    ref,
  ) => {
    const { incidentsEnableSummaryByContext } = useFeatureFlags();

    const defaultClassName = `flex items-center gap-4 p-4 -mt-px group group-test rounded
  transition-all ease-in-out duration-200`;
    const className = classNames(
      defaultClassName,
      {
        'hover:bg-white dark:hover:bg-white/[.06]': !selected,
        'bg-white dark:bg-white/[.06]': selected,
        'pointer-events-none': loading,
      },
      props.className,
    );

    const incidentDetailsEntryPoint = getIncidentDetailsEntryPointURL(
      incident?.incidentType,
      incidentsEnableSummaryByContext as string[],
    );

    const href = `/incidents/${incident?.id}/${incidentDetailsEntryPoint}?resetHistory=true`;

    return (
      <>
        <Link href={href} passHref {...props} className={className} ref={ref}>
          <>
            <div
              className={classNames(
                `flex justify-center items-center w-8 h-8 rounded transition-all ease-in-out duration-200 shrink-0`,
                {
                  'bg-white group-hover:bg-gray-100 dark:bg-gray-925 dark:group-hover:bg-gray-925':
                    !selected,
                  'bg-gray-100 dark:bg-gray-925': selected,
                },
                iconClassName,
              )}
            >
              {!loading && incident?.incidentStatus && (
                <Icon
                  name={incidentIconsMapper[getIncidentStep(incident)]}
                  size={16}
                />
              )}
            </div>
            <div className="flex flex-col justify-center gap-1.5">
              <div className="flex items-center gap-2">
                {!loading &&
                  incident?.incidentStatus === IncidentStatuses.Opened &&
                  incident?.isLive && (
                    <Pill color="red" size="small">
                      <Bullet color="white" className="mr-1" />
                      <Text type="label-caps" color="text-white">
                        Live
                      </Text>
                    </Pill>
                  )}

                <Text
                  type="label-caps"
                  {...typeColorsMapper[getIncidentStep(incident)]}
                >
                  {!loading ? (
                    incidentTypesCopies[incident?.incidentType]
                  ) : (
                    <Skeleton width={57} height={12} />
                  )}
                </Text>

                {!loading ? (
                  <Text
                    type="body"
                    size="large"
                    as="h3"
                    className="truncate max-w-[30rem]"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: SearchFilterService.highlightKeyword(
                          incident?.incidentName,
                          keyword,
                        ),
                      }}
                    />
                  </Text>
                ) : (
                  <Skeleton width={544} height={16} />
                )}
              </div>

              <div className="flex items-center gap-5">
                <IncidentDetailRow
                  label="Incident ID"
                  value={incident?.incidentNumber}
                  loading={loading}
                  data-testid="incident-id"
                />
                <IncidentDetailRow
                  label="Identities"
                  value={<Number value={incident?.devicesCount} />}
                  loading={loading}
                  data-testid="incident-identities"
                />
                <IncidentDetailRow
                  label="Devices"
                  value={<Number value={incident?.devicesCount} />}
                  loading={loading}
                  data-testid="incident-devices"
                />
                <IncidentDetailRow
                  label="Applications"
                  value={<Number value={incident?.applicationsCount} />}
                  loading={loading}
                  data-testid="incident-applications"
                />
                <IncidentDetailRow
                  label="Data Objects"
                  value={<Number value={incident?.dataObjectsCount} />}
                  loading={loading}
                  data-testid="incident-data-objects"
                />
              </div>
            </div>
          </>
        </Link>
        {borderBottom && (
          <div className="w-[calc(100%-2rem)] mx-auto h-px bg-gray-100 dark:bg-white/[.08] -mt-px" />
        )}
      </>
    );
  },
);
MainSearchBarIncidentCard.displayName = 'MainSearchBarIncidentCard';

export default MainSearchBarIncidentCard;
