import React from 'react';
import { Icon, LoaderSpinner, Text } from '@blastradius/ui';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';

type Props = {
  value: string;
  acting?: boolean;
  selected?: boolean;
  loading?: boolean;
};

const SwitchTenantListItem = React.forwardRef<
  HTMLLIElement,
  Props & React.HTMLAttributes<HTMLLIElement>
>(({ value, acting = false, selected, loading, ...props }, ref) => {
  const defaultClassName = `flex items-center justify-between min-h-[3.25rem] pl-6 pr-3
     border border-gray-950/[.25] dark:border-white/[.25] rounded transition-all ease-in-out duration-200`;

  const className = classNames(defaultClassName, props.className, {
    'hover:border-gray-950 dark:hover:border-white cursor-pointer':
      props.onClick,
    'cursor-default': !props.onClick,
    'border-gray-950 dark:border-white': selected,
  });

  return !loading ? (
    <li {...props} className={className} ref={ref}>
      <Text type="body" size="large">
        {value}
      </Text>
      {props.onClick &&
        (!acting ? (
          <Icon
            name="carrot-right"
            size={24}
            className="fill-black dark:fill-white"
          />
        ) : (
          <LoaderSpinner className="mr-px" />
        ))}
    </li>
  ) : (
    <Skeleton height={52} width={'100%'} />
  );
});
SwitchTenantListItem.displayName = 'SwitchTenantListItem';

export default SwitchTenantListItem;
