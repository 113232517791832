import React from 'react';
import useAuthentication from '@customer-web-app/domains/authentication/hooks/use-authentication';

function AnalyticsProvider({ children }: React.HTMLAttributes<HTMLElement>) {
  const { user } = useAuthentication();

  React.useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' && user) {
      window.heap.identify(user.id);
      window.heap.addUserProperties({
        Email: user.email,
        Tenant: `${user.tenant.name} (ID: ${user.tenantId.substring(
          0,
          4,
        )}...${user.tenantId.substring(user.tenantId.length - 6)})`,
      });
    }
  }, [user]);

  return <>{children}</>;
}

export default AnalyticsProvider;
