import React from 'react';

type UseArrowsNavigationParams = {
  selector: string;
  listLength: number;
  onEnterPress: (ref: React.MutableRefObject<HTMLElement | null>) => void;
  disabled?: boolean;
};

function useArrowsNavigation({
  selector,
  listLength = 0,
  onEnterPress,
  disabled,
}: UseArrowsNavigationParams) {
  const [selected, setSelected] = React.useState(-1);

  const selectedRef = React.useRef(null);

  const onKeyDown = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      if (disabled) {
        return false;
      }

      switch (e.key) {
        case 'ArrowDown':
          setSelected((prev) => (prev >= listLength - 1 ? 0 : prev + 1));
          break;
        case 'ArrowUp':
          setSelected((prev) => (prev <= 0 ? listLength - 1 : prev - 1));
          break;
        case 'Enter':
          onEnterPress(selectedRef);
          break;
        default:
          setSelected(0);
      }

      return;
    },
    [listLength, disabled],
  );

  React.useEffect(() => {
    const targetElement = document.querySelector(selector) ?? document;

    targetElement?.addEventListener('keydown', onKeyDown);

    return () => {
      targetElement?.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  React.useEffect(() => {
    (
      selectedRef as React.MutableRefObject<HTMLElement | null>
    )?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [selected]);

  return {
    currentSelection: selected,
    selectedRef,
  };
}

export default useArrowsNavigation;
