import React from 'react';
import { ReactComponent as EmptyStateVector } from './assets/empty-state.svg';
import { Text } from '@blastradius/ui';
import classNames from 'classnames';

type Props = {
  title: string;
  subTitle?: string;
};

const CardEmptyState: React.FC<Props & React.HTMLAttributes<HTMLElement>> = ({
  title,
  subTitle,
  ...props
}) => {
  const defaultClassName =
    'flex flex-col items-center justify-center gap-4 border border-gray-300 dark:border-gray-900 rounded';
  const className = classNames(defaultClassName, props.className);

  return (
    <div {...props} className={className}>
      <div className="flex flex-col items-center justify-center gap-[0.125rem]">
        <Text type="body" size="large">
          {title}
        </Text>
        {subTitle && (
          <Text
            type="body"
            size="regular"
            color="text-gray-600 dark:text-gray-300"
          >
            {subTitle}
          </Text>
        )}
      </div>

      <EmptyStateVector />
    </div>
  );
};

export default CardEmptyState;
