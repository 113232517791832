import React from 'react';
import classNames from 'classnames';
import Text, { TextSizes, TextTypes } from '../text';

type Sizes = 'small' | 'large';

const sizesMapper: {
  [key in Sizes]: string;
} = {
  small: 'py-1 px-2',
  large: 'py-3 px-6',
};

type Props = {
  size?: Sizes;
  color?: string;
  background?: string;
  textType?: TextTypes;
  textSize?: TextSizes;
};

const Badge: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  size = 'small',
  color = 'text-gray-950 dark:text-white',
  background = 'bg-white dark:bg-gray-900',
  textType = 'blast',
  textSize = 'small',
  children,
  ...props
}) => {
  const padding = sizesMapper[size];
  const defaultClassName =
    'inline-flex items-center justify-center rounded select-none pointer-events-none';
  const className = classNames(
    props.className,
    defaultClassName,
    padding,
    background,
  );

  return (
    <div {...props} className={className}>
      <Text
        type={textType}
        size={textSize}
        className="flex items-center justify-center"
        color={color}
      >
        {children}
      </Text>
    </div>
  );
};

export default Badge;
