import React from 'react';
import classNames from 'classnames';
import Text from '../text';

type Props = {
  children: React.ReactNode;
  labelColor?: string;
};

const InputLabel: React.FC<
  Props & React.LabelHTMLAttributes<HTMLLabelElement>
> = ({
  labelColor = 'text-gray-950 dark:text-gray-50',
  children,
  ...props
}) => {
  const className = classNames('cursor-pointer', props.className);

  return (
    <Text
      as="label"
      {...props}
      className={className}
      color={labelColor}
      type="label"
      size="large"
    >
      {children}
    </Text>
  );
};

export default InputLabel;
