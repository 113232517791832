import React from 'react';
import classNames from 'classnames';
import styles from './radio-group.module.scss';
import Text from '../text';
import Icon from '../icon';
import InputLabel from '../input-label';
import LoaderSpinner from '../loader-spinner';
import { UseFormReturn, useWatch } from 'react-hook-form';

type RadioGroupProps = {
  label?: string;
  onChangeValue?: (selected: string) => void;
  loading?: boolean;
  locked?: boolean;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>;
  name: string;
};

type RadioGroupOptionProps = {
  label: string;
  description?: string;
  disabled?: boolean;
  active?: boolean;
  setSelected?: (value: string) => void;
  setHasSelected?: (value: boolean) => void;
  loading?: boolean;
  radioGroupId?: string;
  icon?: React.ComponentProps<typeof Icon>['name'];
};

type RadioGroupOptionType = React.FC<
  RadioGroupOptionProps & React.InputHTMLAttributes<HTMLInputElement>
>;

type SubComponents = {
  Option: RadioGroupOptionType;
};

const RadioGroup: React.FC<
  RadioGroupProps & React.HTMLAttributes<HTMLDivElement>
> &
  SubComponents = ({
  id,
  className,
  onChangeValue,
  children,
  defaultValue,
  label,
  loading = false,
  locked,
  disabled,
  form,
  name,
  ...props
}) => {
  const wrapperClassName = classNames(
    'flex flex-col gap-2 group',
    {
      'pointer-events-none': locked,
      'pointer-events-none opacity-50': disabled,
    },
    className,
  );
  const defaultClassName = classNames([styles.radioGroup]);

  const formValues = useWatch({
    control: form?.control,
  });
  const value = formValues[name];

  const [selected, setSelected] = React.useState(defaultValue);
  const [hasSelected, setHasSelected] = React.useState(false);

  const radioOptions = React.Children.map(
    children as React.ReactElement[],
    (child: React.ReactElement) => {
      return React.cloneElement(child, {
        ...child.props,
        active: child.props.value === value,
        setSelected,
        setHasSelected,
        loading,
        'aria-disabled': disabled || locked,
        radioGroupId: id,
        ...form.register(name),
      });
    },
  );

  React.useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  React.useEffect(() => {
    setSelected(value);
  }, [value]);

  React.useEffect(() => {
    if (onChangeValue && hasSelected) {
      onChangeValue(selected as string);
    }
  }, [selected]);

  return (
    <div className={wrapperClassName}>
      {label && (
        <InputLabel
          labelColor="text-gray-500 group-hover:text-gray-950 dark:group-hover:text-white transition-all ease-in-out duration-200 cursor-default"
          htmlFor={id}
        >
          {label}
        </InputLabel>
      )}
      <div {...props} className={defaultClassName} role="radiogroup">
        {radioOptions}
      </div>
    </div>
  );
};

const Option = React.forwardRef<
  HTMLInputElement,
  RadioGroupOptionProps & React.InputHTMLAttributes<HTMLInputElement>
>(
  (
    {
      label,
      description,
      setSelected,
      active = false,
      disabled,
      loading = false,
      setHasSelected,
      radioGroupId,
      icon,
      ...props
    },
    ref,
  ) => {
    const defaultClassName = `flex items-center gap-4 justify-start p-4 bg-white dark:bg-white/[.06] 
  hover:bg-gray-950/[.04] hover:dark:bg-white/[.12] border border-solid relative first:rounded-t last:rounded-b
  group-deep transition-all ease-in-out duration-200`;

    const className = classNames(defaultClassName, {
      'border-gray-950/20 dark:border-gray-700': !active || loading,
      'border-gray-700 dark:border-white bg-gray-950/[.04] dark:bg-white/[.06]':
        active && !disabled && !loading,
      'pointer-events-none opacity-60 dark:opacity-25': disabled,
      'pointer-events-none': loading,
    });

    return (
      <div
        className={className}
        onClick={() => {
          if (setSelected && setHasSelected) {
            setHasSelected(true);

            setSelected(props.value as string);
          }
        }}
        data-testid={`${props.value}_radio_option`}
      >
        {!loading ? (
          !disabled ? (
            <div
              className={classNames(
                styles.radio,
                'group-deep-hover:border-gray-950/40 dark:group-deep-hover:border-white/50 transition-all ease-in-out duration-200 shrink-0',
                {
                  [styles.radioActive]: active,
                },
              )}
              data-testid="radio-button"
            />
          ) : (
            <Icon name="block" size={24} className="-mr-1" />
          )
        ) : (
          <LoaderSpinner
            size="w-4 h-4"
            className="mr-1"
            circleBackground="after:bg-white after:dark:bg-gray-800"
          />
        )}

        <input
          {...props}
          type="radio"
          ref={ref}
          data-testid={`radio_option_${props.value}`}
          aria-checked={active}
          id={radioGroupId}
        />

        <div className="flex items-center justify-center gap-2">
          {icon && (
            <Icon
              name={icon}
              size={28}
              className="fill-gray-950 dark:fill-white"
            />
          )}
          <div className="flex flex-col gap-0.5">
            <Text type="body" size="regular">
              {label}
            </Text>
            {description && (
              <Text type="body" size="small" color="text-gray-500">
                {description}
              </Text>
            )}
          </div>
        </div>
      </div>
    );
  },
);
RadioGroup.Option = Option;

export default RadioGroup;
