export default function getIncidentsMainScreen({
  hasLiveIncidents,
  useRedirectRoute = undefined,
  enableEndpointAlerts = true,
  enableIdentityAlerts = false,
  enableNetworkAlerts = true,
  enableGenericAlerts = true,
}: {
  hasLiveIncidents: boolean;
  useRedirectRoute?: boolean;
  enableEndpointAlerts?: boolean;
  enableIdentityAlerts?: boolean;
  enableNetworkAlerts?: boolean;
  enableGenericAlerts?: boolean;
}) {
  const baseLiveIncidentsURL = `/incidents?filter=${encodeURIComponent(
    JSON.stringify({
      incidentFilter: {
        incidentPhase: ['live', 'contained', 'remediated'],
        incidentType: [
          ...(enableEndpointAlerts ? ['endpoint'] : []),
          ...(enableIdentityAlerts ? ['identity'] : []),
          ...(enableNetworkAlerts ? ['network'] : []),
          ...(enableGenericAlerts ? ['generic'] : []),
          'phishing',
          'bec',
        ],
      },
    }),
  )}`;

  // Redirect to incidents "Live incidents" KPI checked
  if (hasLiveIncidents && useRedirectRoute) {
    return `/redirect?url=${encodeURIComponent(baseLiveIncidentsURL)}`;
  }

  if (hasLiveIncidents && !useRedirectRoute) {
    return baseLiveIncidentsURL;
  }

  // Redirect to incidents "All incidents" KPI checked
  return `/incidents?filter=${encodeURIComponent(
    JSON.stringify({
      incidentFilter: {
        incidentStatus: ['pending', 'closed', 'falsePositive'],
        incidentPhase: ['live', 'contained', 'remediated'],
      },
    }),
  )}`;
}
