import Icon from '../icon';
import classNames from 'classnames';
import InputText, { InputTextProps } from '../input-text';
import React, { useState } from 'react';
import { RefCallBack } from 'react-hook-form';
import { isValid } from './validate';

function padWithLeadingZeros(num: number) {
  return String(num).padStart(2, '0');
}

type Props = {
  initTime?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  onTimeChange?: (time: string) => void;
  withButtons?: boolean;
  focusedInputId?: string;
} & InputTextProps;

const InputTime = React.forwardRef<
  RefCallBack,
  Props &
    (
      | React.InputHTMLAttributes<HTMLInputElement>
      | React.TextareaHTMLAttributes<HTMLTextAreaElement>
    )
>(
  (
    {
      initTime = '00:00',
      onTimeChange,
      withButtons = true,
      focusedInputId,
      placeholder,
      ...props
    },
    ref,
  ) => {
    const [time, setTime] = useState(initTime || '');
    const id = React.useMemo(() => Math.random().toString(36).slice(2), []);
    const inputRef = React.useRef<HTMLInputElement | null>(null);

    let lastVal = '';

    React.useEffect(() => {
      setTime(initTime);
    }, [initTime]);

    React.useEffect(() => {
      if (focusedInputId === id && inputRef.current) {
        inputRef.current.focus();
      }
    }, [focusedInputId, id]);

    const onChangeHandler = (val: string) => {
      if (val === time) {
        return;
      }
      if (isValid(val)) {
        if (
          val.length === 2 &&
          lastVal.length !== 3 &&
          val.indexOf(':') === -1
        ) {
          val = val + ':';
        }

        if (val.length === 2 && lastVal.length === 3) {
          val = val.slice(0, 1);
        }

        if (val.length > 5) {
          return false;
        }

        lastVal = val;

        setTime(val);

        if (val.length === 5) {
          onTimeChange?.(val);
        }
      }
      return null;
    };

    return (
      <div className="relative select-none">
        <InputText
          {...props}
          data-id={id}
          value={time}
          onChange={(
            e: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>,
          ) => {
            onChangeHandler(e?.target?.value);
          }}
          ref={(node) => {
            if (node instanceof HTMLInputElement) {
              inputRef.current = node;
            }

            if (typeof ref === 'function') {
              ref(node);
            } else if (ref) {
              ref.current = node;
            }
          }}
        />

        {withButtons && (
          <>
            <button
              type="button"
              className={classNames(
                'rotate-180 absolute cursor-pointer right-3',
                {
                  'top-[1.6rem]': props.label,
                  'top-[0.125rem]': !props.label,
                },
              )}
              onClick={() => {
                const currentValue = (
                  document.querySelector(
                    `[data-id="${id}"]`,
                  ) as HTMLInputElement
                ).value;
                const hoursMinutes = currentValue.split(':');
                if (hoursMinutes.length < 2) {
                  return;
                }
                const [hours, minutes] = hoursMinutes.map(Number);
                if (minutes < 59) {
                  onChangeHandler(
                    `${padWithLeadingZeros(hours)}:${padWithLeadingZeros(
                      minutes + 1,
                    )}`,
                  );
                } else {
                  onChangeHandler(`${padWithLeadingZeros(hours + 1)}:00`);
                }
              }}
              aria-label="Increase field value"
            >
              <Icon
                name="carrot-down"
                size={16}
                className="hover:fill-black dark:hover:fill-white transition-colors ease-in-out duration-200"
              />
            </button>

            <button
              type="button"
              className={classNames('absolute cursor-pointer right-3', {
                'top-[2.75rem]': props.label,
                'top-[1.45rem]': !props.label,
              })}
              onClick={() => {
                const currentValue = (
                  document.querySelector(
                    `[data-id="${id}"]`,
                  ) as HTMLInputElement
                ).value;
                const hoursMinutes = currentValue.split(':');
                if (hoursMinutes.length < 2) {
                  return;
                }
                const [hours, minutes] = hoursMinutes.map(Number);
                if (minutes > 0) {
                  onChangeHandler(
                    `${padWithLeadingZeros(hours)}:${padWithLeadingZeros(
                      minutes - 1,
                    )}`,
                  );
                } else if (hours > 0) {
                  onChangeHandler(`${padWithLeadingZeros(hours - 1)}:59`);
                }
              }}
              aria-label="Decrease field value"
            >
              <Icon
                name="carrot-down"
                size={16}
                className="hover:fill-black dark:hover:fill-white transition-colors ease-in-out duration-200"
              />
            </button>
          </>
        )}
      </div>
    );
  },
);

export default InputTime;
