import classNames from 'classnames';
import React from 'react';
import Text from '../text';

type Colors =
  | 'pink'
  | 'red'
  | 'green'
  | 'light-purple'
  | 'purple'
  | 'dark-purple'
  | 'yellow'
  | 'light-blue'
  | 'dark-blue'
  | 'light-gray';

type Props = {
  color: Colors;
  horizontal?: boolean;
  focus?: boolean;
};

const borderColorMapper: {
  [key in Colors]: string;
} = {
  red: 'border-red-500',
  pink: 'border-pink-500',
  green: 'border-green-500',
  'light-purple': 'border-purple-400',
  purple: 'border-purple-500',
  'dark-purple': 'border-purple-600',
  yellow: 'border-yellow-500',
  'light-blue': 'border-blue-500',
  'dark-blue': 'border-blue-700',
  'light-gray': 'border-gray-500',
};

function ChartLabel({
  color,
  horizontal,
  focus,
  children,
  ...props
}: Props & React.HTMLAttributes<HTMLDivElement>) {
  const borderColor = borderColorMapper[color];

  const defaultClassName =
    'flex items-center cursor-default transition-border duration-200 ease-in-out';

  const className = classNames(defaultClassName, props.className, borderColor, {
    'pl-4 border-l-2 h-5': !horizontal,
    'pt-3 border-t-2 w-full': horizontal,
    '!border-t-8': horizontal && focus,
    '!border-l-8': !horizontal && focus,
  });

  return (
    <div {...props} className={className}>
      <Text
        type="body"
        size="regular"
        color="text-gray-500"
        className="flex items-center justify-between w-full"
      >
        {children}
      </Text>
    </div>
  );
}

export default ChartLabel;
